import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router";
import { NavLink, useParams } from "react-router-dom";
import moment from 'moment/min/moment-with-locales'
import { 
  List, 
  Space, 
  Avatar, 
  Layout, 
  Input, 
  Button,
  Divider, 
  Typography,
  Spin,
  Modal,
 } from 'antd';
import {
  EditOutlined,
  UserOutlined
} from "@ant-design/icons";

import { GlobalContext } from "../../App";
import FarmerService from "../../services/Farmer";
import { useTranslation } from "react-i18next";

const { Search } = Input;

function FarmersList(props) {
  const { t, i18n } = useTranslation("translation");
  // Router
  const { id } = useParams();
  const location = useLocation();
  const [farmers, setFarmers] = useState([]);
  const [filtered_data, setFilteredData] = useState(farmers);
  const [view_loading, setFViewLoading] = useState(true);
  const [user_item_onclick_path, setUserItemOnClickPath] = useState("/");

  const [page, setPage] = useState("all");
  const [search_criteria, setSearchCriteria] = useState({search_query: false, season: false, page: 1});

  const [farmer_stats, setFarmerStats] = useState({count: 0});


  //Create New Season modal state
  const [modal_open, setModalOpen] = useState(false);
  
  const [farmer_name, setFarmerName] = useState("");
  const [farmer_street, setFarmerStreet] = useState("");
  const [farmer_province, setFarmerProvince] = useState("");
  const [farmer_postal_code, setFarmerPostalCode] = useState("");
  const [farmer_city, setFarmerCity] = useState("");
  const [farmer_national_id, setFarmerNationalID] = useState("");
  const [farmer_country, setFarmerCountry] = useState("");

  const [loading, setLoading] = useState(false);


  // View Contexts
  const {
    current_view, 
    setCurrentView, 
    setSelectedUserAsset, 
    setSelectedIndex,
    setSelectedIndexOverlay,
    setGlobalFieldDrawUIEnabled,
  } = useContext(GlobalContext);

  const fetchFarmers = async () => {
    setFViewLoading(true);
    FarmerService.getFarmers({...search_criteria, page: page}, _farmers => {
      setFarmerStats({count: _farmers.count})
      setFarmers([..._farmers.data]);
      setFilteredData([..._farmers.data]);
      setFViewLoading(false);
    });
  }

  useEffect(() => {
    setFViewLoading(true);
    fetchFarmers({page: page});
  }, [])

  useEffect(()=>{
    moment.locale(i18n.language)
  }, [i18n.language])


  const loadMore = (_page) => {
    setPage(_page);
    fetchFarmers({...search_criteria, page: _page});
  }

  const onMenuClick = (e) => {
    switch (e.key) {
      case 1:
        setSelectedUserAsset(null);
        setSelectedIndex(null);
        setSelectedIndexOverlay(null);
        setGlobalFieldDrawUIEnabled(true);
        break;
      default:
        break;
    }
  };
  
  const handleClick = (item) => {
    if (setCurrentView) {
      setCurrentView(prev => ({
        ...prev,
        viewTitle:  current_view.viewName,
        viewTitleSecondary: "Location: " + item.id
      }))
    } else {}
  };

  
  useEffect(() => {
    
    if(current_view){
        setUserItemOnClickPath(current_view.basePath + "/farmers");
    }
  }, [current_view]);

  const onSearch = (e) => {
    if (!e) return;
    const value =  e.target.value
    const filtered = farmers.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase()) || item.email?.toLowerCase().includes(value.toLowerCase()) || item.phone?.toLowerCase().includes(value.toLowerCase())
     }
    );
    setFilteredData(filtered);
  };  

  // Handlers
  const renderUserItem = (item, index) => {
    
    // FIXME - Render the selected field differently 
    if(item.id === id) {

    }

    return (
      <List.Item style={{padding: 0}}>
        <List.Item.Meta
          //avatar={<EnvironmentOutlined style={{color: "#282c34", fontSize: 20}}/>}
          avatar={
            <Avatar size={"large"} icon={<UserOutlined />} />
          }
          style={{textAlign: "left"}}
          title={
            <NavLink to={`farmers/farmer/${item.id}`} onClick={() => handleClick(item)}>
              <Space size="large" style={{ width: '100%', justifyContent: "space-between"}}>
                <Typography.Text style={{ fontSize: 16 }}> 
                  { item.name }
                </Typography.Text>
                <Typography.Text italic disabled style={{fontSize: 10}}>
                  {moment(item.createdAt).fromNow()}
                </Typography.Text>
              </Space> 
            </NavLink>
          }
          description={
            <Space size="small" direction="vertical">
              <Typography.Text italic >
                { item.country }
                {/* | { item.phone } */}
              </Typography.Text>
            </Space>
          }
        />
      </List.Item>
    ) 
  }



  // Create Season Modal Cascaders
  const showModal = () => {
    setModalOpen(true);
  }

  const afterModalClose = () => {
    setFarmerName(null);
    setFarmerCity(null);
    setFarmerCountry(null);
    setFarmerNationalID(null);
    setFarmerPostalCode(null);
    setFarmerStreet(null);
    setFarmerProvince(null);
    fetchFarmers();
  }

  const createFarmer = async (_farmer_name, _farmer_street, _farmer_province, _farmer_city, _farmer_national_id, _farmer_country, _farmer_postal_code)=>{
    const _resp = await FarmerService.createFarmer(_farmer_name, _farmer_street, _farmer_province, _farmer_city, _farmer_national_id, _farmer_country, _farmer_postal_code)
    console.log("CREATED SEASON: ", _resp);
    setLoading(false); 
    setModalOpen(false);
  }

  const handleOk = () => {
    setLoading(true);
    createFarmer(farmer_name, farmer_street, farmer_province, farmer_city, farmer_national_id, farmer_country, farmer_postal_code);

  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const onFarmerNameChange = (e) => {
    setFarmerName(e.target.value);
  }

  const onFarmerStreetChange = (e) => {
    setFarmerStreet(e.target.value);
  }

  const onFarmerPostalCodeChange = (e) => {
    setFarmerPostalCode(e.target.value);
  }

  const onFarmerProvinceChange = (e) => {
    setFarmerProvince(e.target.value);
  }

  const onFarmerCityChange = (e) => {
    setFarmerCity(e.target.value);
  }

  const onFarmerCountryChange = (e) => {
    setFarmerCountry(e.target.value);
  }

  const onFarmerNationalIDChange = (e) => {
    setFarmerNationalID(e.target.value);
  }


  return (
      <>
      {
          view_loading && 
          <Layout className='view-loading-overlay fit-to-space'>
            <div className='view-loading-overlay-content'>
              <Spin />
            </div>
          </Layout>
        }
        <Layout style={{backgroundColor: "#f5f5f5", color: "#282c34", position: 'sticky', top: "0", width:"100%",zIndex: 1005, padding: 5}}>
          <Divider orientation="left" style={{color: "#282c34"}}>
            <Typography.Title level={5}>
              {t('components.farmers')}
            </Typography.Title>
          </Divider>
          <Space direction="horizontal" style={{textAlign: "center",  padding: 5, justifyContent: "space-between"}}>
            <Search placeholder={t('general.searchtext')} allowClear onChange={onSearch} style={{ width: "100%" }} />
            
              <Button onClick={() => setModalOpen(true)}>
                <Space>
                  <EditOutlined />
                  {t('general.new')}
                </Space>
              </Button>
            
          </Space>
        </Layout>
        <Layout style={{padding: 10}}>
        {

            (farmers &&  // Show skeleton until fields are loaded from api
            <List
              style={{textAlign: "left"}}
              itemLayout="vertical"
              responsive="true"
              size="small"
              pagination={{
                pageSize: 7,
                size: "small",
                position: "bottom",
                align: "center",
                color: "#282c34",
                total: farmer_stats.count,
                onChange: (_page, pageSize) => {
                  loadMore(_page);
                }        
              }}
              dataSource={filtered_data}
              renderItem={renderUserItem}
            />)
        }
        </Layout>


        {/** Create New Farmer Modal */}
        <Modal
          title={t('general.create_new_farmer')}
          centered
          open={modal_open}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          afterClose={afterModalClose}
          footer={[
            <Button key="back" onClick={handleCancel}>
            {t('general.cancel')}
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            {t('general.create')}
          </Button>
          ]}
        >
          <Space direction="vertical" style={{width: "100%"}}>
            <Input size="large" placeholder={t('components.name')} prefix={<UserOutlined />} onChange={onFarmerNameChange}/>
            <Input size="large" placeholder={t('components.national_id')} prefix={<UserOutlined />} onChange={onFarmerNationalIDChange}/>
            {/* <Input size="large" placeholder={t('components.telephone_number')} prefix={<UserOutlined />} onChange={onFarmerTelephoneNumberChange}/>
            <Input size="large" placeholder={t('components.email')} prefix={<UserOutlined />} onChange={onFarmerEmailChange}/> */}
            <Input size="large" placeholder={t('components.street')} prefix={<UserOutlined />} onChange={onFarmerStreetChange}/>
            <Input size="large" placeholder={t('components.postal_code')} prefix={<UserOutlined />} onChange={onFarmerPostalCodeChange}/>
            <Input size="large" placeholder={t('components.province')} prefix={<UserOutlined />} onChange={onFarmerProvinceChange}/>
            <Input size="large" placeholder={t('components.city')} prefix={<UserOutlined />} onChange={onFarmerCityChange}/>
            <Input size="large" placeholder={t('components.country')} prefix={<UserOutlined />} onChange={onFarmerCountryChange}/>



           
          </Space>
        </Modal>  
      </>
  );
}

export default FarmersList;