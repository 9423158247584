import React, { Suspense, lazy, useContext, useEffect, useState} from "react";
import {Layout, Typography} from 'antd';
import CropClassificationStats from "../../cc-stats/cc-stats";
import TeamList from "../../team/TeamList";
import { BaseViewContext } from "../../../views/Base";

import useWindowDimensions from "../../../utilities/window_size";
import FarmersList from "../../farmers/FarmersList";
import SeasonsList from "../../seasons/SeasonsList";
import { GlobalContext } from "../../../App";
import ActionSheet from "./ActionSheet";

const LazyFieldsList = lazy(() => import('../../fields/FieldsList'));

//FIXME - Repetition; check App.js
const Loading = () => {
  return <p><i>Loading...</i></p>;
}

const {Sider} = Layout;

function LeftSidebarContent () {
  // Decide which content will be loaded based on current_view
  const {current_view} = useContext(BaseViewContext);

  const { 
    globalCCViewEnabled, 
    setGlobalCCViewEnabled,
  } = useContext(GlobalContext);



  return (
    <div>
       { globalCCViewEnabled &&
        <ActionSheet>
          <CropClassificationStats />
        </ActionSheet>
      }
    {
      ["Team", "Team Member"].includes(current_view?.viewName) &&
      <TeamList />
    }
    {
      ["Farmers", "Farmer"].includes(current_view?.viewName) &&
      <FarmersList />
    }
    {
      ["Seasons", "Season"].includes(current_view?.viewName) &&
      <SeasonsList />
    }
    {
      current_view?.viewName === "Crop Classification" &&
      <CropClassificationStats />
    }
    {
      ["Crop Growth", "Field Info", "Weather", "Home", "Fields Control Provisions", "Fields"].includes(current_view?.viewName) &&
        <Suspense fallback={<Loading />}>
          <LazyFieldsList />
        </Suspense>
    }

   
    </div>
  )
}

function LeftSidebar() {

  const [menu_collapsed, setMenuCollapsed] = useState(false);
  const { height, width } = useWindowDimensions();

  const SCREEN_WIDTH_LIMIT = 1300;

  useEffect(() => {
    if(width < SCREEN_WIDTH_LIMIT ){
      setMenuCollapsed(false);
    } else {
      setMenuCollapsed(false);
    }
  }, [width])

  const toggleMenu = () => {
    setMenuCollapsed(!menu_collapsed);
  };
  
  return (
    <>
      <Sider 
        theme="light"
        width={width <= 1680 ? 350 : "15vw"} //FIXME - This should be regulated from css maybe?
        collapsible
        collapsed={menu_collapsed}
        onCollapse={() => setMenuCollapsed(!menu_collapsed)}
        collapsedWidth={0}
        trigger={null}
        style={{
          minWidth: width <= 1680 ? 350 : "2vw", //FIXME - This should be regulated from css maybe?
          overflowY: "scroll", 
          backgroundColor: '#f7f7f8',
          borderRadius: "0px 0px 3px 3px",
          boxShadow: " 0px 0px 4px 1px rgba(0,0,0,.3)",
          zIndex: 1000,
        }}
      >
        <LeftSidebarContent />
      </Sider>

      
    </>
  );
}

export default LeftSidebar;