import React, { useState, useEffect, useRef } from 'react';
import {  Area, Line } from '@ant-design/plots';

import {  Avatar, Empty, Spin } from 'antd';
import { CloudDownloadOutlined} from '@ant-design/icons';
import { GetAnnotations } from "../../utilities/annotations";
import moment from 'moment';
import SatelliteDataService from '../../services/SatelliteData';
import { randomLineString } from '@turf/turf';

const IndexGraph = (props) => {
  const [chart_config, setChartConfig] = useState(null);
  const [dd_chart_config, setDDChartConfig] = useState(null);
  const [accdd_chart_config, setACCDDChartConfig] = useState(null);

  const [field_index, setFieldIndex] = useState(null);

  const indexChartRef = useRef();
  const DDChartRef = useRef();
  const ACCDDChartRef = useRef();
  const [tooltipPosition, setTooltipPosition] = useState(null);

  const config = {
    //data: [],
    colorField: 'category',// or seriesField in some cases  
    color: ({category}) => {
      if(category === 'Cloudy Days'){
        return "rgba(0, 0, 0, 0.27)";
      }
  
      return '#4caf50';
    },
    seriesField: "category",
    xField: 'date',
    yField: 'value',
    xAxis: {
      label: {
      }
    },
    yAxis: {
      //range: [0,10000],
      grid: {line: null},
    },
    // label: {
    //   layout: [
    //     {
    //       type: 'hide-overlap',
    //     },
    //   ],
    //   // 隐藏重叠label
    //   style: {
    //     textAlign: 'right',
    //   },
    //   formatter: (item) => {
    //     if(item.category === "Cloudy Days") {
    //       return item.cloud;
    //     }
  
    //     return item.value;
    //   },
    // },
    lineStyle: (item ) => {
      if(item.category === "Cloudy Days") {
        return {
          lineDash: [0, 50],
          lineOpacity: 1,
          lineWidth: 0,
        }
      }
    },
    label: {
      type: 'inner',
      offset: '-10%',
      content: '{percentage}',
    },
    point: {
      size: 5,
      style: (item) => {
        if(item.category === "Cloudy Days") {
          return {
            r: 5,
            lineWidth: 1,
            fillOpacity: 1,
          }
        }
        return {
          r: 3,
          lineWidth: 1,
          fillOpacity: 1,
        }
        
      },
      shape: (item) => {
        if(item.category === "Cloudy Days") {
          return <CloudDownloadOutlined />
        }
  
        return "circle"
      }
    },
    
    annotations: [],
    legend: {
      position: "bottom-left"
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    meta: props.duration ? 
      {
        value: {
          min: 0,
          max: 0.4,
          tickInterval: 0.1,
          range: [0, 0.4],
        },
      }
      : 
      {
        value: {
          min: 0,
          max: 1,
          tickInterval: 0.1,
          range: [0, 1],
        },
      },
    smooth: true,
    tooltip: {
      showCrosshairs: true,
      shared: true,
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { data, color } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                  >
                    <span className="g2-tooltip-marker" style={{ backgroundColor: color}}></span>
                    <span
                      style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                    >
                      <span style={{ marginRight: 16 }}>
                        {data.category === "Cloudy Days" ? "Cloudiness" : data.category}:
                      </span>
                      <span className="g2-tooltip-list-item-value">
                        {data.category === "Cloudy Days" ? data.cloud + "%" : data.value}
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
    onReady: (chart) => {
      chart.on("plot:mousemove", (evt) => {
        handleMousemove(evt, DDChartRef)
        handleMousemove(evt, ACCDDChartRef)
      }
    );
      chart.on("plot:mouseleave", handleMouseleave);
      chart.on('element:click', (e, element) => {
        console.log("Element Click in Chart: ", e.data.data, );
       /* const _params = new URLSearchParams()
        if(searchParams.get("show_notes")) {
          _params.delete("date")
        } else {
          _params.append("show_notes", "true");
        }
  
        _params.append("date", e.data.data.date);
        Navigate( )*/
      });
      return chart
    },
   
    /*onEvent: (chart, event) => {
      if (event.type === 'click') {
        console.log("LCIKED ON: ", event.target.value);
        const clickedLabel = event.target.get('origin');
        console.log('Clicked x-axis label:', clickedLabel);
        // Perform actions or logic based on the clicked x-axis label
      }
    },*/
  };

  const dd_config = {
    data: [],
    seriesField: "category",
    colorField: "category",
    color: ({category}) => {
      if(category === 'Cloudy Days'){
        return "rgba(0, 0, 0, 0.27)";
      }
  
      return '#4caf50';
    },
    xField: "date",
    yField: "value",
    tooltip: { showCrosshairs: true, shared: true },
    xAxis: {
      label: {
      }
    },
    yAxis: {
     // range: [0,1000],
      grid: {line: null},
    },
    legend: {
      position: "bottom-left"
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    lineStyle: (item ) => {
      if(item.category === "Cloudy Days") {
        return {
          lineDash: [0, 50],
          lineOpacity: 1,
          lineWidth: 0,
        }
      }
    },
    label: {
      type: 'inner',
      offset: '-10%',
      content: '{percentage}',
    },
    point: {
      size: 5,
      style: (item) => {
        if(item.category === "Cloudy Days") {
          return {
            r: 5,
            lineWidth: 1,
            fillOpacity: 1,
          }
        }
        return {
          r: 3,
          lineWidth: 1,
          fillOpacity: 1,
        }
        
      },
      shape: (item) => {
        if(item.category === "Cloudy Days") {
          return <CloudDownloadOutlined />
        }
  
        return "circle"
      }
    },
    onReady: (plot) => {
      plot.on("plot:mousemove", (evt) => {
        handleMousemove(evt, indexChartRef)
        handleMousemove(evt, ACCDDChartRef)
      }
    );
      plot.on("plot:mouseleave", handleMouseleave);
    },
  };

  const accdd_config = {
    data: [],
    seriesField: "category",
    colorField: "category",
    color: ({category}) => {
      if(category === 'Cloudy Days'){
        return "rgba(0, 0, 0, 0.27)";
      }
  
      return '#ffa726';
    },
    xField: "date",
    yField: "value",
    tooltip: { showCrosshairs: true, shared: true },
    xAxis: {
      label: {
      }
    },
    yAxis: {
      //range: [0,1000],
      grid: {line: null},
    },
    legend: {
      position: "bottom-left"
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
    lineStyle: (item ) => {
      if(item.category === "Cloudy Days") {
        return {
          lineDash: [0, 50],
          lineOpacity: 1,
          lineWidth: 0,
        }
      }
    },
    label: {
      type: 'inner',
      offset: '-10%',
      content: '{percentage}',
    },
    point: {
      size: 5,
      style: (item) => {
        if(item.category === "Cloudy Days") {
          return {
            r: 5,
            lineWidth: 1,
            fillOpacity: 1,
          }
        }
        return {
          r: 3,
          lineWidth: 1,
          fillOpacity: 1,
        }
        
      },
      shape: (item) => {
        if(item.category === "Cloudy Days") {
          return <CloudDownloadOutlined />
        }
  
        return "circle"
      }
    },
    onReady: (plot) => {
      plot.on("plot:mousemove", (evt) => {
        handleMousemove(evt, DDChartRef)
        handleMousemove(evt, indexChartRef)
      }
    );
      plot.on("plot:mouseleave", handleMouseleave);
    },
  };

  const handleMousemove = (evt, targetChartRef) => {
    const point = evt?.event?.data?.point;
    if (point) {
      setTooltipPosition(point);
      if (targetChartRef.current) {
        targetChartRef.current.chart.showTooltip({ x: point.x, y: point.y });
      }
    }
  };

  const handleMouseleave = () => {
    setTooltipPosition(null);
    if (indexChartRef.current) indexChartRef.current.chart.hideTooltip();
    if (DDChartRef.current) DDChartRef.current.chart.hideTooltip();
    if (ACCDDChartRef.current) ACCDDChartRef.current.chart.hideTooltip();
  };


  const {current_field, index, dd, accdd, show_visits} = props;

  if(!props.duration) {
    var start_date = current_field.current_season?.startDate || moment().subtract(6, "months").format("YYYY-MM-DD");
    var end_date = current_field.current_season?.endDate || moment().format("YYYY-MM-DD");
  } else {
    var start_date = moment().subtract(props.duration, "days").format("YYYY-MM-DD");
    var end_date = moment().format("YYYY-MM-DD");
  }

  useEffect(() => {
    if(props.current_field && props.index) {

      console.log("PROPS: ", props.current_field, props.index)  

      // if we have not processed this index, but can show timeseries by on-demand processing
      //FIXME - is this a good design?
      // if((props.current_field.indexes && !props.current_field.indexes[props.index]) || props.index === 'ndwi') { 
      SatelliteDataService.getIndexTimeseriesForField(props.current_field.id, props.index, moment(start_date).format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"), (result) => {
        setFieldIndex(result.map(item => {
          return {date: item.date, value: item.value, cloud: item.cloud, category: props.index.toUpperCase()}
        }).sort((a, b) => (a.date > b.date) ? 1 : (a.date < b.date) ? -1 : 0))
      })
      // } else {
      const annotations = GetAnnotations(props.current_field?.provisions?.data);
      config.annotations = annotations;

      let _field_index = props.current_field?.indexes && props.current_field?.indexes[props.index]?.filter((item) => {
        item.date = moment(item.date).format("YYYY-MM-DD")
        return item.value >= 0 // avoid negative values
      }).map(item => {
        return {date: item.date, value: item.value, cloud: item.cloud, category: props.index.toUpperCase()}
      }).sort((a, b) => (a.date > b.date) ? 1 : (a.date < b.date) ? -1 : 0);


        // if(show_visits) {
        //   // const annotations = GetAnnotations(current_field?.provisions?.data);
        //   // config.annotations = annotations;

        //   const _visits = props.current_field?.provisions?.map((item) => {
        //     return {date: item.date, value: 1, cloud: 0, category: "Field Visit"}
        //   })

        //   _field_index = _field_index.concat(_visits);
        //   console.log("FIELD INDEX: ", _field_index);
        // }
        setFieldIndex(_field_index);
      //}
    }

    if(dd && accdd) {
      dd_config.data = dd;
      accdd_config.data = accdd;

      dd_config.xAxis.label = {
        formatter: (date, idx) => {
          return moment(date).format("MMM DD").toString()
        }
      }

      accdd_config.xAxis.label = {
        formatter: (date, idx) => {
          return moment(date).format("MMM DD").toString()
        }
      }

      console.log("DD: ", dd, "ACCDD: ", accdd)

      setDDChartConfig({...dd_config});
      setACCDDChartConfig({...accdd_config});
    }

    
  }, [props.current_field, props.index])

  useEffect(()=>{

    if(! (props.current_field  && field_index) ) {
      return
    }

    // const _filtered_index = field_index.filter((index_date_item) => {
    //   return index_date_item.cloud < 60;
    // })

    const _cloudy_days = field_index.filter((index_date_item) => {
      return index_date_item.cloud > 60 && index_date_item.value >= 0 // avoid cloud more than 60% and negative index values
    }).map(item => {
      return {date: item.date, value: 1, cloud: item.cloud, category: "Cloudy Days"}
    })

    _cloudy_days.sort((a, b) => (a.date > b.date) ? 1 : (a.date < b.date) ? -1 : 0);
    config.data = field_index.concat(_cloudy_days);
    
    config.xAxis.label = {
      formatter: (date, idx) => {
        return moment(date).format("MMM DD").toString()
      }
    }

    config.yAxis.range = [0, 1 ] //props.range !== "bands" && [0, 1];
    if (props.range === "bands") {
      config.meta.value = {
        min: 1000,
        max: 4000,
        tickInterval: 1000,
      };
    } else {
      config.meta.value = {
        min: 0,
        max: 1,
        tickInterval: 0.1,
      };
    }

    setChartConfig({...config});
  }, [field_index])

  return (
    <>

    
    { chart_config?.data?.length > 0
      ?
      <>
        <Line style={{height: "20vh", width: "100%"}} chartRef={indexChartRef} {...chart_config}/>
      </>
      :
      !dd && !accdd &&
      <Spin /> 
    }

    {dd_chart_config?.data && accdd_chart_config?.data &&
      <>
        <Line style={{height: "20vh", width: "100%"}} chartRef={DDChartRef} {...dd_chart_config} />
        <Area style={{height: "20vh", width: "100%"}} chartRef={ACCDDChartRef} {...accdd_chart_config} />
      </>
    }
    
    {/**
     * this Avatar.Group needs to be here - I believe - to generate a temp css for the annotations html
     * they are coming from utilities/annotations.js as annotations obj to be used in graph config.
     */}
    <Avatar.Group maxCount={10} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}></Avatar.Group>
    </>
  )
}

export default IndexGraph;




/** {
          type: 'html',
          //position: ['median', 'median'],
          position:  { date: '2023-04-17', value: 0.1 },
          html: '
            <div class="ant-avatar-group css-dev-only-do-not-override-1vtf12y">
            
              <span class="ant-avatar ant-avatar-sm ant-avatar-circle ant-avatar-image css-dev-only-do-not-override-1vtf12y">
                <img src="https://xsgames.co/randomusers/avatar.php?g=pixel&amp;key=2">
              </span>
            
              <span class="ant-avatar ant-avatar-sm ant-avatar-circle css-dev-only-do-not-override-1vtf12y" style="background-color: rgb(245, 106, 0);">
                <span class="ant-avatar-string" style="transform: scale(1) translateX(-50%);">K</span>
              </span>
              
              <span class="ant-avatar ant-avatar-sm ant-avatar-circle css-dev-only-do-not-override-1vtf12y" style="color: rgb(245, 106, 0); background-color: rgb(253, 227, 207);">
                <span class="ant-avatar-string" style="transform: scale(1) translateX(-50%);">+1</span>
              </span>
            
            </div>',
          style: {
            fill: 'red',
          },
        }, */


        /** {
          type: 'html',
          //position: ['median', 'median'],
          position:  { date: '2023-04-17', value: 0.1 },
          html: '<div class="ant-avatar-group css-dev-only-do-not-override-1vtf12y"><span class="ant-avatar ant-avatar-sm ant-avatar-circle ant-avatar-image css-dev-only-do-not-override-1vtf12y"><img src="https://xsgames.co/randomusers/avatar.php?g=pixel&amp;key=2"></span><span class="ant-avatar ant-avatar-sm ant-avatar-circle css-dev-only-do-not-override-1vtf12y" style="background-color: rgb(245, 106, 0);"><span class="ant-avatar-string" style="transform: scale(1) translateX(-50%);">K</span></span><span class="ant-avatar ant-avatar-sm ant-avatar-circle css-dev-only-do-not-override-1vtf12y" style="color: rgb(245, 106, 0); background-color: rgb(253, 227, 207);"><span class="ant-avatar-string" style="transform: scale(1) translateX(-50%);">+1</span></span></div>',
          style: {
            fill: 'red',
          },
        }, */



        /**
         * 
         *<div class="ant-avatar-group css-dev-only-do-not-override-1vtf12y"><span class="ant-avatar ant-avatar-circle ant-avatar-image css-dev-only-do-not-override-1vtf12y"><img src="https://xsgames.co/randomusers/avatar.php?g=pixel&amp;key=2"></span><span class="ant-avatar ant-avatar-circle css-dev-only-do-not-override-1vtf12y" style="background-color: rgb(245, 106, 0);"><span class="ant-avatar-string" style="transform: scale(1) translateX(-50%);">K</span></span><span class="ant-avatar ant-avatar-circle css-dev-only-do-not-override-1vtf12y" style="color: rgb(245, 106, 0); background-color: rgb(253, 227, 207);"><span class="ant-avatar-string" style="transform: scale(1) translateX(-50%);">+2</span></span></div>
         */