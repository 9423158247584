import axios_instance from '../utilities/axios_instance';

const ParcelService = {
  
  getParcelForPoint: async (point, _clb) => {
    
    try {
      const URL = `parcel/?lat=${point.lat}&lng=${point.lng}`

      const response = await axios_instance.get(`${URL}`);      
      console.log("TKGM Response", response.data.data)
      _clb(response.data.data);
    } catch (error) {
      console.error(error);
    }
  },

  

};

export default ParcelService;