import React, {useContext} from "react";
import { Layout, List, Space, Divider, Button} from 'antd';
import { TileLayer } from "react-leaflet";

// Import Global Context 
import {GlobalContext} from "../../App";
import { useTranslation } from "react-i18next";

export const MapLayers = [
  {
    name: 'ESRI WorldImagery',
    layer:  <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
        attribution='&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
      />,
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
    attribution: '&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
  },
  {
    name: 'OpenStreetMap',
    layer: <TileLayer
      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />,
    attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  },
  {
    name: 'Google Satellite',
    layer: <TileLayer
      attribution='&amp;copy '
      url="https://mts0.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
    />,
    attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: "https://mts0.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
  },
  {
    name: 'OSM World Light Gray Base',
    layer: <TileLayer
      attribution='&amp;copy '
      url="http://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
    />,
    attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: "http://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
  }
]

function MapOverlays(props) {
  const { t, i18n } = useTranslation("translation");
  const {selected_map_layer, setSelectedMapLayer} = useContext(GlobalContext);

  const handleLayerClick = (layer_item) => {
    setSelectedMapLayer(layer_item);
  }


  return (
    <Space direction="vertical" style={{width: "100%"}}>
      <Divider orientation="left" style={{color: "#282c34"}}>{t('general.mapoverlays')}</Divider>
      <Layout style={{backgroundColor: "#f5f5f5", color: "#282c34"}}>
        <List
        dataSource={MapLayers}
        bordered={false}
        size="small"
        itemLayout="horizontal"
        split
        renderItem={(item, index) => (
            <List.Item style={{cursor: "pointer"}} onClick={() => handleLayerClick(item)}>
              <List.Item.Meta
                  title={
                    <Button type="">
                      {selected_map_layer.name === item.name ? <b>{item.name}</b> : item.name}
                    </Button>
                  }
              />
            </List.Item>
        )}
        />
      </Layout>
    </Space>
  );
}

export default MapOverlays;