import React, { useEffect, useState, createContext, Suspense } from 'react';

//import Weather from './components/weather/Weather';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './App.less';
import { ConfigProvider, Layout, Spin } from 'antd'

//Views
import AuthView from './views/Auth/Auth';
import CropClassificationView from './views/CropClassification';
import TeamView from './views/Team';
import BaseView from './views/Base';

// Utilities
import { ProtectedRoute } from "./utilities/protected_route";

import AuthContext from './contexts/AuthContext';

import kc from './utilities/Keycloak';
import UserService from './services/User';

import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

//Styles
import "./App.css"
import './styles/index.css'

import { message  } from 'antd';

import { lazy } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { set } from 'react-ga';

const LazyHomeView = lazy(() => import('./views/Home'));
const LazyNDVIView = lazy(() => import('./views/CropGrowth'));
const LazyWeatherView = lazy(() => import('./views/Weather'));
const LazyFieldNDVIView = lazy(() => import('./views/FieldNDVI'));
const LazyFieldsControlProvisionsView = lazy(() => import('./views/FieldControlProvisions/FieldsControlProvisions'));
const LazyFieldsView = lazy(() => import('./views/Fields'));
const LazyAddEditProvisionView = lazy(() => import('./views/FieldControlProvisions/AddEditProvision'));
const LazyTeamView = lazy(() => import('./views/Team'));
const LazyFarmersView = lazy(() => import('./views/Farmers'));
const LazySeasonsView = lazy(() => import('./views/Seasons'));
const LazySeasonProfileView = lazy(() => import('./views/SeasonProfile'));
const LazyFarmerView = lazy(() => import('./views/FarmerProfile'));
const LazyTeamMemberProfile = lazy(() => import('./views/TeamMemberProfile'));
const LazyAuthView = lazy(() => import('./views/Auth/Auth'));

//FIXME - Repetition; check LeftSidebar.js, IndexTimeline.js, AddEditProvisions.js, agcc-map.js
const Loading = () => {
  return (
    <Layout className='view-loading-overlay'>
      <div className='view-loading-overlay-content'>
        <Spin />
      </div>
    </Layout>
  );
}

// GLobal Context
export const GlobalContext = createContext({});

function QueryParams(props) {
  const setQueryParams = props.setQueryParams;
  const query_params = props.queryParams;

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
 
  const query = useQuery();
  useEffect(()=>{
    if (query !== query_params)
		  setQueryParams(query);

	}, [ query, query_params, setQueryParams])
  
  return (<></>);
}

const MainLayout = () => {
  return (
      <Routes>
        {/* Login View */}
        <Route exact path="/login" element={<AuthView type="login"/>} />

        {/* Register View */}
        <Route exact path="/register" element={<AuthView type="register" />} />

        {/* Logout */}
        <Route path="/logout" element={<AuthView type="logout" />} />

        {/** Protected Base View */}
        <Route path="/" element={<ProtectedRoute><BaseView /></ProtectedRoute>}>
          {/* Home View  */}
          <Route exact path="/" element={<Suspense fallback={<Loading />}><LazyHomeView /></Suspense>}>
             {['/', '/home'].map(path => <Route path={path} element={<Suspense fallback={<Loading />}><LazyHomeView /></Suspense>} />)} 
            <Route path="/home/fields/:id" element={ <Suspense fallback={<Loading />}><LazyHomeView /></Suspense>} />
          </Route>

          <Route exact path="/seasons" element={<Suspense fallback={<Loading />}><LazySeasonsView /></Suspense>}/>
          <Route exact path="/seasons/season/:id" element={ <Suspense fallback={<Loading />}><LazySeasonProfileView /></Suspense>} />

          {/* Weather */}
          <Route exact path="/weather" element={ <Suspense fallback={<Loading />}><LazyWeatherView /></Suspense> }/>
          <Route exact path="/weather/fields/:id" element={ <Suspense fallback={<Loading />}><LazyWeatherView /></Suspense> } />

          {/* NDVI */}
          <Route exact path="/crop-growth" element={<Suspense fallback={<Loading />}><LazyNDVIView /></Suspense>} />

          {/* Fields */}
          <Route exact path="/fields" element={<Suspense fallback={<Loading />}><LazyFieldsView /></Suspense>} />

          {/* NDVI */}
          <Route path="/ndvi" element={ <Suspense fallback={<Loading />}><LazyNDVIView /></Suspense>} />
          <Route path="/ndvi/fields/:id" element={<Suspense fallback={<Loading />}> <LazyFieldNDVIView /></Suspense> } />

          {/* Field Control Provisions */}
          <Route path="/provisions" element={ <Suspense fallback={<Loading />}><LazyFieldsControlProvisionsView /></Suspense>} />
          <Route path="/provisions/fields/:id" element={<Suspense fallback={<Loading />}> <LazyFieldsControlProvisionsView /></Suspense> } />
          <Route path="/provisions/new" element={<Suspense fallback={<Loading />}> <LazyAddEditProvisionView /></Suspense> } />
          <Route path="/provisions/edit/:id" element={<Suspense fallback={<Loading />}> <LazyAddEditProvisionView /></Suspense> } />

          {/* Field Control Provisions */}
          <Route path="/farmers" element={ <Suspense fallback={<Loading />}><LazyFarmersView /></Suspense>} />
          <Route path="/farmers/farmer/:id" element={<Suspense fallback={<Loading />}> <LazyFarmerView /></Suspense> } />

          {/* Team */}
          <Route exact path="/team" element={<Suspense fallback={<Loading />}><LazyTeamView /></Suspense>} />
          <Route exact path="/team/member/:id" element={<Suspense fallback={<Loading />}><LazyTeamMemberProfile /></Suspense>} />
        
          {/* Crop Classification */}
          <Route path="/cc" element={<CropClassificationView />} >
            <Route exact path="/cc/yield/:crop" element={ <Suspense fallback={<Loading />}><CropClassificationView /></Suspense> } />
          </Route>
        </Route>

      
      </Routes>
  )
}

const App = () => {
  
  // Selected map layer state is updated by MapOverlays.js component
  // "./components/map-overlays/MapOverlays.js"
  const [selected_map_layer, setSelectedMapLayer] = useState({});
  const [selected_index_overlay, setSelectedIndexOverlay] = useState(null); // index layers, NDVI, EVI, etc...
  const [selected_index, setSelectedIndex] = useState(null); // hold selected index from MapsideZone indices menu
  const [query_params, setQueryParams] = useState();
  const [selected_user_asset, setSelectedUserAsset] = useState();
  const [globalFieldDrawUIEnabled, setGlobalFieldDrawUIEnabled] = useState(false);
  const [globalCCViewEnabled, setGlobalCCViewEnabled] = useState(false);
  const [globalSelectedRegion, setGlobalSelectedRegion] = useState({});
  const [global_map_center, setGlobalMapCenter] = useState(false);
  const [drawnField, setDrawnField] = useState({});
  const [messageAPI, contextHolder] = message.useMessage();

  const [proceed, setProceed] = useState(false);
  
  // Init Local Storage Items
  if(!localStorage.getItem("FieldsSatData")){ // satellite data for the fields, ndvi, constrast_ndvi, sentinel2 etc.
    localStorage.setItem("FieldsSatData", JSON.stringify({}));
  }

  if(!localStorage.getItem("Fields")) { // all fields data coming from /field
    localStorage.setItem("Fields", JSON.stringify({}));
  }

  if(!localStorage.getItem("fieldsdata")) { // all fields data coming from /data/field
    localStorage.setItem("fieldsdata", JSON.stringify({data: [], last_update: "", should_update: ""}));
  }

  if(!localStorage.getItem("Segmentations")) { // 
    localStorage.setItem("Segmentations", JSON.stringify([]));
  }

  if(!localStorage.getItem("token")) { // 
    localStorage.setItem("token", null);
  }

  return (
    <ConfigProvider theme="light">
      <GlobalContext.Provider 
        value={{ 
          selected_map_layer, 
          setSelectedMapLayer, 
          query_params, 
          setQueryParams,
          selected_user_asset,
          setSelectedUserAsset,
          messageAPI, 
          contextHolder,
          selected_index_overlay,
          setSelectedIndexOverlay,
          selected_index,
          setSelectedIndex,
          globalFieldDrawUIEnabled,
          setGlobalFieldDrawUIEnabled,
          globalCCViewEnabled, 
          setGlobalCCViewEnabled,
          globalSelectedRegion,
          setGlobalSelectedRegion,
          drawnField,
          setDrawnField,
          global_map_center,
          setGlobalMapCenter,
        }}>
        <div className="App">
        <ReactKeycloakProvider 
          authClient={kc} 
          initOptions={{onLoad: 'check-sso', silentCheckSsoRedirectUri: window.location.origin + '/app/silent-check-sso.htm'}}
          LoadingComponent={<Loading />}
          onEvent={(event) => {
            //FIXME - Handle onTokenExpired and onAuthRefreshSuccess
            switch (event) {
              case 'onTokenExpired':
                // kc.updateToken(300).then(
                //   (response) => {
                //      //I want to update my existing Token
                //    alert("response: ", response )
                //   })
                //   .catch(error => {
                //       console.log("error: ", error)
                //   })
                break;
              case 'onAuthRefreshSuccess':
                console.log("Token Refreshed");
                break;
              default:
                break;
            }
          }}
          >
            <BrowserRouter basename='/app'>
              <QueryParams queryParams={query_params} setQueryParams={setQueryParams}/>
              <MainLayout >  
              </MainLayout>
            </BrowserRouter>
          </ReactKeycloakProvider>
        </div>
      </GlobalContext.Provider>
    </ConfigProvider>
  );
};

export default App