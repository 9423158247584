import { AppstoreOutlined, BarsOutlined, EditOutlined, EllipsisOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Card, Divider, Layout, Segmented, Spin, Table, Typography } from "antd"
import { useEffect, useState } from "react"

const TeamCards = (props) => {

  return (
      <Layout style={{width: "100%", backgroundColor: 'transparent', display: "flex", flexDirection: "row", flexWrap: "wrap", flexFlow: "row wrap", justifyContent: "space-around"}}>
      {
        props.data?.map((team_member, idx) => {
          return (
            <Card
              className="data-avatars-card"
              hoverable
              style={{ width: 300 }}
              
              actions={[
                <SettingOutlined key="setting" />,
                <EditOutlined key="edit" />,
                <EllipsisOutlined key="ellipsis" />,
              ]}
            >
              <Card.Meta
                avatar={<Avatar size="large" style={{ backgroundColor: '#f56a00' }}>{team_member.name.split(" ")[0][0] + team_member.name.split(" ")[1][0]}</Avatar>}
                title={team_member.name}
                description={<>
                  <Typography.Text type="secondary">{team_member.email}</Typography.Text> 
                   <br />
                  <Typography.Text  type="secondary">{team_member.phone}</Typography.Text>
                </>}
              />
            </Card>
          ) 
        })
      }
      </Layout>
  )
}

export default TeamCards