import axios_instance from "../utilities/axios_instance";


const NotesService = {
  getNotesByField: async (id) => {
     try {
      const notes = await axios_instance.get(`note/?field_id=${id}`);
      return notes.data.data.notes;
    } catch (error) {
      console.error(error);
    }
  },
  
  getAllNotes: async () => {
    try {
     const notes = await axios_instance.get(`note`);
     return notes.data.data.notes;
   } catch (error) {
     console.error(error);
   }
 }



};

export default NotesService;