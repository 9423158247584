import { AppstoreOutlined, BarsOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Button, Cascader, Divider, Input, Layout, Segmented, Space, Spin, Table, Tooltip, Typography } from "antd"
import { useEffect, useState } from "react"
import TeamTable from "./TeamTable";
import TeamCards from "./TeamCards";
import SearchToolbar from "../search-toolbar/search-toolbar";
import { useTranslation } from "react-i18next";

const TeamData = (props) => {
  const { t, i18n } = useTranslation("translation");
  const [data_layout, setDataLayout] =  useState("table");

  const [team_data, setTeamData] = useState([]);
  const [filtered_team_data, setFilteredTeamData] = useState(null);

  useEffect(() => {
    if(props.data){
      setTeamData(props.data); 
      setFilteredTeamData(props.data);
    }
  }, [props.data])


  const onSearch = (_search_criteria) => {

    if (_search_criteria.search_query) {
      const value =  _search_criteria.search_query.toLowerCase()
    const _filtered_data = team_data.filter((member) => {
      return member.name.indexOf(value) > -1 ||
          member.email.indexOf(value) > -1 ||
          member.phone?.indexOf(value) > -1 ||
          member.address?.street.indexOf(value) > -1 ||
          member.address?.suite.indexOf(value) > -1 ||
          member.address?.city.indexOf(value) > -1 ||
          member.address?.zip_code.indexOf(value) > -1;
    })

      //setSearchCriteria(_search_criteria)

      setFilteredTeamData(_filtered_data)
    
    } else {
      setFilteredTeamData(team_data)
    }
  
  }

  const onChange = (e) => {    
    
    if (e.target.value === "") {
      //setFilterResultTitle("All Fields");
      setFilteredTeamData(team_data);
      return;
    }

    const value =  e.target.value.toLowerCase();
    const _filtered_data = team_data.filter((m) => {
      
      return m.name.indexOf(value) > -1 ||
          m.email?.indexOf(value) > -1 ||
          m.phone?.indexOf(value) > -1 ||
          m.address?.street.indexOf(value) > -1 ||
          m.address?.suite.indexOf(value) > -1 ||
          m.address?.city.indexOf(value) > -1 ||
          m.address?.zip_code.indexOf(value) > -1;
    })

    //setFilterResultTitle(`${_filtered_data.length} results for ${e.target.value}`);
    setFilteredTeamData(_filtered_data)
  }

  const onChangeCascader = (value, selectedOptions) => {
    console.log(value, selectedOptions);
  };

  return (
      <>
      <Space.Compact block={true} direction='horizontal' size={"large"} align='center' style={{width: "95%", margin: "auto"}}>
        <SearchToolbar 
          onSearch={onSearch}
          onChange={onChange}
          users={false} 
          farmers={false} 
          onChangeUsers={onChangeCascader}
          onChangeFarmers={onChangeCascader}
        />
        <div className="layout-change-segmented">
          <Segmented
            onChange={(value)=>{setDataLayout(value)}}
            options={[
            {
                label: t('components.searchbar.table'),
                value: 'table',
                icon: <BarsOutlined />,
            },
            {
              label: t('components.searchbar.avatars'),
                value: 'avatars',
                icon: <AppstoreOutlined />,
            },
            ]}
          />
        </div>
        </Space.Compact>
        <br />
        { data_layout === "table" &&
          <Layout className="data-table-layout">
             <TeamTable data={team_data} filtered_data={filtered_team_data} />
          </Layout>
        }

        { data_layout === "avatars" &&
          <Layout className="data-avatars-layout">
            <TeamCards data={filtered_team_data}/>
          </Layout>
       }
      </>
  )
}

export default TeamData