import { Space, Button, Layout, Typography} from 'antd';
import { useCallback, useContext, useEffect } from "react";

  // Components
import Logo from '../../components/logo/Logo'

import "./Login.css"

import LoginBranding from "./Branding";
import Texty from 'rc-texty';
import "./Login.less"
import { BaseViewContext } from '../Base';
import AuthContext from '../../contexts/AuthContext';
import { useKeycloak } from '@react-keycloak/web';
import useWindowDimensions from '../../utilities/window_size';
  
  const AuthView = () => {

    //const {keycloak} = useContext(AuthContext);
    const {keycloak, initialized} = useKeycloak();

    const { height, width } = useWindowDimensions();
    
    const login = useCallback(() => {
      if(keycloak) {
        return keycloak.login({redirectUri: window.location.origin + "/app/home"})
      }
    }, [keycloak])
    
    const register = useCallback(() => {

      console.log(keycloak);
      if(keycloak) {
        return keycloak?.register({redirectUri: window.location.origin + "/app/login"})
      }
    }, [keycloak])

    /*useEffect(() => {
      if(props.type === "logout") {
        keycloak.logout();
      }
    }, [keycloak, props.type])*/

    return (
  
   
    <Space direction='horizontal' style={{margin: "auto",width: "100%", justifyContent:"space-evenly"}}>
        {/* Branding side of the login page */}
        { width > 1300 &&
          <LoginBranding />
          }

        {/* Login side of the Login page */}
    
        <Space direction='vertical' style={{padding: 10}}>
          <Space direction='horizontal' style={{ margin: "auto", display: "flex", width: "100%", }}>
            <div style={{width: 35}} >
              <Logo brandmark={true} />
            </div>
            <Typography.Title style={{fontSize: 35, whiteSpace:"nowrap"}} type='secondary'>
              <Texty>
                Agcurate FieldOps™
              </Texty>
            </Typography.Title>
          </Space>
          <Space direction={width > 1000 ? "horizontal" : "vertical"} size="large" style={{margin: "auto",}}>
            <Button className="agci-button login-button" type='primary' onClick={() => login()}>
              Login
            </Button>
            <Button className="agci-button register-button" type='primary' onClick={() => register()}>
              Register
            </Button>
          </Space>
        </Space>

      </Space>
  );
};

  export default AuthView;