import axios from "axios";
//import keycloak from "./Keycloak";

const API = process.env.REACT_APP_API_URL;


const axios_instance = axios.create({
  baseURL : API,
  //headers: {'Authorization' : "Bearer "+localStorage.getItem("token")}
});

// Add a request interceptor
axios_instance.interceptors.request.use(async function (config) {
  // Do something before request is sent;
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;
  return config
}, 
function (error) {
  // Do something with request error
  return Promise.reject(error);
});

async function authInterceptor(error) {
  console.log(error);

  if(error.response.status === 401) { // if unauthorized somehow
    //FIXME - Make it more user friendly and cover all future edge cases
    window.location.href = "/app/login"
  }

  return Promise.reject(error);
}

axios_instance.interceptors.response.use(null, authInterceptor);

// // Response interceptor for API calls
// axios_instance.interceptors.response.use((response) => {
//   return response
// }, async function (error) {
//   const originalRequest = error.config;
//   if (error.response.status === 403 && !originalRequest._retry) {
//     originalRequest._retry = true;

//     keycloak.updateToken(5)
//     .then((resp) => {
//       console.log("Token refreshed", resp);
//       const access_token = resp.access_token
//       localStorage.getItem("token", access_token);
//       axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
//       return axios_instance(originalRequest);
//     })
//     .catch(()=> {
//       console.log("Token refresh failed");
//       //window.location.href = "/login"
//     });

    
//   }
//   return Promise.reject(error);
// });


export default axios_instance;