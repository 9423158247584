import axios_instance from "../utilities/axios_instance";
import moment from "moment";

// Utilities
import {flattenPolygonCoordinates} from '../utilities/polygon_util';
import SatelliteDataService from "./SatelliteData";

const FieldsService = {
  
  getAllFields: async (search_criteria, clb) => {
     try {
      const {user, farmer, season, search_query, page, pageSize} = search_criteria
      let response = null;

      let query = "";
      query += search_query ? `search_query=${search_query}&` : "";
      query += user ? `assigneeId=${user?.id}&` : "";
      query += farmer ? `farmerId=${farmer?.id}&` : "";
      query += season ? `seasonId=${season?.id}&` : "";
      query += page ? `page=${page}&` : "";
      query += pageSize || pageSize ? `pageSize=${pageSize}` : "";
    
      console.log("QUERY: ", query);
      response = await axios_instance.get(`field?${query}`);

      
      const {rows, count} = response.data.data.fields;
      clb({data: rows, count});
      return;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getField: async (id, start_date, end_date) => {
    try {
      const field = (await axios_instance.get(`field/${id}/`)).data.data.field;

      let field_data;
      
      if (start_date && typeof end_date === "undefined") {
        field_data = await FieldsService.getFieldData(id, start_date);

        return {...field, ...field_data}
      }

      if (field.current_season && !(start_date && end_date)) {
        if(moment(field.current_season.startDate).diff(moment(), 'days') >= 0) { // if season start date is in the future, a.k.a season hasn't started yet
          const _current_date = moment().format("YYYY-MM-DD");
          field_data = await FieldsService.getFieldData(id, _current_date);
        } else {
          field_data = await FieldsService.getFieldDataInRange(id, field.current_season.startDate, field.current_season.endDate);
        }

        return {...field, ...field_data}
      }

      
      // Check if field is currently operated in season
      if (start_date && end_date) {
        console.log("BURADA");
        field_data = await FieldsService.getFieldDataInRange(id, start_date, end_date);  
      } else {
        field_data = await FieldsService.getFieldData(id, start_date); // Gets date with currrent date
      }
      
      let _field_data = {...field, ...field_data}
      return _field_data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getFieldsByUser: async (user_id, page) => {
    try {
      let aggregated_fields_data = []; //both fields and fields_data
      const fields = await axios_instance.get(`field/?assigneeId=${user_id}&page=${page || 1}`);
      for(var i = 0; i < fields.data.data.fields.length; i++){
        const field = fields.data.data.fields[i];
        field.key = i; // for uniqueness of each field across the app
        const id = field.id;
        //const data = await FieldsService.getFieldData(id);
        aggregated_fields_data.push({...field});
      }
      return aggregated_fields_data;
    } catch (error) {
      throw error;
    }
  }, 

  getFieldsByFarmer: async (farmer_id, page, _clb) => {
    try {
      let aggregated_fields_data = []; //both fields and fields_data
      const fields = await axios_instance.get(`field/?farmerId=${farmer_id}&page=${page || 1}`);
      for(var i = 0; i < fields.data.data.fields.length; i++){
        const field = fields.data.data.fields[i];
        field.key = i; // for uniqueness of each field across the app
        const id = field.id;
        //const data = await FieldsService.getFieldData(id);
        aggregated_fields_data.push({...field});
      }
      _clb(aggregated_fields_data);
    } catch (error) {
      throw error;
    }
  }, 
  getFieldsBySeason: async (season_id, page, clb) => {
    try {
      let aggregated_fields_data = []; //both fields and fields_data
      const fields = await axios_instance.get(`field/?seasonId=${season_id}&page=${page || 1}`);
      for(var i = 0; i < fields.data.data.fields.length; i++){
        const field = fields.data.data.fields[i];
        field.key = i; // for uniqueness of each field across the app
        const id = field.id;
        //const data = await FieldsService.getFieldData(id);
        aggregated_fields_data.push({...field});
      }
      if(clb )
        clb(aggregated_fields_data);
      else 
        return aggregated_fields_data
    } catch (error) {
      throw error;
    }
  }, 

  // FIELD DATA RELATED SERVICES
  getFieldData: async (id, dt) => { 
    try {
      let URL = `data/field?id=${id}&date=${dt ? dt: moment().format("YYYY-MM-DD")}`;

      const field_data_response = await axios_instance.get(URL);
      let field_data = field_data_response.data.data;

      return field_data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getFieldDataInRange: async (id, start_date, end_date) => { 
    try {
      let URL = `data/field?id=${id}&start_date=${start_date}&end_date=${end_date}`;
      const field_data_response = await axios_instance.get(URL);
      let field_data = field_data_response.data.data;

      return field_data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getAllFieldsData: async (clb) => {
    try {
      const _fieldsdata = JSON.parse(localStorage.getItem("fieldsdata"));
      const now = moment();
      const last_update = moment(_fieldsdata.last_update || 0);
      const fields = await FieldsService.getAllFields();

      //Either fetch the fields again or get the data from localStorage.
      if (_fieldsdata.data.len === 0 || now.diff(last_update, 'h') > 24 || _fieldsdata.should_update) {
        
        let field_data_promises = []; //both fields and fields_data

        for(var i = 0; i < fields.length; i++){
          const field = fields[i];
          field.key = i; // for uniqueness of each field across the app
          const id = field.id;
          const data = FieldsService.getFieldData(id);
          field_data_promises.push(data);
        }

        Promise.all(field_data_promises).then(function(fields_data) {

          let aggregated_fields_data = [];

          for(var i = 0; i < fields.length; i++){
            const field = fields[i];
            const field_data = fields_data[i];
            aggregated_fields_data.push({...field_data, ...field});  
          }

          
          //Check AGCC-147 - https://agcurate.atlassian.net/jira/software/c/projects/AGCC/boards/17?selectedIssue=AGCC-147
          localStorage.setItem("fieldsdata", JSON.stringify({data: aggregated_fields_data, should_update: false, last_update: moment()}));

          clb(aggregated_fields_data, fields);
        });
  
      } else {
        // /** FIXME FIXME  - RETIRE HERE
        //  * this should be calculated on the backend
        //  * it is a temporary solutiosn
        //  */
        // const fld_data = _fieldsdata.data.map((field)=> {
        //   field.lowest_growth = -1 * field.lowest_growth;
        //   field.highest_growth = -1 * field.highest_growth;
        //   field.ytd_crop_growth = -1 * field.ytd_crop_growth;
        //   field.mtd_crop_growth = -1 * field.mtd_crop_growth;
        //   return field
        // })
        // return fld_data;
        clb(_fieldsdata.data, fields);
      }
    } catch (e) {
      throw e;
    }
  },

  createField: async (field) => {
    try {
      
      //field.geometry = flattenPolygonCoordinates(field.geometry.features[0].geometry.coordinates[0]);
      field.geometry = field.feature?.geometry || field.features[0].geometry; // TODO - stupid, I know. It should handle uploaded geometries, drawn geometries and marked geometries (retrieved parcel data)
      console.log("TO BE CREATED FIELD: ", field);
      const response = await axios_instance.post(`field`, field);
      console.log("FIELD CREATED RESPONSE: ", response.data);
      return {field: response.data.data.field, status: "success"};
    } catch (error) {
      console.error(error);
      return {field: null, status: "error", msg: error}
    }
  },
  uploadField: async (field) => {
    try {
      delete field.area;
      delete field.field_img;
      delete field.key;

      const response = await axios_instance.post(`field`, field);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  updateField: async (id, field) => {
    try {
      const response = await axios_instance.put(`/fields/${id}`, field);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  deleteField: async (id) => {
    try {
      const response = await axios_instance.delete(`/field/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
};

export default FieldsService;