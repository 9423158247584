import React, { useState, useEffect, useContext } from 'react';
import { G2, Pie} from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import SatelliteDataService from '../../../services/SatelliteData';
import { Layout, Space, Spin, Statistic } from 'antd';
import { GlobalContext } from '../../../App';
import { CommaFormatter } from '../../../utilities/format';
import { ArrowDownOutlined, ArrowUpOutlined, MinusOutlined } from '@ant-design/icons';

const {Content} = Layout;


const FieldHealthPieChart = (props) => {
  const { t, i18n } = useTranslation("translation");
  //const [field, setField] = useState({});
  const [chart_config, setChartConfig] = useState(null)
  const [index_zones_data, setIndexZonesData] = useState(null);
  const [prev_index_zones_data, setPrevIndexZonesData] = useState(null); // index zones data for a previous date
  const [imagery_dates, setImageryDates] = useState([]); // satellite imagery dates for the selected field

  const [loading, setLoading] = useState(false);

  const G = G2.getEngine('canvas');

    //Contexts
    const {
      current_view
    } = useContext(GlobalContext);

  const fetchImageryDates = () => {
    SatelliteDataService.getSentinelDates(props.field.id).then((dates) => {
      setImageryDates(dates)
      // const idx = dates.indexOf(props.current_date)
      // const prev_date = dates[idx-1 > 0 ? idx - 1 : 0 ]
      // fetchIndexZonesData(prev_date);
    });
  }
  
  const fetchIndexZonesData = (date) => {
    setLoading(true)
    SatelliteDataService.getIndexZonesForField(props.field.id, date || props.current_date, props.index, (_index_zones_data) => {
      if(!date)
        setIndexZonesData(_index_zones_data)
      // else 
      //   setPrevIndexZonesData(_index_zones_data)

      setLoading(false);
    })
  }

  useEffect(()=>{
    if(props.current_date) {
      fetchIndexZonesData();
      fetchImageryDates();
    }
  }, [props.current_date])

  useEffect(() => {
    let config = {
      //type: ["Field Info"].includes(current_view?.viewName) ? "spider" : "spider", // TODO - FIX THISS!!
      appendPadding: 10,
      data: [],
      angleField: 'pixel_count',
      colorField: 'name',
      color: ["#f44336", "#ff9800", "#ffc107",  "#00C853", "#4CAF50"],
      radius:["Field Info"].includes(current_view?.viewName) ? 1 : 0.80,
      // legend: {
      //   position: 'right',
      //   color: {
      //     title: true,
      //     position: 'right',
      //     rowPadding: 5,
      //   },
        
      // },
      legend: false,
      
      label: {
        // text: 'value',
        // style: {
        //   fontWeight: 'bold',
        // },
      },
      interactions: [
        {
          type: 'element-selected',
        },
        {
          type: 'element-active',
        },
      ],
    };
  
    if(index_zones_data) {
      let sum_pixels = 0
      index_zones_data.forEach((zone) => { sum_pixels += zone.pixel_count })

      config.tooltip = {
        formatter: (zone, idx) => {
          // const prev_date_zone = prev_index_zones_data.filter(dt => dt.name === zone.name)[0] // same zone from previous date
          // const difference = ((zone.pixel_count - prev_date_zone.pixel_count) / prev_date_zone.pixel_count) * 100; 
          // const arrow_difference = (difference === 0 && '-') || difference > 0 ? "up" : <ArrowDownOutlined />
          return {
            name: zone.name,
            value: `${(zone.pixel_count / sum_pixels * 100).toPrecision(2)}%`
          }
       } 
     }

    //  config.legend.formatter = (zone, idx) => {
    //     return {
    //       name: zone.name,
    //       value: `${(zone.pixel_count / sum_pixels * 100).toPrecision(2)}%`
    //     }
    //   }

      config.data = index_zones_data.filter(item => item.pixel_count > 0);

      config.label.formatter = (dt, mappingData) => {
        // const group = new G.Group({});
        // group.addShape({
        //   type: 'circle',
        //   attrs: {
        //     x: 0,
        //     y: 0,
        //     width: 40,
        //     height: 50,
        //     r: 5,
        //     fill: mappingData.color,
        //   },
        // });
        // group.addShape({
        //   type: 'text',
        //   attrs: {
        //     x: 10,
        //     y: 8,
        //     text: `${dt.name}`,
        //     fill: mappingData.color,
        //   },
        // });
        // group.addShape({
        //   type: 'text',
        //   attrs: {
        //     x: 0,
        //     y: 25,
        //     // Want to keep this icon here -> 个 :)
        //     text: `${(dt.pixel_count / sum_pixels * 100).toPrecision(3)} % `,
        //     fill: 'rgba(0, 0, 0, 0.65)',
        //     fontWeight: 700,
        //   },
        // });
        // return group;
      };
      setChartConfig({...config});
    }

    

  },[G.Group, current_view, index_zones_data])


  //Get previous imagery's zone data and calculate differences
  useEffect(() => {

  }, [])


  return (
    <>  
      {loading && <Spin />}
      {
        !loading && chart_config && 
        <>
       
        <Space direction='vertical' style={{justifyContent: "space-evenly", alignItems: "center", width: 500}}> 
        <Pie 
            style={{textAlign: "center", alignItems: "center"}} height={300} width={400}
            {...chart_config} 
          />
          {/* <Space direction='horizontal' style={{justifyContent: "center", textAlign: "left"}}>
            <Statistic title={t('components.vegetation')} value={75} suffix="%" formatter={CommaFormatter} />
            <Statistic title={t('components.moisture')} value={75} suffix="%" formatter={CommaFormatter} />
          </Space> */}
        </Space>
        </>
      }
    </>
  )
};

export default FieldHealthPieChart;
