// Modules
import React from 'react'

// Component: Icon > Chevron (Shape)
const Chevron = props => (
  <svg className="chevron icon"
    viewBox="0 0 19 12">
    <path d="M0.726796015,4.24264069 C-0.242265338,3.27207794 -0.242265338,1.69848481 0.726796015,0.727922061 C1.69585737,-0.242640687 3.26701626,-0.242640687 4.23607761,0.727922061 L9.5,6 L14.7639224,0.727922061 C15.7329837,-0.242640687 17.3041426,-0.242640687 18.273204,0.727922061 C19.2422653,1.69848481 19.2422653,3.27207794 18.273204,4.24264069 L11.2546408,11.2720779 C10.2855794,12.2426407 8.71442056,12.2426407 7.7453592,11.2720779 L0.726796015,4.24264069 Z" />
  </svg>
)

// Export
export default Chevron
