import React, { Suspense, useEffect, useState, createContext, useContext, useRef, useMemo } from 'react';
import { useLeafletContext } from '@react-leaflet/core'
import * as L from 'leaflet';
import {
  MapContainer,
  TileLayer,  
  LayersControl,
  useMapEvents,
  FeatureGroup,
  WMSTileLayer,
  useMap,
} from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import { createBrowserHistory } from "history";

import { Layout } from 'antd';

// Import Global Context 
import {GlobalContext} from "../../App";

//Services
import GeoService from "../../services/GeoService";

// Map Layers
import { MapLayers } from "../map-overlays/MapOverlays"
import { centroid, polygon } from '@turf/turf';
import AuthContext from '../../contexts/AuthContext';
import { BaseViewContext } from '../../views/Base';
import { useLocation, useNavigate, useParams } from 'react-router';
import WFS from '../OGR/WFS';
import { convertBoundingBoxToPolygon } from '../../utilities/polygon_util';

const center = [37.1390745446244, 38.94586676726791]

const CCMapContext = createContext("");


function MapSubscriber(props){
  
  const navigate = useNavigate();
  const location = useLocation();
  const [isFlyTo, setIsFlyTo] = useState(false);

  const _map = useMap()
  //_map.flyTo([ 38.64261790634527,34.89296635696362], 7, {duration: "1"});
  _map.flyTo([ 39.232253141714914, 34.50243930975155], 7, {duration: "1"});

  useMapEvents({
    click: () => {
      //map.locate()
    },
    locationfound: (e) => {
      props.map.current.flyTo(e.latlng, props.map.getZoom())
    },

    moveend: () => {
      if (!isFlyTo) {
        // your code here
        // Update URL with long lat and zoom
        const newCenter = props.map.current.getCenter();
        const newZoom = props.map.current.getZoom();
        const new_history_state = {title: `Lat ${newCenter.lat} Lng ${newCenter.lng}`, url: `${location.pathname}?lat=${newCenter.lat}&lng=${newCenter.lng}&zoom=${newZoom}`}
        //FIXME - This triggers whole HomeView all over again
        // add console.logs to HomeView and BaseView and see it in the console.
        window.history.replaceState(new_history_state, new_history_state.title, new_history_state.url);
        //navigate(new_history_state.url, {replace:false})
      } else {
        setIsFlyTo(false);
      } 
    },
    fly: () => {
      setIsFlyTo(true);
    },
    flyend: () => {
      setIsFlyTo(true);
      // your code here
    }
  })

  return (<></>)
}


const CCMap = (props) => {
  const map_ref = useRef(null);
  const index_overlay_ref = useRef(null); // holds ref to the tilelayer when a vegetation index is loaded
  const [drawUIGeoJSON, setDrawUIGeoJSON] = useState(null);
  const [segmentation_id, setSegmentationID] = useState(null); // state to keep segmentation id from SAM API response
  const [segmented_mask, setSegmentedMask] = useState(null); // state to keep segmented mask from SAM API response
  // if segmentation progress is not 100, then show loading, see getSegmentation method
  const [is_segmentation_loading, setIsSegmentationLoading] = useState(false);
  const [map_center, setMapCenter] = useState({lat: 0, lng: 0, zoom: 2}); // map center at opening
  const [selected_feature, setSelectedFeature] = useState(null);

  const [crop, setCrop] = useState(null);


  useEffect(() => {
    if(props.crop) {
      console.log("CROP: ", props.crop)
      setCrop(props.crop)
    }
  }, [props.crop])



  // Main map state
  const [map, setMap] = useState(null);


  // Get current_view, selected_map_layer and query_params from Global Context
  // current_view is to be updated to Home
  // selected_map_layer Will be manipulated by MapOverlays Component
  // "../components/map-overlays/MapOverlays"
  // query_params is accessible by every component
  const { 
    selected_map_layer, 
    setSelectedMapLayer,
    selected_index_overlay,
  } = useContext(GlobalContext);

  const {current_view} = useContext(BaseViewContext);

  /* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
  // Window resize hack
  // This is necessary for leaflet to load the map in full. 
  // Don't touch it, play or otherwise fuck around with it.
  const handleResize = () => {
    if(map_ref)
      map_ref.current.invalidateSize();
    else
      // not a map_ref
      console.log("Not a map_ref")
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () =>  window.removeEventListener("resize", handleResize);
  },[])
  useEffect(() => {
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 100);
  }, [map_ref])
  /* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

  

  useEffect(() => {
    if(map_ref.current) { 
      // const polygonFeature = convertBoundingBoxToPolygon(user.organization.extent);
      // const center = centroid(polygonFeature).geometry.coordinates;
      // const lat = center[0];
      // const lng = center[1];
      map_ref.current.flyTo([ 38.64261790634527,34.89296635696362], 7, {duration: "1"});
      //map_ref.current?.fitBounds(user.organization.extent);
    }
  }, [map_ref])

  const crop_style_map = {
    barley: "verim_arpa",
    sunflower: "verim_aycicegi",
    wheat: "verim_bugday",
    canola: "verim_kanola",
    corn: "verim_misir",
    cotton: "verim_pamuk",
    sugarbeet: "verim_sekerpancari",
    silagecorn: "verim_silajmisir",
    peanut: "verim_yerfistigi",
    oat: "verim_yulaf"
  }

  useEffect(()=> {
    setSelectedMapLayer(MapLayers[3]);
  }, [setSelectedMapLayer])

  /* Map Container Memo */
  // is used to have more control over user defined assets
  const MapContainerMemo = useMemo(
    () => (
      <MapContainer
        whenReady={(map)=> setMap(map)}
        ref={map_ref}
        doubleClickZoom={false}
        center={[map_center.lat, map_center.lng]}
        zoom={map_center.zoom} 
        className="map-container"
        zoomControl={false}
        maxZoom={16}
      >
        <MapSubscriber map={map_ref} setMapCenter={setMapCenter} current_view={current_view}/>
        
        <LayersControl position="topright">
          {
            selected_index_overlay &&
              <TileLayer
                zIndex={1010}
                ref={index_overlay_ref}
                attribution='&amp;copy'
                url="https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
              />
          }

          { /* Basemaps */ }
          {
            selected_map_layer && MapLayers.map((layer) => (
              <LayersControl.Overlay
                key={layer.name}
                name={layer.name}
                checked={selected_map_layer.name === layer.name}
              >
                {layer.layer}
              </LayersControl.Overlay>
          ))}

          { !crop &&
            <WMSTileLayer
              maxZoom={12}
              layers={"Agcurate:2021_classification"}
              url={`https://geoserver.agcurate.com/geoserver/gwc/service/wms?SERVICE=WMS`}
              zIndex={1100}
              format='image/png'
              transparent={true}
            />
          }

          {
            crop && 
            <WMSTileLayer
              key={crop}
              maxZoom={12}
              layers={"Fieldops:Verim"}
              styles={`${crop_style_map[crop]}`}
              url={`https://geoserver.agrocasco.com/geoserver/Fieldops/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&`}
              zIndex={1100}
              format='image/png'
              transparent={true}
            />
          }

          {
            <WFS map_ref={map_ref} cc={true} minZoom={13} />
          }
        </LayersControl>
      </MapContainer>
    ),[map_center.lat, map_center.lng, map_center.zoom, current_view, selected_index_overlay, selected_map_layer, crop, crop_style_map]
  )

  return (
    <CCMapContext.Provider value={{map, setMap, map_center, setMapCenter}}>
      { /* MAP Content */ }
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          {//map ? <DisplayPosition map={map} /> : null
          }
          {(crop || !crop) &&
            MapContainerMemo
          }
        </Suspense>
        {
        //<Footer style={footerStyle}>Footer</Footer>
        }
      </Layout>
    </CCMapContext.Provider>
  );
};

export default CCMap;