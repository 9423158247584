import { Outlet, Navigate, useOutlet, useNavigate, Route } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';

export const ProtectedRoute = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  const [ proceed, setProceed ] = useState("");
  const [ goToLogin, setGoToLogin ] = useState(false);

  const navigate = useNavigate();

  const setTokenAndProceed = (tk) => {
    localStorage.setItem("token", tk); //set token for axios_instance to use in interceptor
    setProceed(true); // Go on little sunshine
  }

  useEffect(() => {
    if(initialized) {
      const isAuthenticated = !!keycloak.idToken && !!keycloak.token;
      if ( !isAuthenticated ) {
        navigate("/login")
      } else {
        setTokenAndProceed(keycloak.token)  
      }
    }
  }, [initialized, keycloak])

  return  proceed && children 
};