import axios_instance from '../utilities/axios_instance';
import moment from 'moment';

const API_KEY = '94976952467899872b18ff33d2d1c941';


const SatelliteDataService = {

  getIndexTileURLWithDates: async (id, index) => {
    //const current_date = moment().format("YYYY-mm-dd")
    const URL = `map/indexWithDates?type=${index}&id=${id}`;
    try {
      const response = await axios_instance.get(`${URL}`);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  },

  getIndexTileURL: async (id, date, index) => {
    //const current_date = moment().format("YYYY-mm-dd")
    console.log("INDEX: ", index, id, date)
    const URL = `map/index?type=${index}&date=${date}&id=${id}`;

    try {
      const response = await axios_instance.get(`${URL}`);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  },

  getSentinelDates: async (id) => {
    const current_date = moment().format("YYYY-MM-DD")
    //TODO - 2023-03-01 should be automatically adjusted to season
    const URL = `/data/sentinel/dates?id=${id}&date_range=2023-03-01,${current_date}`;
    
    try {
      const response = await axios_instance.get(`${URL}`);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  },

  getSentinel2TileURL: async (id, date) => {
    //const current_date = moment().format("YYYY-mm-dd")
    const URL = `map/sentinel?type=true_color&date=${date}&id=${id}`;

    try {
      const response = await axios_instance.get(`${URL}`);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  },

  getSentinel2TileURLWithDates: async (id) => {
    const current_date = moment().format("YYYY-MM-DD")
    const URL = `map/sentinelWithDates?type=true_color&date=${current_date}&id=${id}`;

    try {
      const response = await axios_instance.get(`${URL}`);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  },

  getIndexTimeseriesForField: async (id, index_type, start_date, end_date, _clb) => {
    const URL = `/data/index/timeseries?id=${id}&index_type=${index_type}&start_date=${start_date}&end_date=${end_date}`;
    try {
      console.log("URL: ", URL);
      const response = await axios_instance.get(URL);
      return _clb(response.data.data);
    } catch (error) {
      console.error(error);
    }
  },

  getIndexZonesForField: async (id, date, index, _clb) => {
    const URL = `/data/index/zones?id=${id}&date=${date}&index=${index}`;
    try {
      console.log("INDEX ZONES URL: ", URL);
      const response = await axios_instance.get(URL);
      return _clb(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }
};

export default SatelliteDataService;