import { ArrowLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Input, Layout, Modal, Spin, Typography, message } from "antd";
import { Header } from "antd/es/layout/layout";
import { createContext, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import RightSidebar from "../components/sidebars/right-sidebar/RightSidebar";
import LeftSidebar from "../components/sidebars/left-sidebar/LeftSidebar";
import LeftMenu from "../components/sidebars/LeftMenu";
import UserService from "../services/User";
import FieldsService from "../services/Fields";
import { useTranslation } from "react-i18next";
import OrganizationsService from "../services/Organization";
import moment from "moment";
import 'moment/locale/tr'
import 'moment/locale/nl' 
import Intercom from '@intercom/messenger-js-sdk';

//Base View Context
export const BaseViewContext = createContext({});

const {TextArea} = Input;

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

const BaseView = () => {
  const { t, i18n } = useTranslation("translation");
  const [create_organization_modal_visible, setCreateOrganizationModalVisible] = useState(false);
  const [org_name, setOrgName] = useState("");
  const [org_description, setOrgDescription] = useState("");
  const [view_loading, setViewLoading] = useState(false);
  const [user, setUser] = useState({}); // to be used across the app
  const navigate = useNavigate();

  const [fields_data, setFieldsData] = useState(null);

  const id = useParams();

  const base_view_ref = useRef();

  const [current_view, setCurrentView] = useState({
    viewName: "", 
    viewTitle: "", // to be shown in the header
    viewTitleSecondary: "",
    basePath: "/",
    breadcrumbs: [],
    goBack: {}
  });

  const handleGoBack = () => {
    // Handle back button click here
    navigate(current_view.goBack.url);
  };

  const createOrganization = () => {
    OrganizationsService.createOrganization(org_name, org_description, (org, err) => {
      if (org) {
        setCreateOrganizationModalVisible(false);
        setOrgDescription("");
        setOrgName("");
        navigate("/home/")
        return
      }

      if (err) {
        message.error(`${t('general.error')} - ${t('general.organization_not_created')}`)
        console.error("BASE: ", err);
        return 
      }
    })
  };

  const fetchUser = async () => {
    UserService.getMe(_user => {
      if(!_user.organization) { // organization is not yet set for the user 
        setViewLoading(false);
        setCreateOrganizationModalVisible(true);
      } else {
        console.log("USER: " , _user);
        setUser(_user);
        i18n.changeLanguage(_user.organization.language);
        setViewLoading(false);
      }
    });
  }

  useEffect(()=>{
    setViewLoading(true);
    fetchUser();
  }, [])

  useEffect(() => {
    // Set moment locale to match i18n language at app startup and on changes
    const updateMomentLocale = (language) => {
      moment.locale(language);
      console.log("Moment language set to:", moment.locale());
    };

    // Update locale initially
    updateMomentLocale(i18n.language);

    // Listen for language changes in i18n and update Moment locale accordingly
    i18n.on("languageChanged", updateMomentLocale);

    // Cleanup the event listener on unmount
    return () => {
      i18n.off("languageChanged", updateMomentLocale);
    };
  }, [i18n]);

  return (
      <BaseViewContext.Provider 
          value={{ 
            current_view,
            setCurrentView,
            view_loading,
            setViewLoading,
            base_view_ref,
            user
          }}>
        <Layout>
          {
            view_loading &&
              <Layout className='view-loading-overlay'>
                <div className='view-loading-overlay-content'>
                  <Spin />
                </div>
              </Layout>
          }

          { /* Primary Left Menu  */ }
          { //["Weather", "Crop Growth", "Team", "Home", "Field Info", "Team Member", "Fields Control Provisions", "Farmers", "Crop Classification"].includes(current_view.viewName) &&  
            <LeftMenu />
          }
          <Layout>
            { /* Left Sidebar  */ }
            { //["Weather", "Crop Growth", "Team", "Home", "Field Info", "Team Member", "Fields Control Provisions", "Farmers", "Crop Classification"].includes(current_view?.viewName) &&
              <LeftSidebar />
            }
            <Layout className="page">
              {
                //["Weather", "Crop Growth", "Team", "Field Info", "Team Member", "Fields Control Provisions", "Farmers", "Crop Classification"].includes(current_view?.viewName) && 
                !["Home"].includes(current_view?.viewName) && 
                  <Header
                    style={{
                      backgroundColor: "#f7f7f8",
                      textAlign: "center",
                      position: "relative",
                      right: 0,
                      top: 0,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      borderRadius: "0px 0px 3px 3px",
                      boxShadow: " 0px 0px 4px 0.3px rgba(0,0,0,.3)",
                      flexWrap: "nowrap"
                    }}
                  >
                    <Button style={{position: "absolute", left: 0}} type="" icon={<ArrowLeftOutlined />} onClick={handleGoBack}>
                      {current_view.goBack?.name}
                    </Button>
                    <Typography.Title level={3}>
                      {current_view.viewTitle}
                    </Typography.Title>
                  </Header>
              }
              <Layout 
                style={{overflowY: "scroll", backgroundColor: "#ffffff", color: "#000000"}}>
                  {//["Weather", "Crop Growth", "Team", "Field Info", "Team Member", "Fields Control Provisions", "Farmers"].includes(current_view?.viewName) && 
                    !["Home", "Crop Classification"].includes(current_view?.viewName) && 
                    <Breadcrumb
                      style={{padding: 15}}
                      items={
                        current_view?.breadcrumbs.map((bc) => {
                          return {title: (<a href="#" onClick={(event) => {navigate(`${bc.url}/`)}}>{bc.name}</a>)}
                        })
                      }
                    />
                  }
                  {
                    ["Home", "Auth", "Crop Classification"].includes(current_view?.viewName) ? // we don't want Home and Auth to have padding, check App.less for .view-layout
                      <Outlet />
                    :
                    <div className='view-layout' ref={base_view_ref}>
                      {
                        view_loading && 
                        <Layout className='view-loading-overlay'>
                          <div className='view-loading-overlay-content'>
                            <Spin />
                          </div>
                        </Layout>
                      }
                      <Outlet />
                    </div>
                  }
              </Layout>
            </Layout>
          </Layout>
          { /* Right Sidebar */ }
          { //["Weather", "Crop Growth", "Team", "Home", "Field Info", "Team Member", "Fields Control Provisions", "Farmers", "Crop Classification"].includes(current_view?.viewName) &&  
            <RightSidebar field_id={id.id}/>
          }
        </Layout>

        <Modal 
          title={t('general.create_organization')} 
          open={create_organization_modal_visible} 
          cancelButtonProps={{ style: { display: 'none' } }}
          okText={t('general.create')}
          onOk={createOrganization}
          zIndex={1005}
          maskClosable={false}
          closable={false}
        >
          <Input placeholder={t('general.name')} onChange={(e)=>setOrgName(e.target.value)}/>
          <br />
          <br />
          <TextArea rows={4} placeholder={t('general.description')} onChange={(e)=>setOrgDescription(e.target.value)} />
        </Modal>

        {
        
          Intercom({
            app_id: INTERCOM_APP_ID,
            user_id: user.id, 
            name: user.name,
            user_hash: user.intercomHMAC,
            email: user.email,
            //language_override:  user.organization?.language,
            //language_override: user.keycloak.organizations[user.organizationId].attributes.language[0],
            created_at: user.createdAt,
          })
        }
      </BaseViewContext.Provider> 
    
  )
}

export default BaseView;