// Modules
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

// Components
import Icon from '../icon/Icon'

// Style
import './Toggle.less'

// Component: Toggle
const Toggle = forwardRef((props, ref) => {
  // Render
  return (
    <div
      ref={ref}
      className="toggle"
      data-active={props.isActive || null}>
      <h1
        className="title text-single"
        data-size={props.size}
        data-text-alignment={props.textAlignment}
        data-prefix={props.prefix}
        data-prefix-position={props.prefixPosition}
        onClick={() => !props.isDummy && props.onToggle(!props.isActive)}>
        {props.children}
        {props.children && <Icon name="chevron" />}
      </h1>
    </div>
  )
})

// Properties
Toggle.displayName = 'Toggle'
Toggle.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  isDummy: PropTypes.bool,
  onToggle: PropTypes.func,
  prefix: PropTypes.string,
  prefixPosition: PropTypes.oneOf(['top', 'left']),
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),
  textAlignment: PropTypes.oneOf(['left', 'right'])
}

// Export
export default Toggle
