import { Cascader } from "antd";
import { useContext, useEffect, useState } from "react";
import RegionsService from "../../services/Regions";
import { centroid, polygon } from "@turf/turf";
import { GlobalContext } from "../../App";
import { BaseViewContext } from "../../views/Base";

const RegionsCascader = (props) => {
  const [regions, setRegions] = useState(null);

  const {
    setGlobalMapCenter,
    globalCCViewEnabled,
    setGlobalCCViewEnabled,
    globalSelectedRegion,
    setGlobalSelectedRegion
  } = useContext(GlobalContext);

  const {
    user
  } = useContext(BaseViewContext)

  useEffect(()=>{
    const fetchParentRegions = async () => {
      const _regions = await RegionsService.getParentRegions();
      const aze_tur_nld_rou = _regions.filter(r => r.value === 'tur' || r.value === "aze" || r.value === "rou" || r.value === "nld" || r.value === 'svn' || r.value === 'svk' || r.value === 'pol' || r.value === 'cze')
      
      setRegions(aze_tur_nld_rou);
    }

    if(!!user.organization) { // if everything is set for the user to function
      fetchParentRegions();
    }
  }, [user])


  //FIXME - this was an attempt to populate children for the defaultValue array of previously selected regions
  useEffect(()=>{
    if(props.defaultValue) {
      const populateCascader = async () => {

        const parent_region = props.defaultValue[0];
        const parent_region_object = regions.filter((r)=>{
          return r.value === parent_region;
        })

        await loadData(parent_region_object)

        for(var i = 0; i< regions.slice(0,1).length; i++) {
          var df = regions[i];
          // eslint-disable-next-line no-loop-func
          const region_object = regions.filter((r)=>{
            return r.value === df.value;
          })
          await loadData(region_object)
        }
      }

      populateCascader();
    }
  }, [props.defaultValue, regions])

  const onChange = (value, selected_options) => {
    if (!value) {
      return
    }
    const _sel_leaf_opt = selected_options.filter((option)=> {
      return option.isLeaf
    })

    if(_sel_leaf_opt.length !== 0 ) {
      var _centroid = centroid(_sel_leaf_opt[0].geometry);
      // get coordinates of the centroid
      var center = _centroid.geometry.coordinates;
      const lat = center[1];
      const lng = center[0];
      setGlobalMapCenter({lat: lat, lng: lng, zoom: 13  })
      // console.log("Selected Region222: ", _sel_leaf_opt[0])
      // setGlobalSelectedRegion(_sel_leaf_opt[0]);
    }
  };

  const fetchSubRegions = async (parent_id) => {
    const _subregions = await RegionsService.getSubRegions(parent_id);
    return _subregions;
  }

  const loadData = async (selectedOptions) => {
    const target_option = selectedOptions[selectedOptions.length - 1];
    console.log("TARGET OPTION: ", target_option)
    const _subregions = await fetchSubRegions(target_option.value);

    target_option.children = _subregions.subregions;
    if(_subregions.subregions.length === 0) {
      var _centroid = centroid(_subregions.geometry);
      // get coordinates of the centroid
      var center = _centroid.geometry.coordinates;
      const lat = center[1];
      const lng = center[0];
      setGlobalMapCenter({lat: lat, lng: lng, zoom: 11})
      // console.log("Selected Region: ", _subregions)
      // setGlobalSelectedRegion(_subregions);
 
      target_option.isLeaf = true; // if has no children, make it a leaf
      target_option.geometry = _subregions.geometry
    }
    setRegions([...regions]);
  };

  const filter = (inputValue, path) => {
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }


  return (
    <>
      <Cascader
        key="zone-selector"
        bordered={props.bordered}
        options={regions}
        //fieldNames={{label: "name", value: "region_key", isLeaf: "is_leaf", key: "key"}}
        onChange={onChange}
        loadData={loadData}
        value={props.defaultValue?.map((pl)=>pl.value)}
        changeOnSelect
        // showSearch={{
        //   filter,
        // }} // !!!! this doesn't work with loadData, check ant-design documentation: https://ant.design/components/cascader#api
        // onSearch={(value) => console.log(value)} 
        placeholder={"Select Region"}
        style={{marginTop: 0}}
      />
    </>
  )
}


export default RegionsCascader;