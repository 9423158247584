import React, { useEffect, useContext, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import moment from 'moment/min/moment-with-locales'
import { 
  List, 
  Space, 
  Avatar, 
  Layout, 
  Input, 
  Button,
  Divider, 
  Typography,
  Spin,
  Modal,
  message,
 } from 'antd';
import {
  EditOutlined,
  UserOutlined
} from "@ant-design/icons";

import { GlobalContext } from "../../App";

// Services
import FieldsService from "../../services/Fields";
import { Header } from "antd/es/layout/layout";
import GeoService from "../../services/GeoService";

// Utilities
import UserService from "../../services/User";
import { useTranslation } from "react-i18next";
import { set } from "react-ga";

const { Search } = Input;

function TeamList(props) {
  const { t, i18n } = useTranslation("translation");
  // Router
  const { id } = useParams();
  const [invite_new_user_modal, setInviteNewUserModal] = useState(false);
  const [users, setUsers] = useState(null);
  const [filtered_data, setFilteredData] = useState(users);

  const [invite_user_info, setInviteUserInfo] = useState({email: ""});
  const [loading, setLoading] = useState(false);

  const [view_loading, setViewLoading] = useState(true);

  useEffect(()=>{
    moment.locale(i18n.language)
  }, [i18n.language])

  // View Contexts
  const {
    current_view, 
    setCurrentView,
    setSelectedUserAsset, 
  } = useContext(GlobalContext);

  const afterModalClose = () => {
    setInviteUserInfo({email: ""});
    setLoading(false);
    fetchUsers();
  }

  const handleOk = () => {
    setLoading(true);
    //createFarmer(farmer_name, farmer_street, farmer_province, farmer_city, farmer_national_id, farmer_country, farmer_postal_code);
    inviteUser(invite_user_info);
  };

  const inviteUser = (info) =>{
    UserService.inviteUser(info, (data, err) => {
      if(data){
        setInviteUserInfo({email: ""}); 
        setInviteNewUserModal(false);
        setLoading(false);
        return
      }

      setLoading(false);
      message.error(`${t('general.error')} ${err}`)

    });
  }

  const handleCancel = () => {
    setInviteUserInfo({email: ""});
    setLoading(false);
    setInviteNewUserModal(false);
  };

  const fetchUsers = async () => {
    setViewLoading(true);
    const _users = await UserService.getUsers();

    setUsers(_users);
    setFilteredData(_users);
    setViewLoading(false);
  }

  useEffect(() => {
    fetchUsers();
  }, [])
  
  const handleClick = (item) => {
    if (setCurrentView) {
      setCurrentView(prev => ({
        ...prev,
        viewTitle:  current_view.viewName,
        viewTitleSecondary: "Location: " + item.id
      }))
    } else {}

    if (setSelectedUserAsset){
      //TODO - should clicking on a field do this or just open FieldNDVI UI?
      //setSelectedUserAsset(item);
    }

    //window.open("http://localhost:3000/#/ndvi/fields/" + item.id, "_self");
  };

  const [user_item_onclick_path, setUserItemOnClickPath] = useState("/");
  useEffect(() => {
    
    if(current_view){
        setUserItemOnClickPath(current_view.basePath + "/user");
    }
  }, [current_view]);

  const onSearch = (e) => {
    if (!e) return;
    const value =  e.target.value
    const filtered = users.filter((item) =>
      item.name?.toLowerCase().includes(value.toLowerCase()) || item.email?.toLowerCase().includes(value.toLowerCase()) || item.phone?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };  

  // Handlers
  const renderUserItem = (item, index) => {
    
    // FIXME - Render the selected field differently 
    if(item.id === id) {

    }

    return (
      <List.Item >
        <List.Item.Meta
          //avatar={<EnvironmentOutlined style={{color: "#282c34", fontSize: 20}}/>}
          avatar={
            <Avatar size={"large"} icon={<UserOutlined />} />
          }
          style={{textAlign: "left"}}
          title={
            <NavLink to={`team/member/${item.id}`} onClick={() => handleClick(item)}>
              <Space size="large" style={{ width: '100%', justifyContent: "space-between"}}>
                <Typography.Text style={{fontSize: 16}}> 
                  {`${item.name} ${item.invited ? t('general.invited') : ""}`}
                </Typography.Text>
                <Typography.Text italic disabled style={{fontSize: 10}}>
                  {moment(item.createdAt).fromNow()}
                </Typography.Text>
              </Space> 
            </NavLink>
          }
          description={
            <Space size="small" direction="vertical">
              <Typography.Text italic >
                {item.email} | {item.phone}
              </Typography.Text>
            </Space>
          }
        />
      </List.Item>
    ) 
  }

  return (
      <>
      {
          view_loading && 
          <Layout className='view-loading-overlay fit-to-space'>
            <div className='view-loading-overlay-content'>
              <Spin />
            </div>
          </Layout>
        }
        <Layout style={{backgroundColor: "#f5f5f5", color: "#282c34", position: 'sticky', top: "0", width:"100%",zIndex: 1005, padding: 5}}>
          <Divider orientation="left" style={{color: "#282c34"}}>
            <Typography.Title level={5}>
              {t('components.team')}
            </Typography.Title>
          </Divider>
          <Space direction="horizontal" style={{textAlign: "center",  padding: 5, justifyContent: "space-between"}}>
            <Search placeholder={t('general.searchtext')} allowClear onChange={onSearch} style={{ width: "100%" }} />
              <Button  onClick={() => setInviteNewUserModal(true)}>
                <Space>
                  <EditOutlined />
                  {t('general.new')}
                </Space>
              </Button>
          </Space>
        </Layout>
        <Layout style={{padding: 10}}>
        {
          (users &&  // Show skeleton until fields are loaded from api
          <List
            style={{textAlign: "left"}}
            itemLayout="vertical"
            responsive="true"
            size="small"
            pagination={{
              pageSize: 6,
              size: "small",
              position: "bottom",
              align: "center",
              color: "#282c34",          
            }}
            dataSource={filtered_data}
            renderItem={renderUserItem}
          />)
        }
        </Layout>

        {/** Invite new user */}
        <Modal
          title={t('general.invite_new_user')}
          centered
          open={invite_new_user_modal}
          onOk={() => setInviteNewUserModal(false)}
          onCancel={() => setInviteNewUserModal(false)}
          afterClose={afterModalClose}
          footer={[
            <Button key="back" onClick={handleCancel}>
            {t('general.cancel')}
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            {t('general.invite')}
          </Button>
          ]}
        >
          <Space direction="vertical" style={{width: "100%"}}>
            <Input size="large" placeholder={t('general.email')} value={invite_user_info.email} prefix={<UserOutlined />} onChange={(e) => setInviteUserInfo({ email: e.target.value})}/>
          </Space>
        </Modal>  
      </>
  );
}

export default TeamList;