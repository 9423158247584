import axios from 'axios';
import axios_instance from '../utilities/axios_instance';

const API_KEY = '94976952467899872b18ff33d2d1c941';
//const API = "http://localhost:3001/";
//const API = "https://agrocasco-backend-okwhxq4xvq-ew.a.run.app/"
const API = process.env.REACT_APP_API_URL;


const FarmerService = {
  
  getFarmers: async (search_criteria, clb) => { 

    try {
      const {season, search_query, page} = search_criteria
      let query = "";
      query += search_query ? `search_query=${search_query}&` : "";
      query += season ? `seasonId=${season?.id}&` : "";
      query += page ? `page=${page}` : "";
      
      console.log("QUERY: ", query);
      let response = await axios_instance.get(`farmer?${query}`);
    
      const {rows, count} = response.data.data.farmers;
      clb({data: rows.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      }), count: count});
    } catch (error) {
      console.error(error);
    }

  },

  createFarmer: async (_farmer_name, _farmer_street, _farmer_province, _farmer_city, _farmer_national_id, _farmer_country,_farmer_postal_code) => {
    try {
      const URL = API + "farmer";
      const body = {
        name:_farmer_name, 
        street: _farmer_street, 
        province: _farmer_province, 
        city:_farmer_city, 
        national_id: _farmer_national_id, 
        country: _farmer_country, 
        postal_code: _farmer_postal_code
      }
      const response = await axios_instance.post(`${URL}`, body);
      return response.data.data.farmers;
    } catch (error) {
      console.error(error);
    }
  },

  assignFields: async (farmer_id, fields_arr) => {
    try {
      const URL = `${API}farmer/${farmer_id}/addField`;
      const response = await axios_instance.post(`${URL}`, {added_fields: fields_arr});
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  getFarmer: async(farmer_id) => {
    try {
      const URL = `${API}farmer/${farmer_id}`;
      const response = await axios_instance.get(`${URL}`);
      return response.data.data.farmer;
    } catch (error) {
      console.error(error);
    }
  },


  deleteFarmer: async (farmer_id) => {
    try {
      const URL = API + "farmer";
      const response = await axios_instance.delete(`${URL}/${farmer_id}`);

      return response.data.data.farmer;
    } catch (error) {
      console.error(error);
    }
  },

};

export default FarmerService;