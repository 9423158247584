import React, { useState, useEffect, createContext, useContext} from 'react';
import { Layout, Typography } from 'antd';

// Components
import MapSidezone from '../components/mapside-zone/MapsideZone';

// Import Global Context 
import AGCCMap from '../components/agcc-map/agcc-map';
import { BaseViewContext } from './Base';
import CCMap from '../components/cc-map/cc-map';
import MiniMap from '../components/mini-map/mini-map';
import { useParams } from 'react-router';

// View Context
export const CCViewContext = createContext("");

// Ant Design - Layout, Typography Components
const {Header} = Layout;
const { Title } = Typography;

const CropClassificationView = () => {

  // Get current_view, selected_map_layer and query_params from Global Context
  // current_view is to be updated to Home
  // selected_map_layer Will be manipulated by MapOverlays Component
  // "../components/map-overlays/MapOverlays"
  // query_params is accessible by every component
  const { 
    setCurrentView, 
  } = useContext(BaseViewContext);

  const [yield_crop, setYieldCrop] = useState(null);
  const { crop } = useParams();

  useEffect(() => {
    setYieldCrop(crop);
  }, [crop])

  // View Properties - same for all views
  const [cc_view_properties, setCCViewProperties] = useState({
    viewName: "Crop Classification",
    viewTitle: "Crop Map",
    viewTitleSecondary: "",
    basePath: "/cc",
    breadcrumbs: [{name: "Crop Map", url: "/cc"}],
    goBack: {name: "Home", url: "/home"}
  });

  useEffect(()=>{
    setCurrentView(cc_view_properties);
  }, [cc_view_properties, setCurrentView]);
    
  return (
    <CCViewContext.Provider value={{cc_view_properties, setCCViewProperties}}>
      <CCMap crop={yield_crop}/>        
      <MapSidezone showCropLegend={true} crop={yield_crop}/>
    </CCViewContext.Provider>
  )
}

export default CropClassificationView;