// Modules
import React from 'react'
import PropTypes from 'prop-types'

// Subcomponents
import Chevron from './shapes/Chevron'
import Exit from './shapes/Exit'
import Ellipsis from './shapes/Ellipsis'
import Clouds from './shapes/Clouds'

// Style
import './Icon.css'
import { Tooltip } from 'antd'

// Component: Icon
function Icon (props) {
  // Render
  switch (props.name) {
    case 'chevron':
      return <Chevron />
    case 'exit':
      return <Exit />
    case 'ellipsis':
      return <Ellipsis />
    case 'clouds':
      return <Tooltip title={props.title}> <Clouds /> </Tooltip>
    default:
      return null
  }
}

// Properties
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string
}

// Export
export default Icon
