/**
 * 
 * @param {} provisions
 * 
 * This function will generate an HTML DOM string
 * containing avatars of the users who wrote notes for smth, i.e crop growth graphs, field comparison graphs etc.
 * The function will get distinct users from the notes object and put their info into an array (distinct users),
 * which it'll later be used to create an HTML DOM string to be used as `html` in chart annotations
 */
export const GetAnnotations = (provisions) => {

      if(!provisions || provisions.length < 0) return null; // if no notes there to show
  
      var _annotations = {};
      provisions?.map((provision) => {
        console.log("PROVISION: ", provision);
        if (!_annotations[provision.date]) {
          _annotations[provision.date] = [];
        } 

        //annotation_dates[note.annotation_date].push(note.author);
        _annotations[provision.date].push(provision.user);
      })
  
      var annotations = [];
      for (let dt in _annotations) {
        var annot = _annotations[dt];
        console.log("ANNOT: ", annot);
        var html_string = ['<div class="ant-avatar-group css-dev-only-do-not-override-1vtf12y">'];
        var _limit = annot.length; //> 2 ? 2 : annot.length;
  
        for (var i=0; i < _limit; i++){
          if (annot[i].avatar) {
            html_string.push('<span class="ant-avatar ant-avatar-circle ant-avatar-sm ant-avatar-image css-dev-only-do-not-override-1vtf12y">');
                html_string.push('<img src="https://xsgames.co/randomusers/avatar.php?g=pixel&amp;key=2">');
            html_string.push('</span>');
          } else {
            html_string.push('<span class="ant-avatar ant-avatar-circle ant-avatar-sm css-dev-only-do-not-override-1vtf12y" style="border-radius: 50%; background-color: rgb(245, 106, 0);">');
                html_string.push('<span class="ant-avatar-string" style="transform: scale(1) translateX(-50%);">'+annot[i].name[0]+'</span>');
            html_string.push('</span>');
          }  
        }
  
        // if ( _limit > 2 ) {
        //   html_string.push('<span class="ant-avatar ant-avatar-circle ant-avatar-sm css-dev-only-do-not-override-1vtf12y" style="color: rgb(245, 106, 0); background-color: rgb(253, 227, 207);">');
        //       html_string.push(`<span class="ant-avatar-string" style="transform: scale(1) translateX(-50%);">+${annot.length - 2}</span>`);
        //   html_string.push('</span>')
        // }
  
        html_string.push('</div>')
  
        annotations.push({
            type: 'html',
            position: {date: dt, value: 0.95, category: "Provisions"},
            html: html_string.join(""),
        });
      }

      console.log("ANNOTATIONS: ", annotations);
  
      return annotations;
  }














//FIXME - CLEAN THIS UP
//TO ANNOTATE THE GRAPH WITH FIELD NOTES FROM NOTES API - MIGHT BE REDUNDANT
// export const GetAnnotations = (notes) => {

//     if(!notes || notes.length < 0) return null; // if no notes there to show

//     var _annotations = {};
//     notes.map((note) => {
//         if (!_annotations[note.annotationDate]) {
//             _annotations[note.annotationDate] = [];
//         } 

//         //annotation_dates[note.annotation_date].push(note.author);
//         _annotations[note.annotationDate].push(note.user);
//     })

//     var annotations = [];
//     for (let dt in _annotations) {
//       var annot = _annotations[dt];
//       var html_string = ['<div class="ant-avatar-group css-dev-only-do-not-override-1vtf12y">'];
//       var _limit = annot.length > 2 ? 2 : annot.length;

//       for (var i=0; i < _limit; i++){
//         if (annot[i].avatar) {
//           html_string.push('<span class="ant-avatar ant-avatar-circle ant-avatar-sm ant-avatar-image css-dev-only-do-not-override-1vtf12y">');
//               html_string.push('<img src="https://xsgames.co/randomusers/avatar.php?g=pixel&amp;key=2">');
//           html_string.push('</span>');
//         } else {
//           html_string.push('<span class="ant-avatar ant-avatar-circle ant-avatar-sm css-dev-only-do-not-override-1vtf12y" style="background-color: rgb(245, 106, 0);">');
//               html_string.push('<span class="ant-avatar-string" style="transform: scale(1) translateX(-50%);">'+annot[i].name[0]+'</span>');
//           html_string.push('</span>');
//         }  
//       }

//       if ( _limit > 2 ) {
//         html_string.push('<span class="ant-avatar ant-avatar-circle ant-avatar-sm css-dev-only-do-not-override-1vtf12y" style="color: rgb(245, 106, 0); background-color: rgb(253, 227, 207);">');
//             html_string.push(`<span class="ant-avatar-string" style="transform: scale(1) translateX(-50%);">+${annot.length - 2}</span>`);
//         html_string.push('</span>')
//       }

//       html_string.push('</div>')

//       annotations.push({
//           type: 'html',
//           position: {date: dt, value: 0.1},
//           html: html_string.join(""),
//       });
//     }

//     return annotations;
// }