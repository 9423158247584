import {  polygon } from '@turf/turf';

export const flattenPolygonCoordinates = (coordinates) => {
  const flattenedCoords = [];

  for (let i = 0; i < coordinates.length; i++) {
    const ring = coordinates[i];

    for (let j = 0; j < ring.length; j++) {
      const latlng = {
        lat: ring[j][1],
        lng: ring[j][0]
      };
      flattenedCoords.push(latlng);
    }
  }

  return flattenedCoords;
}



// Function to convert the bounding box string to a polygon feature
export const convertBoundingBoxToPolygon = (boundingBoxStr) => {

  const [minLong, minLat, maxLong, maxLat] = boundingBoxStr
    .replace("BOX(", "").replace(")", "")
    .split(/[ ,]+/)
    .map((coord) => parseFloat(coord));

  const bbox = [minLong, minLat, maxLong, maxLat];

  return {bbox: bbox, polygon: polygon([[
    [minLong, minLat],
    [maxLong, minLat],
    [maxLong, maxLat],
    [minLong, maxLat],
    [minLong, minLat] // Closing the polygon
  ]])}

};