import React, { Suspense, useEffect, useState, createContext, useContext, useRef, useMemo } from 'react';
import { useLeafletContext } from '@react-leaflet/core'
import * as L from 'leaflet';
import * as turf from '@turf/turf'

import {
  MapContainer,
  TileLayer,  
  LayersControl,
  useMapEvents,
  FeatureGroup,
  useMap,
  WMSTileLayer,
  GeoJSON,
} from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import { createBrowserHistory } from "history";

import { Layout, Spin } from 'antd';

// Import Global Context 
import {GlobalContext} from "../../App";

//Services
import GeoService from "../../services/GeoService";

// Map Layers
import { MapLayers } from "../map-overlays/MapOverlays"
import { centroid } from '@turf/turf';
import { BaseViewContext } from '../../views/Base';
import { useLocation, useNavigate, useParams } from 'react-router';
import { convertBoundingBoxToPolygon } from '../../utilities/polygon_util';
import { getGeoJSONBounds } from '../../utilities/format';

import OGRWFS from '../OGR/WFS';
import ParcelService from '../../services/ParcelService';

const center = [47.5621227, 1.0827152]

const AGCCMapContext = createContext("");

//FIXME - Repetition; check LeftSidebar.js, IndexTimeline.js, AddEditProvisions.js, agcc-map.js
const Loading = () => {
  return (
    <Layout className='view-loading-overlay'>
      <div className='view-loading-overlay-content'>
        <Spin />
      </div>
    </Layout>
  );
}

function MapSubscriber(props){
  
  const navigate = useNavigate();
  const location = useLocation();
  const [isFlyTo, setIsFlyTo] = useState(false);

  const {user} = useContext(BaseViewContext)
  const map = useMap();


  useEffect(()=>{
    if(user){
      if(user.organization?.extent) {
      const polygonFeature = convertBoundingBoxToPolygon(user.organization.extent);
      const center = centroid(polygonFeature.polygon).geometry.coordinates;
      const lat = center[1];
      const lng = center[0];
      map.flyTo([lat, lng], 8, {duration: "1"});
    } else {
      map.flyTo(center, 4, {duration: "1"});
    }
      //map.fitBounds(polygonFeature.polygon);
    }
  }, [map, user])



  useMapEvents({
    click: () => {
      //map.locate()
    },
    locationfound: (e) => {
      props.map.current.flyTo(e.latlng, props.map.getZoom())
    },
    moveend: () => {
      if (!isFlyTo) {
        // your code here
        // Update URL with long lat and zoom
        const newCenter = props.map.current.getCenter();
        const newZoom = props.map.current.getZoom();
        const new_history_state = {title: `Lat ${newCenter.lat} Lng ${newCenter.lng}`, url: `/app${location.pathname}?lat=${newCenter.lat}&lng=${newCenter.lng}&zoom=${newZoom}`}
        //FIXME - This triggers whole HomeView all over again
        // add console.logs to HomeView and BaseView and see it in the console.
        window.history.replaceState(new_history_state, new_history_state.title, new_history_state.url);
        //navigate(new_history_state.url, {replace:false})
      } else {
        setIsFlyTo(false);
      } 
    },
    fly: () => {
      setIsFlyTo(true);
    },
    flyend: () => {
      setIsFlyTo(true);
      // your code here
    }
  })

  return (<></>)
}

/* WFS Component */
/* This will get GeoJSON data from GeoServer based on the extent the user is viewing currently */
function WFS(props) {

  const {selected_user_asset, setSelectedUserAsset, setSelectedIndexOverlay, setSelectedMapLayer} = useContext(GlobalContext);
  const {setViewLoading} = useContext(BaseViewContext)
  const context = useLeafletContext()
  const wfs_ref = useRef()
  const [wfs_data, setWFSData] = useState(null);
  const [map_moved, setMapMoved] = useState(null);

  const location = useLocation();
  const {id} = useParams();

  const {user }= useContext(BaseViewContext);

  const focusOnField = (field_id) => {
    const bounds = getGeoJSONBounds(field_id);
    props.map_ref.current.fitBounds(bounds);
    // wfs_ref.current.eachLayer(function (layer) {  
    //   if(layer.feature.properties.id === field_id) {    
    //     const bounds = layer.getBounds();
        
    //     layer.setStyle({color: "red", fillColor: "gray"}) 
    //   }
    // });
  }

  useMapEvents({
    dragend: (event) => {
      setMapMoved(event); // this is a trick to ping the useEffect below to work again, to avoid repetition of the code
    },
  })

  useEffect(() => {
    const bounds = props.map_ref.current.getBounds();
    let ne = bounds.getNorthEast(); // Coords of the northeast corner
    let sw = bounds.getSouthWest(); // Coords of the southwest corner
    const extent = [sw.lng, sw.lat, ne.lng, ne.lat];
    
    const fetchWFS = async () => {
      setViewLoading(true);
      const data = await GeoService.getWFS(extent, props.layer_name, props.org_id)
      setWFSData(data)
      // const _fid = location.pathname.split("/home/fields/")[1]?.split("/")[0]

      // if(_fid) {//if it  is on /home/fields/:id route
      //   focusOnField(_fid);
      // }
      setViewLoading(false);
    }
    fetchWFS();
  }, [props.map_ref, map_moved, props.layer_name, props.org_id, setViewLoading])


  useEffect(() => {
    if(selected_user_asset) {
      focusOnField(selected_user_asset)
      setSelectedMapLayer(MapLayers[2])
    } 

    if(!selected_user_asset && props.map_ref?.current?.getZoom() > 15) {
      props.map_ref.current.zoomOut(2);
      setSelectedMapLayer(MapLayers[3])
    }
  }, [selected_user_asset])


  const onFeatureClick = (e) => {
    const feature = e.target;
    if (selected_user_asset?.id === feature.feature.properties.id) { //if it is the second click on the selected user asset
      //feature.resetStyle();
      feature.setStyle({
        color: "blue",
        fillColor: "green"
      })

      props.map_ref.current.zoomOut(2);
      setSelectedUserAsset(null);
      //setSelectedIndexOverlay(null);

    } else {
      //feature.resetStyle();
      
      feature.setStyle({
        color: "red",
        fillColor: "blue",
        stroke: "purple"
      })
      
      const bounds = feature.getBounds();
      props.map_ref.current.fitBounds(bounds);
      setSelectedUserAsset({...feature.feature.properties, geometry: feature.feature.geometry});
    
    }
  }

  useEffect(() => {
    // if(user.organization) { //TODO: INSPECT HERE FOR A WHILE
      wfs_ref.current = new L.geoJSON(wfs_data, {
        onEachFeature: (feature, layer) => {
          layer.on({
            click: onFeatureClick,
            //mouseover: layer.bindPopup(feature.properties.fid).openPopup()
          });
          layer.bindTooltip(feature.properties.name, {
            permanent: false,
            direction: 'center', // Direction can be 'center', 'top', 'bottom', 'left', or 'right'
            offset: [0, 0],
            className: 'feature-label'
          });
          //console.log("FEAUTER: ", feature);
        },
        style: (feature) => {
          console.log("FEATURE: ", feature)
          const _style = {
            color: feature.properties?.color || "#F44336",
            fillColor: feature.properties?.color || "#FFCDD2" ,
          }
          //feature.setStyle(_style)
          return _style;
        }
      })

      props.map_ref.current.addLayer(wfs_ref.current)

      return () => {
        props.map_ref.current?.removeLayer(wfs_ref.current)
      }
    // }
  }, [props.map_ref, selected_user_asset, setSelectedIndexOverlay, setSelectedUserAsset, wfs_data])

  return null
}

const AGCCMap = () => {
  const map_ref = useRef(null);
  const index_overlay_ref = useRef(null); // holds ref to the tilelayer when a vegetation index is loaded
  const [drawUIGeoJSON, setDrawUIGeoJSON] = useState(null);
  const [segmentation_id, setSegmentationID] = useState(null); // state to keep segmentation id from SAM API response
  const [segmented_mask, setSegmentedMask] = useState(null); // state to keep segmented mask from SAM API response
  // if segmentation progress is not 100, then show loading, see getSegmentation method
  const [is_segmentation_loading, setIsSegmentationLoading] = useState(false);
  const [map_center, setMapCenter] = useState({lat: 0, lng: 0, zoom: 2}); // map center at opening
  const [selected_feature, setSelectedFeature] = useState(null);
    // Main map state
    const [map, setMap] = useState(null);


  // Get current_view, selected_map_layer and query_params from Global Context
  // current_view is to be updated to Home
  // selected_map_layer Will be manipulated by MapOverlays Component
  // "../components/map-overlays/MapOverlays"
  // query_params is accessible by every component
  const { 
    selected_map_layer, 
    setSelectedMapLayer, 
    query_params, 
    selected_user_asset,
    setSelectedUserAsset,
    selected_index_overlay,
    globalFieldDrawUIEnabled,
    globalCCViewEnabled, 
    setGlobalCCViewEnabled,
    setDrawnField,
    global_map_center,
    setGlobalMapCenter,
    selected_index
  } = useContext(GlobalContext);

  const {current_view, user, view_loading, setViewLoading} = useContext(BaseViewContext);

  /* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
  // Window resize hack
  // This is necessary for leaflet to load the map in full. 
  // Don't touch it, play or otherwise fuck around with it.
  const handleResize = () => {
    if(map_ref)
      map_ref.current.invalidateSize();
    else
      // not a map_ref
      console.log("Not a map_ref")
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () =>  window.removeEventListener("resize", handleResize);
  },[])
  useEffect(() => {
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 100);
  }, [map_ref])
  /* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

  //const _map_center = {lat: 39.0905, lng: 34.8638, zoom: 6.5};
  
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      if(query_params) {
       /* const lat = query_params.get('lat') ? query_params.get('lat') : 39.0905
        const lng = query_params.get('lng') ? query_params.get('lng') : 34.8638
        const zoom = query_params.get('zoom') ? query_params.get('zoom') : 6.5

        // Fly To the queried location
        map_ref.current.flyTo({lat: lat, lng: lng, zoom: zoom})*/
        isFirstRender.current = false
        return;
      } else {
        // s
      }
    }
  }, [globalFieldDrawUIEnabled, map_ref, query_params])

  useEffect(() => {
    if (index_overlay_ref.current && selected_index_overlay) {
      //spinner_ref.current.removeLayer();
      index_overlay_ref.current.setUrl(selected_index_overlay.tile_url);
    }
  }, [selected_index_overlay, index_overlay_ref]);

  /* TODO Add spinner in the center of the feature when it is selected with an index */
  // useEffect(() => {
  //   let spinner_marker = "";
  //   if(selected_index && !selected_index_overlay) {
  //     // Get the center point of the feature's geometry
  //     const centroid = turf.centroid(turf.polygon(selected_user_asset.geometry?.coordinates || selected_user_asset.feature.geometry.coordinates)) 
  //     const center = {lat: centroid.geometry.coordinates[1], lng: centroid.geometry.coordinates[0]}

  //     // Create a custom icon using the PNG image
  //     const customIcon = L.divIcon({
  //       className: 'custom-icon',
  //       html: '<div class="ant-spin ant-spin-lg ant-spin-spinning css-dev-only-do-not-override-1rqnfsa" aria-live="polite" aria-busy="true"><span class="ant-spin-dot ant-spin-dot-spin"><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i><i class="ant-spin-dot-item"></i></span></div>',
  //       iconSize: [32, 32], // Adjust the size of the icon as needed
  //       iconAnchor: [16, 16] // Position the icon's anchor point at its center
  //     });

  //     // Create a marker with the custom icon at the center point
  //     spinner_marker = L.marker(center, { icon: customIcon }).addTo(map_ref.current);
  //   } else if (selected_index && selected_index_overlay) {
  //     map_ref.removeLayer(spinner_marker) // FIXME - this is the problem; how to remove a marker?
  //   }
  // }, [selected_index, selected_user_asset, selected_index_overlay]) 

  const featuregroup_ref = useRef(null);
  const editcontrol_ref = useRef(null);

  useEffect(() => {
    if (globalFieldDrawUIEnabled) { // if draw ui is enabled, then activate Google Satellite basemap
      setSelectedMapLayer(MapLayers[2]);
    } else {
      setSelectedMapLayer(MapLayers[3]);
    }

    if (featuregroup_ref.current?.getLayers().length === 0 && drawUIGeoJSON) {
      L.geoJSON(drawUIGeoJSON).eachLayer((layer) => {
        if (
          layer instanceof L.Polyline ||
          layer instanceof L.Polygon ||
          layer instanceof L.Marker
        ) {
          if (layer?.feature?.properties.radius && featuregroup_ref.current) {
            new L.Circle(layer.feature.geometry.coordinates.slice().reverse(), {
              radius: layer.feature?.properties.radius,
            }).addTo(featuregroup_ref.current);
          } else {
            featuregroup_ref.current?.addLayer(layer);
          }
        }
      });
    }
  }, [drawUIGeoJSON, globalFieldDrawUIEnabled, setSelectedMapLayer]);

  const segmentBBOX = async (geo) => {
    const _segmentation_id = await GeoService.segmentBBOX(geo);
    var Segmentations = JSON.parse(localStorage.getItem("Segmentations"));
    Segmentations.push({id: _segmentation_id, name: "Classification", geo: geo});
    localStorage.setItem("Segmentations", JSON.stringify(Segmentations));
    setSegmentationID(_segmentation_id);
  }

  useEffect(() => {
    if(selected_user_asset && globalFieldDrawUIEnabled){
      setDrawUIGeoJSON(selected_user_asset.feature.geometry); // FIXME - selected_user_asset is now a field object from db, not a feature
      setDrawnField(selected_user_asset.feature.geometry); // FIXME - selected_user_asset is now a field object from db, not a feature
    } else {
      setDrawUIGeoJSON(null);
      setDrawnField(null);
    }
  }, [globalFieldDrawUIEnabled, /*selected_user_asset*/, setDrawnField])

  
  // FIXME - This doesn't work at all. Should activate marker editcontrol on parcel selected on leftsidebar, and polygon editcontrol on draw on leftsidebar.
  // useEffect(() => {
  //   //map_ref.current?.flyTo([0, 0], 2, {duration: "1"});
  //   const drawControl = editcontrol_ref.current?.leafletElement;
  //   if (!drawControl) {
  //     console.log("sligkndgmf")
  //     return;
  //   }

  //   if(globalFieldDrawUIEnabled === "parcel") {
  //     console.log("BURADADADADADADDADADAD");
  //     // to start with marker mode active
  //     drawControl._toolbars.draw._modes.marker.handler.enable();
  //   } else if (globalFieldDrawUIEnabled === "draw") {
  //     // to start with polygon mode:
  //     drawControl?._toolbars?.draw?._modes?.polygon?.handler.enable();
  //   }
  // }, [globalFieldDrawUIEnabled, editcontrol_ref])

  useEffect(() => {
    map_ref.current?.flyTo([global_map_center.lat, global_map_center.lng], 10, {duration: "1"});
  }, [global_map_center])

  function handleChange(e) {
    console.log("e.layerTYPE ", e.layerType)
    const geo = featuregroup_ref.current?.toGeoJSON();
    console.log("GEO: ", geo);
    if(e.layerType === "rectangle") { //invoke SAM backend API
      // Send the rectangle (AoI) to the API
      segmentBBOX(geo);
    } else if (e.layerType === "marker") { // TODO invoke TKGM and other countries API to pull the parcel info including geometry
      console.log("sdlfn", e.layer.getLatLng());
      ParcelService.getParcelForPoint(e.layer.getLatLng(), (_parcel) => {
        setSelectedUserAsset({feature: _parcel});
        setDrawUIGeoJSON({feature: _parcel});
        setDrawnField({feature: _parcel});
      })
    } else {
      if (geo?.type === 'FeatureCollection') {
        setSelectedUserAsset({feature: {geometry: geo.features[0].geometry}, properties: {}});
        setDrawUIGeoJSON(geo);
        setDrawnField(geo);
      } 

      
    }
  }

  function handleDrawStart(e) {
    if(e.layer === "rectangle") {

    }
  }

  useEffect(() => {
    if(map_ref.current && user) { 

      if(user.organization.extent) {
        const polygonFeature = convertBoundingBoxToPolygon(user.organization.extent);
        const center = centroid(polygonFeature.polygon).geometry.coordinates;
        const lat = center[1];
        const lng = center[0];
        map_ref.current?.flyTo([lat, lng], 8, {duration: "1"});
      } else {
        map_ref.current?.flyTo(center, 4, {duration: "1"});
      }
      //map_ref.current?.fitBounds(user.organization.extent);
    }
  }, [map_ref, user])

  /* Map Container Memo */
  // is used to have more control over user defined assets
  const MapContainerMemo = useMemo(
    () => (
      <MapContainer
        whenReady={(map)=>setMap(map)}
        ref={map_ref}
        doubleClickZoom={false}
        center={[map_center.lat, map_center.lng]}
        zoom={map_center.zoom} 
        className="map-container"
        zoomControl={false}
        maxZoom={18}
      >
        <MapSubscriber map={map_ref} setMapCenter={setMapCenter} current_view={current_view}/>
        {
          globalFieldDrawUIEnabled 
            ?
            <FeatureGroup ref={featuregroup_ref} style={{zIndex: 5000}}>
              <EditControl
                ref={editcontrol_ref}
                position="topright"
                onEdited={handleChange}
                onCreated={handleChange}
                onDeleted={handleChange}
                onDrawStart={handleDrawStart}
                draw={{
                  // rectangle: { // TODO - remove if not needed; it was being used to get a rectangle AOI for SAM in Replicate.
                  //   repeatMode: false,
                  //   showRadius: true
                  // },
                  rectangle: false,
                  circle: false,
                  polyline: false,
                  polygon: true,
                  marker: {icon: new L.DivIcon({
                    iconSize: new L.Point(10, 10),
                    className: 'leaflet-div-icon leaflet-editing-icon my-own-class'
                })},
                  circlemarker: false,
                }}
              />
            </FeatureGroup>
            :
          ""
        }
          
        <LayersControl position="topright">
         
          
          {/* Basemaps */}
          {selected_map_layer && MapLayers.map((layer) => (
            <LayersControl.Overlay
              key={layer.name}
              name={layer.name}
              checked={selected_map_layer.name === layer.name}
            >
              {layer.layer}
            </LayersControl.Overlay>
          ))}

{
            selected_index_overlay &&
            <TileLayer
              zIndex={1010}
              ref={index_overlay_ref}
              attribution={false}
              url="https://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            />
          }

          {/** Regions GeoJSON loaded when globalCCVIew is active */}
          {
            map_ref && !globalFieldDrawUIEnabled && globalCCViewEnabled  &&
            <OGRWFS map_ref={map_ref} layer_name={"regions"} org_id={user.organizationId}/>
          }

          {/** Crop Classification Map */}
          {map_ref && !globalFieldDrawUIEnabled && globalCCViewEnabled &&
            <OGRWFS map_ref={map_ref} cc={true} minZoom={13} org_id={user.organizationId}/> }

          {map_ref && !globalFieldDrawUIEnabled && globalCCViewEnabled && 
            <WMSTileLayer
            zIndex={1400}
            maxZoom={13}
            minZoom={5}
            url="http://34.147.106.59:8080/geoserver/AgcurateCCMap/gwc/service/wms/" //FIXME - This should be regulated from GEOSERVICE maybe?
            opacity={1}
            transparent={true}
            layers={"AgcurateCCMap:TRCC2024"}
            format={"image/png"}
          />}

     

          {/* User-defined assets - uploaded, drawn or parcel-assigned */}
          {
            map_ref && user &&
            <WFS map_ref={map_ref} layer_name={"fields"} org_id={user.organizationId}/>
          }

          {
             map_ref && user && globalFieldDrawUIEnabled === "parcel" && selected_user_asset && 
             <GeoJSON data={selected_user_asset.feature} /> 
          }
        

          {/** FIXME - Delete if unnecessary, refactor if necessary - WAs used to show SAM segmentation results on the map  */}
          {
            // map_ref && globalFieldDrawUIEnabled &&
            // <WFS map_ref={map_ref} layer_name={"segmentations"} org_id="Segmentations" />
          } 
            
          
        </LayersControl>
         
      </MapContainer>
    ),[map_center.lat, 
      map_center.lng, 
      map_center.zoom, 
      globalFieldDrawUIEnabled, 
      handleChange, 
      current_view, 
      selected_index_overlay, 
      user, 
      selected_map_layer.name]
  )


  return (
    <AGCCMapContext.Provider value={{map, setMap, map_center, setMapCenter}}>
      { /* MAP Content */ }
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          {//map ? <DisplayPosition map={map} /> : null
          }

        {
          view_loading && <Loading />
        }
        {MapContainerMemo}
       
        </Suspense>
        {
        //<Footer style={footerStyle}>Footer</Footer>
        }
      </Layout>
    </AGCCMapContext.Provider>
  );
};

export default AGCCMap;