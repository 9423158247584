// Modules
import React from 'react'

// Component: Icon > Exit (Shape)
const Exit = props => (
  <svg className="exit icon"
    viewBox="0 0 19 19">
    <path d="M4.23607761,0.727922061 L9.5,6 L14.7639224,0.727922061 C15.7329837,-0.242640687 17.3041426,-0.242640687 18.273204,0.727922061 C19.2422653,1.69848481 19.2422653,3.27207794 18.273204,4.24264069 L13.024,9.5 L18.273204,14.7573593 C19.2001322,15.6857237 19.2404334,17.1658209 18.3941077,18.1421621 L18.273204,18.2720779 C17.3462757,19.2004423 15.868468,19.240806 14.8936373,18.3931689 L14.7639224,18.2720779 L9.5,13 L4.23607761,18.2720779 C3.26701626,19.2426407 1.69585737,19.2426407 0.726796015,18.2720779 C-0.200132236,17.3437136 -0.240433464,15.8636163 0.60589233,14.8872752 L0.726796015,14.7573593 L5.975,9.5 L0.726796015,4.24264069 C-0.242265338,3.27207794 -0.242265338,1.69848481 0.726796015,0.727922061 C1.69585737,-0.242640687 3.26701626,-0.242640687 4.23607761,0.727922061 Z" />
  </svg>
)

// Export
export default Exit
