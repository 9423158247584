import React, {useState, useRef} from "react";
import { Layout, Menu, Button, Space, Dropdown } from 'antd';
import "./VegetationIndexLegend.css"

function VegetationIndexLegend(props) {
    // Data
    const color_palette = ["#CCFF00","#C3F702","#BBEE04","#B2E606","#AADE09","#A1D50B","#99CD0D","#90C50F","#87BC11","#7FB413","#76AC16","#6EA418","#659B1A","#5C931C","#548B1E","#4B8220","#437A23","#3A7225","#326927","#296129"]

    return (
        <div className="index-legend-info" style={{left: 10}}>
          <div className="index-legend">
            <div className="index-info">
              <span> {//i18next.t('lowIndex', { index: props.selectedIndexInfo.name.toUpperCase() })
                "Low Vegetation Index"
              } </span>
              <span> {//i18next.t('highIndex', { index: props.selectedIndexInfo.name.toUpperCase() })
                "High Vegetation Index"
              } </span>
            </div>
            <div className="color-list">
              {color_palette.map((color, index) => {
                return (
                  <div key={index} style={{ background: `${color}` }}></div>
                )
              })}
            </div>
          </div>
        </div>
    );
  }

export default VegetationIndexLegend;