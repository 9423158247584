import axios from 'axios';
import axios_instance from '../utilities/axios_instance';
import bbox from 'geojson-bbox';

const API_KEY = '94976952467899872b18ff33d2d1c941';
const Fields_WFS_Layer_Name = process.env.REACT_APP_Fields_WFS;
const Segmentations_WFS_Layer_Name = process.env.REACT_APP_Segmentations_WFS;

const GeoService = {
  
  getWFS: async (extent, layer_name, org_id) => {
    const lyr = layer_name === "fields" ? Fields_WFS_Layer_Name : Segmentations_WFS_Layer_Name;
    try {
      const URL = 'https://geoserver-old.agcurate.com/geoserver/wfs?service=WFS&' +
                'version=1.1.0&request=GetFeature&typename='+lyr+'&viewparams=organizationId:'+ org_id +'&'+ 
                'outputFormat=application/json&srsname=EPSG:4326&' +
                'bbox=' +
                extent.join(',') +
                ',EPSG:4326';

      const response = await axios_instance.get(`${URL}`);
      let data = "";
      if(!response.data.features) {
        data = {
          "type": "FeatureCollection",
          "features": [],
          "totalFeatures": 0,
          "numberMatched": 0,
          "numberReturned": 0,
          "crs": null
        }
      } else {
        data = response.data
      }
      
      console.log("DATA", data); 
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  _getCCWFS: async (extent) => {
    try {
      let URL = "https://geoserver.agcurate.com/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&"
      URL += "typename=Agcurate%3A2021_classification&"
      //URL += "authkey=634a87f1-170e-40ea-b7da-e42038d6f55a&ENV=aoi%3A+66c4d849aec235750a8b5beb5dbe186e&"
      URL += "outputFormat=application%2Fjson&srsname=EPSG%3A3857&"
      URL += "bbox=" +  extent.join(',') + ',EPSG:3857';

      const response = await axios.get(`${URL}`, {});
      
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  getCCWFS: async (extent) => {
    console.log("GETCC EXTENT: ", extent)
    try {
      let URL = "http://34.147.106.59:8080/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&"
      URL += "typename=	AgcurateCCMap%3ATRCC2024&"
      URL += "outputFormat=application%2Fjson&srsname=EPSG%3A4326&"
      URL += "bbox=" +  extent.join(',') + ',EPSG:4326';
      
      const response = await axios_instance.get(`${URL}`);
      console.log("GETCC: ", response)
      
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  getCCWMTS: async (extent) => {
    console.log("GETCC EXTENT: ", extent)
    try {
      let URL = "http://34.147.106.59:8080/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&"
      URL += "typename=	AgcurateCCMap%3ATRCC2024&"
      URL += "outputFormat=application%2Fjson&srsname=EPSG%3A4326&"
      URL += "bbox=" +  extent.join(',') + ',EPSG:4326';
      
      const response = await axios_instance.get(`${URL}`);
      
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  getRegions: async (extent, zoom_level) => {
    console.log("GETCC EXTENT: ", extent)
    try {
      let URL = "http://34.147.30.22:8080/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&"
      URL += "typename=FieldOps%3ARegions&"
      URL += "outputFormat=application%2Fjson&srsname=EPSG%3A4326&"
      URL += `bbox=${extent.join(',')},EPSG:4326&viewparams=zoomLevel:${zoom_level}`;
      
      const response = await axios_instance.get(`${URL}`);
      
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  segmentBBOX: async (geo) => { //geo is a geojson feature, coming from EditControl of react-leaflet-draw
    const _bbox = bbox(geo);
    const extent = _bbox.join(",");
    console.log("EXTENT: ", extent, "BBOX: ", bbox(geo), "GEO: ", geo);
    try {
        const segment_response = await axios_instance.post(`/field/segment?bbox=${extent}`, {});
        const segment_data = segment_response.data;
        console.log("SEG DATA: ", segment_data);
        return segment_data.data.id;
    } catch (error ){
        console.error(error);
    }
  },
  getSegmentation: async (id) => { //geo is a geojson feature, coming from EditControl of react-leaflet-draw
    try {
        const segment_response = await axios_instance.get(`/field/segment/${id}`);
        const segment_data = segment_response.data;
        return segment_data.data;
    } catch (error ){
        console.error(error);
    }
  },
  getSegmentationProgresses: async() => {
    const _classifications = JSON.parse(localStorage.getItem("Segmentations"));
    for (var i = 0; i<_classifications.length; i++) {
      var cls = _classifications[i];
      const resp = await GeoService.getSegmentation(cls.id);
      console.log("RESP: ", resp);
      _classifications[i] = {id: cls.id, name: cls.name, geo_coord:resp.field.bbox?.coordinates/* cls.geo.features[0].geometry.coordinates*/, createdAt: resp.field.createdAt, coordinates: resp.field.bbox?.coordinates}
    }

    localStorage.setItem("Segmentations", JSON.stringify(_classifications));
    return _classifications;
  }

};

export default GeoService;