import React, { Suspense, useEffect, useState, createContext, useContext, useRef, useMemo, useCallback } from 'react';
import { useLeafletContext } from '@react-leaflet/core'
import * as L from 'leaflet';
import {
  MapContainer,
  TileLayer,  
  LayersControl,
  useMapEvents,
  FeatureGroup,
  WMSTileLayer,
  GeoJSON,
  useMap,
} from 'react-leaflet';
import { EditControl } from "react-leaflet-draw";
import { createBrowserHistory } from "history";

import { Layout, Spin } from 'antd';

// Import Global Context 
import {GlobalContext} from "../../App";

import {  polygon, centroid, center } from '@turf/turf';


//Services
import GeoService from "../../services/GeoService";

// Map Layers
import { MapLayers } from "../map-overlays/MapOverlays"
import { BaseViewContext } from '../../views/Base';
import { useLocation, useNavigate } from 'react-router';
import WFS from '../OGR/WFS';
import SatelliteDataService from '../../services/SatelliteData';
import moment from 'moment';

const {Content} = Layout;

//const center = [37.1390745446244, 38.94586676726791]

const MiniMapContext = createContext("");

function MapSubscriber(props){
  
  const navigate = useNavigate();
  const location = useLocation();
  const [isFlyTo, setIsFlyTo] = useState(false);

  useMapEvents({
    click: () => {
      //map.locate()
    },
    locationfound: (e) => {
      props.map.current.flyTo(e.latlng, props.map.getZoom())
    },

    moveend: () => {
      if (!isFlyTo && props.refresh_url) {
        // your code here
        // Update URL with long lat and zoom
        const newCenter = props.map.current.getCenter();
        const newZoom = props.map.current.getZoom();
        const new_history_state = {title: `Lat ${newCenter.lat} Lng ${newCenter.lng}`, url: `/app${location.pathname}?lat=${newCenter.lat}&lng=${newCenter.lng}&zoom=${newZoom}`}
        //FIXME - This triggers whole HomeView all over again
        // add console.logs to HomeView and BaseView and see it in the console.
        window.history.replaceState(new_history_state, new_history_state.title, new_history_state.url);
        //navigate(new_history_state.url, {replace:false})
      } else {
        setIsFlyTo(false);
      } 
    },
    fly: () => {
      setIsFlyTo(true);
    },
    flyend: () => {
      setIsFlyTo(true);
      // your code here
    }
  })

  return (<></>)
}

const MiniMap = (props) => {
  const {force_selected_map_overlay} = props;
  const map_ref = useRef(null);
  const [map_center, setMapCenter] = useState({lat: 0, lng: 0, zoom: 2}); // map center at opening
  const [field, setField] = useState(null);
  const [field_geojson, setFieldGeoJSON] = useState(null );
  const [current_date, setCurrentDate] = useState(null); // it holds current date of the data shown to the user, not "today"
  const index_overlay_ref = useRef(null); // holds ref to the tilelayer when a vegetation index is loaded
  // Main map state
  const [map, setMap] = useState(null);
  const [selected_index_overlay, setSelectedIndexOverlay] = useState(null);
  const [loading, setLoading] = useState(true);


  // Get current_view, selected_map_layer and query_params from Global Context
  // current_view is to be updated to Home
  // selected_map_layer Will be manipulated by MapOverlays Component
  // "../components/map-overlays/MapOverlays"
  // query_params is accessible by every component
  const { 
    setSelectedMapLayer,
    selected_map_layer,
    selected_user_asset
  } = useContext(GlobalContext);

  const {current_view} = useContext(BaseViewContext);

  /* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
  // Window resize hack
  // This is necessary for leaflet to load the map in full. 
  // Don't touch it, play or otherwise fuck around with it.
  const handleResize = () => {
    if(map_ref)
      map_ref.current.invalidateSize();
    else
      // not a map_ref
      console.log("Not a map_ref")
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () =>  window.removeEventListener("resize", handleResize);
  },[])
  useEffect(() => {
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 100);
  }, [map_ref ])
  /* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

  
  useEffect(() => {
    
    if(map_ref.current) { 
      
      // const polygonFeature = convertBoundingBoxToPolygon(user.organization.extent);
      // const center = centroid(polygonFeature).geometry.coordinates;
      // const lat = center[0];
      // const lng = center[1];
      //map_ref.current.flyTo([ 38.64261790634527,34.89296635696362], 7, {duration: "1"});
      //map_ref.current?.fitBounds(user.organization.extent);
    }
  }, [map_ref])

  useEffect(() => {
    if(selected_user_asset){
      setSelectedMapLayer(MapLayers[3]); // set the map layer to google to show other fields in true color when user selects a field
    }
  }, [selected_user_asset])

  useEffect(()=>{
    const fetchNDVIForDate = async () => {
      setLoading(true);
      const index_tile_url = await SatelliteDataService.getIndexTileURL(field.id, current_date, props.index);

      setSelectedIndexOverlay({date: current_date, tile_url: index_tile_url.tileUrl});
      setLoading(false);
    }

    if(current_date && field && props.index){
      setSelectedIndexOverlay(null);
      fetchNDVIForDate()
    }
  }, [field, current_date, props.index])


  useEffect(()=>{
    if(field_geojson && map_ref.current && field){
      const field_center = center(field_geojson)
      const _center = [field_center.geometry.coordinates[1], field_center.geometry.coordinates[0]]
      map_ref.current.flyTo(_center, 16, {duration: "1"})
    }
  }, [field, map_ref, field_geojson])

  useEffect(()=>{
    if(props.field){
      const _field_geojson = {type:"Feature", geometry: props.field.geometry,properties: {name: "BLAH"}}
      setFieldGeoJSON(_field_geojson)
      setField(props.field)
      if(!props.current_date) { // if the parent hasn't sent any current_date
        setCurrentDate(moment(props.field.last_update).format("YYYY-MM-DD"))
      } else {
        console.log(props.current_date)
        setCurrentDate(moment(props.current_date).format("YYYY-MM-DD")) // Dynamic, eh? :))
      }
    }
  }, [props.field, props.current_date])

  useEffect(()=> {
    setSelectedMapLayer(MapLayers[3]);
  }, [setSelectedMapLayer])

  useEffect(() => {
    if (index_overlay_ref.current && selected_index_overlay) {
      //spinner_ref.current.removeLayer();
      index_overlay_ref.current.setUrl(selected_index_overlay.tile_url);
    }
  }, [selected_index_overlay]);

  /* Map Container Memo */
  // is used to have more control over user defined assets
  const MapContainerMemo = useMemo(
    () => (
      <MapContainer
        whenReady={(map)=> setMap(map)}
        ref={map_ref}
        doubleClickZoom={false}
        center={[map_center.lat, map_center.lng]}
        zoom={map_center.zoom}
        className={"map-container2"}
        style={{ width: props.width || 500, height: props.height || 200 }}
        zoomControl={false}
        maxZoom={16}
      >
        <MapSubscriber map={map_ref} setMapCenter={setMapCenter} current_view={current_view} refresh_url={props.refresh_url}/>
          <LayersControl position="topright">
            {
              !loading && 
                <TileLayer
                  zIndex={1010}
                  ref={index_overlay_ref}
                  attribution='&amp;copy'
                  url={""}
                />
            }

            {loading && <Spin />}

          { /* Basemaps */ }
          { 
            force_selected_map_overlay &&
              <LayersControl.Overlay
                key={force_selected_map_overlay.name}
                name={force_selected_map_overlay.name}
                checked={true}
              >
                {force_selected_map_overlay.layer}
              </LayersControl.Overlay>
          }

          {
            !force_selected_map_overlay && selected_map_layer && MapLayers.map((layer) => (
              <LayersControl.Overlay
                key={layer.name}
                name={layer.name}
                checked={selected_map_layer.name === layer.name}
              >
                {layer.layer}
              </LayersControl.Overlay>
            ))
          }

            

          {field && <GeoJSON data={field_geojson}/>}
          </LayersControl>
        </MapContainer>
    ),[map_center.lat, map_center.lng, map_center.zoom, current_view, selected_index_overlay, selected_map_layer, field, field_geojson]
  )

  return (
    <MiniMapContext.Provider value={{map, setMap, map_center, setMapCenter}}>
      { /* MAP Content */ }
      

    {MapContainerMemo}
      
    </MiniMapContext.Provider>
  );
};

export default MiniMap;