import React, { useEffect, useContext } from "react";
import { 
  Space,  
  Layout, 
  Input, 
  Result,
  Typography,
  Tag,
  Table,
  Spin, 
 } from 'antd';
import {
  ExclamationCircleOutlined
} from "@ant-design/icons";

import polyline from 'google-polyline';

import { GlobalContext } from "../../App";
import CCStatsService from "../../services/CCStatsService";
import { Path, StaticGoogleMap } from "react-static-google-map";
import { flattenPolygonCoordinates } from "../../utilities/polygon_util";
import { t } from "i18next";

const { Search } = Input;

const years = [{
    key: '1',
    label: "2018",
  },
  {
    key: '2',
    label: "2019"
  },
  {
    key: '3',
    label: "2020"
  },
  {
    key: '4',
    label: "2021"
  },
  {
    key: '5',
    label: "2022"
  },
  {
    key: '6',
    label: "2023"
  },
];

  /** TODO - REPEATING - check WFS.js */
  const CC_VALUES = ["wheat",
    "sunflower",
    "maize",
    //"othercrops",
    //"background1",
    //"background2",
    "secondmaize",
    "secondcotton",
    //"otherdoublecrops",
    "cotton",
    "clover"]

    const CC_Colors = ["#FFF3E0", "#FFF9C4", "#C8E6C9", /*"#BBDEFB", "#E0E0E0", "#CFD8DC",*/ "#DCEDC8", "#F8BBD0", /*"#E1BEE7",*/ "#FFFFFF", "#B2EBF2"]



function CropClassificationStats(props) {
  // View Contexts
  const {current_view, setCurrentView, globalSelectedRegion} = useContext(GlobalContext);

  const [ccstats, setCCStats] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    console.log("GLOBAL SELECTED REGION: ", globalSelectedRegion);
    //if a region is selected load its CC stats for *YEAR* // TODO; add YEAR into the mix here, currently only 2024 TR is hardcoded.
    const fetchCCStats = async () => {
      const _cc_stats = await CCStatsService.getCCStatsForRegion(globalSelectedRegion.properties);
      setCCStats(_cc_stats);
      setLoading(false);
    }

    if (globalSelectedRegion && Object.keys(globalSelectedRegion).length !== 0) {
      setCCStats([]);
      setLoading(true);
      fetchCCStats();
    }
  }, [globalSelectedRegion])

  const handleClick = (item) => {
    if (setCurrentView) {
      setCurrentView(prev => ({
        ...prev,
        viewTitle:  current_view.viewName + " - " + item.title,
        viewTitleSecondary: "Location: " + item.id
      }))
    } else {
      
    }
  }

  const columns = [
    {
      title: 'Crop',
      dataIndex: 'crop',
      key: 'crop',
      render: (crop) => {
        return (
          <Tag color={CC_Colors[crop]} key={crop}>
            <Typography.Text color="black">{t(`components.crops.${CC_VALUES[crop]}`)}</Typography.Text>
          </Tag>
        )
      },
    },
    {
      title: 'Area',
      dataIndex: 'crop_area_ha',
      key: 'area',
      render: (area) => {
        return <Typography.Text>{parseFloat(area).toFixed()} ha</Typography.Text>
      }
    },
    {
      title: '%',
      dataIndex: 'crop_percentage',
      key: 'percentage',
    }
  ];

  return (
    <>
      {globalSelectedRegion && Object.keys(globalSelectedRegion).length !== 0 && 
        <>
        <StaticGoogleMap className="field-info-card-img" size="300x200" maptype="satellite" apiKey="AIzaSyDfbtDOCEr8I6CYzKeygJ7DIxoX-u_SwR8">
                  <Path
                    weight="1"
                    fillcolor='0xFFFF0033'
                    points={"enc:" + encodeURIComponent(polyline.encode(flattenPolygonCoordinates(globalSelectedRegion.geometry.coordinates[0])))}
                  />
                </StaticGoogleMap>
        <Typography.Title>{globalSelectedRegion?.properties?.name}</Typography.Title>
        {ccstats && 
          (loading ? <Spin size="medium" /> : <Typography.Title level={4}>{parseFloat(ccstats[0]?.total_area_ha).toFixed()} ha</Typography.Title>)}
        </>
        }
      <Layout style={{backgroundColor: "#f5f5f5", color: "#282c34", zIndex: 1010, padding: 5}}>
        
      {
        globalSelectedRegion && Object.keys(globalSelectedRegion).length !== 0 && ccstats.length > 0 && 
        <Table 
          columns={columns} 
          dataSource={ccstats} 
          pagination={false}
          bordered={false}
        />
      }

{
        globalSelectedRegion && Object.keys(globalSelectedRegion).length !== 0 && ccstats.length === 0 && loading &&
        <Space direction="vertical" size={10}>
            <Layout style={{textAlign: "center", bottom:0, margin: "auto", display: "block"}}>
              <Spin size="large" />
            </Layout>
        </Space>
      }
      
      {
        globalSelectedRegion && Object.keys(globalSelectedRegion).length !== 0 && ccstats.length === 0 && !loading && 
        <Space direction="vertical" size={10}>
            <Layout style={{textAlign: "center", bottom:0, margin: "auto", display: "block"}}>
              <Layout className='fit-to-space'>
                  <Result
                    title="You don't have permission to see these stats."
                    icon={<ExclamationCircleOutlined style={{color: "#4caf50"}} />}
                  />
              </Layout>
            </Layout>
          </Space>
      }

      {
        globalSelectedRegion && Object.keys(globalSelectedRegion).length === 0 && 
          <Space direction="vertical" size={10}>
            <Layout style={{textAlign: "center", bottom:0, margin: "auto", display: "block"}}>
              <Layout className='fit-to-space'>
                  <Result
                    title={t('general.region_not_selected')}
                    icon={<ExclamationCircleOutlined style={{color: "#4caf50"}} />}
                  />
              </Layout>
            </Layout>
          </Space>
        }
      <br />
      <div style={{margin: 10}}>
        {
          CC_VALUES.map((crop, index) => {
            return <Space direction="horizontal" style={{columnGap: 0, marginRight: 10}} ><div className="color" style={{backgroundColor: CC_Colors[index]}}></div><Typography.Text className="label" style={{color: "black"}}>{t(`components.crops.${CC_VALUES[index]}`)}</Typography.Text></Space>
          })
        }
      </div>

      </Layout>
    </>
  );
}

export default CropClassificationStats;