import axios_instance from '../utilities/axios_instance';

const API_KEY = '94976952467899872b18ff33d2d1c941';
//const API = "http://localhost:3001/";
//const API = "https://agrocasco-backend-okwhxq4xvq-ew.a.run.app/"
const API = process.env.REACT_APP_API_URL;


const CropService = {
  
  getCrops: async () => { 
    try {
      const URL = API + "crop";
      //const URL = "https://jsonplaceholder.typicode.com/users"
      const response = await axios_instance.get(`${URL}`, /*config*/);
      console.log("RESP: ", response);
      return response.data.data.seasons;
    } catch (error) {
      console.error(error);
    }
  },

  getCropById: async (crop_id) => { 
    try {
      const URL = API + "crop";
      //const URL = "https://jsonplaceholder.typicode.com/users"
      const response = await axios_instance.get(`${URL}/${crop_id}`, /*config*/);
      console.log("RESP: ", response);
      return response.data.data.crop;
    } catch (error) {
      console.error(error);
    }
  },

  getCropClasses: async () => {
    try {
      const URL = API + "cropClass";
      const response = await axios_instance.get(`${URL}`);
      return response.data.data.cropClasses;
    } catch (error) {
      console.error(error);
    }
  },

  getCropsByClass: async (crop_class) => {
    try {
      const URL = `${API}crop/class/${crop_class}`;
      console.log(URL);
      const response = await axios_instance.get(`${URL}`);
      return response.data.data.crops;
    } catch (error) {
      console.error(error);
    }
  },

  getCropVariety: async() => {
    try {
      const URL = API + "cropVariety";
      const response = await axios_instance.get(`${URL}`);
      return response.data.data.cropVariety;
    } catch (error) {
      console.error(error);
    }
  },

};

export default CropService;