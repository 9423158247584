import axios_instance from '../utilities/axios_instance';

const URL = "fieldControl";

const FieldControlProvisionsService = {
  
  getAllProvisions: async (search_criteria, clb) => {
    try {
      const {user, farmer, season, field, search_query, page, pageSize, where} = search_criteria
      let query = "";
      query += search_query ? `search_query=${search_query}&` : "";
      query += user ? `assigneeId=${user?.id}&` : "";
      query += farmer ? `farmerId=${farmer?.id}&` : "";
      query += season ? `seasonId=${season?.id}&` : "";
      query += field ? `fieldId=${field?.id}&` : "";
      query += page || page ? `page=${page}&` : "";
      query += pageSize || pageSize ? `pageSize=${pageSize}` : "";

      const response = await axios_instance.get(`${URL}?${query}`);
      const fieldControls = response.data.data.fieldControls;
      const {count, rows} = fieldControls.length !== 0 ? fieldControls : {count: 0, rows: []};

      const provisions = rows; 
      const _prov_data = provisions.map((d, idx) => {
        let p = {...d.data}; // FIXME - there is a bug in update Provision  - AGCC-189
        p.field = d.field;
        p.farmer = d.field.farmer;
        p.fieldId = d.fieldId;
        p.id = d.id;
        p.updatedAt = d.updatedAt;
        p.createdAt = d.createdAt;
        p.user = d.user;
        p.userId = d.userId;
        p.season = d.season;
        return p
      })

      clb({data: _prov_data, count});
    } catch (error) {
      console.error(error);
    }
  },

  getProvisionsForField: async (field_id) => {
    try {
      const response = await axios_instance.get(`${URL}/?fieldId=${field_id}`);
      const provisions = response.data.data.fieldControls;
      const _prov_data = provisions.map((d, idx) => {
        
        let p = {...d.data}; // FIXME - there is a bug in update Provision  - AGCC-189
        p.field = d.field;
        p.farmer = d.field.farmer;
        p.fieldId = d.fieldId;
        p.id = d.id;
        p.updatedAt = d.updatedAt;
        p.createdAt = d.createdAt;
        p.user = d.user;
        p.userId = d.userId;
        p.season = d.season;
        return p
      })
      return _prov_data;
    } catch (error) {
      console.error(error);
    }
  },

  getProvisionsForFieldForSeason: async (field, season, page, pageSize, _clb) => {
    try {
      FieldControlProvisionsService.getAllProvisions({field: field, season:season, page: page, pageSize: pageSize}, (data) => {
        _clb(data)

      })
      // const response = await axios_instance.get(`${URL}/?fieldId=${field_id}`);
      // const provisions = response.data.data.fieldControls;

      
      // console.log("PROVISIONS: ", provisions);
      // const _prov_data = provisions.map((d, idx) => {
        
      //   let p = {...d.data}; // FIXME - there is a bug in update Provision  - AGCC-189
      //   p.field = d.field;
      //   p.farmer = d.field.farmer;
      //   p.fieldId = d.fieldId;
      //   p.id = d.id;
      //   p.updatedAt = d.updatedAt;
      //   p.createdAt = d.createdAt;
      //   p.user = d.user;
      //   p.userId = d.userId;
      //   p.season = d.season;
      //   return p
      // })

      // return _prov_data.filter(p => p.season.id === season_id);
    } catch (error) {
      console.error(error);
    }
  },

  createProvision: async (field_id, date, body) => {
    try {
      const response = await axios_instance.post(`${URL}/?fieldId=${field_id}&date=${date}`, body);

      return response.data.data.fieldControl;
    } catch (error) {
      console.error(error);
    }
  },

  updateProvision: async (provision_id, body ) => {
    try {
      console.log("BOYDDDD: ", body);
      const response = await axios_instance.put(`${URL}/${provision_id}`, body);

      return response.data.data.fieldControl;
    } catch (error) {
      console.error(error);
    }
  },

  deleteProvision: async (provision_id) => {
    try {
      const response = await axios_instance.delete(`${URL}/${provision_id}`);

      return response.data.data.fieldControl;
    } catch (error) {
      console.error(error);
    }
  },

  getProvision: async (provision_id) => {
    try {
      const response = await axios_instance.get(`${URL}/${provision_id}`);
      const _prov = response.data.data.fieldControl; 

      let p = {..._prov.data}; // FIXME - there is a bug in update Provision 
      p.field = _prov.field;
      p.farmer = _prov.field.farmer;
      p.fieldId = _prov.fieldId;
      p.id = _prov.id;
      p.updatedAt = _prov.updatedAt;
      p.createdAt = _prov.createdAt; 
      p.user = _prov.user;
      p.userId = _prov.userId;
      p.season = _prov.season;
      //TODO - need to add here a way to pull images from fieldControl too.
      return {...p, /** ...image */};
    } catch (error) {
      console.error(error);
    }
  }
};

export default FieldControlProvisionsService;