
import React, { useContext, useEffect, useState } from 'react';
import { Drawer, Button, Layout } from 'antd';
import { GlobalContext } from '../../../App';
import useWindowDimensions from '../../../utilities/window_size';
import { useTranslation } from 'react-i18next';

const ActionSheet = ({props, children}) => {
    const { t, i18n } = useTranslation("translation");
  const [visible, setVisible] = useState(false);

  const { 
      setGlobalCCViewEnabled,
      globalCCViewEnabled,
      setGlobalSelectedRegion
    } = useContext(GlobalContext);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setGlobalCCViewEnabled(false);
    setGlobalSelectedRegion({});
  };

  useEffect(() => {
    globalCCViewEnabled && showDrawer();
  }, [])

  const ActionSheetRef = React.createRef();

   const { height, width } = useWindowDimensions();

  return (
      <Drawer
          placement="bottom"
          closable={true}
          onClose={onClose}
          open={visible}
          mask={false}
          title={t('general.crop_intelligence')}
          getContainer={ActionSheetRef.current}
          height={"80%"}
          className='action-sheet'
          rootStyle={{
            width: width <= 1680 ? 350 : "15vw", //FIXME - This should be regulated from css maybe?
            marginLeft: width <= 1680 ? 50 : "2.5vw", //FIXME - This should be regulated from css maybe?
            height: "100%",
            bottom: 0,
     

          }}
          style={{
            padding: '8px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#f7f7f8',
            bottom: 0,
            borderRadius: "15px"
          }}

        >
          {/* Dash Indicator */}
          {/* <div
            style={{
              width: 40,
              height: 5,
              background: '#ccc',
              borderRadius: 2.5,
              margin: '0 auto 16px',
            }}
          /> */}
          {/* Content */}
          {children}
        </Drawer>
  );
};

export default ActionSheet;
