import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router";
import { NavLink, useParams } from "react-router-dom";
import moment from 'moment/min/moment-with-locales'
import { 
  List, 
  Space, 
  Avatar, 
  Layout, 
  Input, 
  Button,
  Divider, 
  Typography,
  Spin,
  Modal,
  DatePicker,
  Cascader,
 } from 'antd';
import {
  EditOutlined,
  SunOutlined,
  UserOutlined
} from "@ant-design/icons";

import { GlobalContext } from "../../App";
import { useTranslation } from "react-i18next";
import SeasonService from "../../services/Season";
import CropService from "../../services/Crop";
import dayjs from "dayjs";
import { upperCaseFirstLetter } from "../../utilities/format";
import { generate, green, presetPalettes, red, orange } from '@ant-design/colors';
import { ColorPicker, theme } from 'antd';



const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map(([label, colors]) => ({
    label,
    colors,
  }));

const { Search } = Input;
const { RangePicker } = DatePicker;

function SeasonsList(props) {
  const { t, i18n } = useTranslation("translation");
  // Router
  const { id } = useParams();
  const location = useLocation();
  const [seasons, setSeasons] = useState([]);
  const [view_loading, setViewLoading] = useState(true);
  const [user_item_onclick_path, setUserItemOnClickPath] = useState("/");


  //Create New Season modal state
  const [modal_open, setModalOpen] = useState(false);
  
  const [season_name, setSeasonName] = useState("");
  const [crop_classes, setCropClasses] = useState([]);
  const [crops, setCrops] = useState([]);
  const [selected_crop, setSelectedCrop] = useState(null);
  const [season_start_date, setSeasonStartDate] = useState(null);
  const [season_end_date, setSeasonEndDate] = useState(null);
  const [season_color, setSeasonColor] = useState("#1677ff");
  const [filtered_data, setFilteredData] = useState(seasons);

  const [loading, setLoading] = useState(false);

  // For color picker in create season modal
  const { token } = theme.useToken();
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    orange
  });

  // View Contexts
  const {
    current_view, 
    setCurrentView, 
    setSelectedUserAsset, 
    setSelectedIndex,
    setSelectedIndexOverlay,
    setGlobalFieldDrawUIEnabled,
  } = useContext(GlobalContext);

  const fetchSeasons = async () => {
    setViewLoading(true);
    const _seasons = await SeasonService.getSeasons();
    setSeasons(_seasons);
    setFilteredData(_seasons);
    setViewLoading(false);
  }

  const fetchCropClasses = async () => {
    const _crop_classes = await CropService.getCropClasses();
    
    const _crop_classes_cascader = _crop_classes.map((cc, idx) => {
      return {
        key: idx,
        label: t(`components.cropclasses.${cc.cropClass}`),
        value: cc.cropClass, 
        isLeaf: false
      }
    })
    setCropClasses(_crop_classes_cascader);
  }

  useEffect(() => {
    fetchSeasons();
  }, [])

  useEffect(()=>{
    moment.locale(i18n.language)
  }, [i18n.language])

  useEffect(()=>{
    if(modal_open) {
      fetchCropClasses();
    }
  }, [modal_open])

  const onMenuClick = (e) => {
    switch (e.key) {
      case 1:
        setSelectedUserAsset(null);
        setSelectedIndex(null);
        setSelectedIndexOverlay(null);
        setGlobalFieldDrawUIEnabled(true);
        break;
      default:
        break; 
    }
  };
  
  const handleClick = (item) => {
    if (setCurrentView) {
      setCurrentView(prev => ({
        ...prev,
        viewTitle:  current_view.viewName,
        viewTitleSecondary: "Location: " + item.id
      }))
    } else {}
  };

  useEffect(() => {
    if(current_view){
      setUserItemOnClickPath(current_view.basePath + "/seasons");
    }
  }, [current_view]);

  const onSearch = (e) => {
    if (!e) return;
    const value =  e.target.value
    const filtered = seasons.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase()) || item.email?.toLowerCase().includes(value.toLowerCase()) || item.phone?.toLowerCase().includes(value.toLowerCase())
      }
    );
    setFilteredData(filtered);
  };

  // Handlers
  const renderUserItem = (item, index) => {
    return (
      <NavLink to={`seasons/season/${item.id}`} onClick={() => handleClick(item)}>
        <List.Item key={index} style={{padding: 0}}>
          <List.Item.Meta
            //avatar={<EnvironmentOutlined style={{color: "#282c34", fontSize: 20}}/>}
            avatar={
              <Avatar size={"large"} style={{ backgroundColor: item.color }} shape="square" icon={<SunOutlined />} />
            }
            style={{textAlign: "left"}}
            title={ 
              
                <Space size="large" style={{ width: '100%', justifyContent: "space-between"}}>
                  <Typography.Text style={{fontSize: 16}}> 
                    {item.name}
                  </Typography.Text>
                  <Typography.Text italic disabled style={{fontSize: 10}}>
                    {moment(item.createdAt).fromNow()}
                  </Typography.Text>
                </Space> 
            
            }
            description={
              <Space size="small" direction="vertical">
                <Typography.Text italic >
                  {t(`components.cropclasses.${item.planted_crop.cropClass}`)} / {upperCaseFirstLetter(t(`components.crops.${item.planted_crop.name}`))} 
                </Typography.Text>
                <Typography.Text italic >
                  {moment(item.startDate).format("DD MMM YYYY")} - {moment(item.endDate).format("DD MMM YYYY")}
                </Typography.Text>
              </Space>
            }
          />
        </List.Item>
      </NavLink>
    ) 
  }

  // Create Season Modal Cascaders
  const showModal = () => {
    setModalOpen(true);
  }

  const afterModalClose = () => {
    setSeasonName(null);
    setSeasonStartDate(null);
    setSeasonEndDate(null);
    setSelectedCrop(null);
    fetchSeasons();
  }

  const createSeason = async (sname, ssdate, sedate, scrop, scolor)=>{
    const _resp = await SeasonService.createSeason(sname, ssdate, sedate, scrop, scolor)
    setLoading(false); 
    setModalOpen(false);
  }

  const handleOk = () => {
    setLoading(true);
    createSeason(season_name, season_start_date, season_end_date, selected_crop, season_color);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const onCropChange = (value, selectedOptions) => {
    console.log(value, selectedOptions);
    if(!selectedOptions) return;
    
    if (selectedOptions[1]?.isLeaf) {
      console.log(value, selectedOptions);
      const selected_option = selectedOptions[1].data.id;
      setSelectedCrop(selected_option)
    }
    setCrops(value);
  }

  const onSeasonNameChange = (e) => {
    setSeasonName(e.target.value);
  }

  const onDateRangeChange = (e) => {
    setSeasonStartDate(dayjs(e[0]).format("YYYY-MM-DD"))
    setSeasonEndDate(dayjs(e[1]).format("YYYY-MM-DD"));
  }

  const loadData = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];

    const crops = await CropService.getCropsByClass(targetOption.value);
    
    // load options lazily
    setTimeout(() => {

      targetOption.children = crops.map((crop, idx) => {
        return {
          label: (
            <Space direction="horizontal" style={{textAlign: "left"}}>
              <Avatar size="small" style={{ backgroundColor: '#f56a00', fontSize: 11}}>{t(`components.crops.${crop.name}`)}</Avatar>
              <Typography.Text>{t(`components.crops.${crop.name}`)}</Typography.Text>
            </Space>
          ),
          data: crop,
          value: crop.name,
          key: idx,
          isLeaf: true // TODO - This should change when varieties are introduced
        }
      })

      setCropClasses([...crop_classes]);
      console.log(targetOption.children);
    }, 1000);
  };

  

  return (
      <>
        {
          view_loading && 
          <Layout className='view-loading-overlay fit-to-space'>
            <div className='view-loading-overlay-content'>
              <Spin />
            </div>
          </Layout>
        }
        <Layout style={{backgroundColor: "#f5f5f5", color: "#282c34", position: 'sticky', top: "0", width:"100%",zIndex: 1005, padding: 5}}>
          <Divider orientation="left" style={{color: "#282c34"}}>
            <Typography.Title level={5}>
              {t('components.seasons')}
            </Typography.Title>
          </Divider>
          <Space direction="horizontal" style={{textAlign: "center",  padding: 5, justifyContent: "space-between"}}>
            <Search placeholder={t('general.searchtext')} allowClear onChange={onSearch} style={{ width: "100%" }} />
            
            <Button onClick={() => setModalOpen(true)}>
              <Space>
                <EditOutlined />
                {t('general.new')}
              </Space>
            </Button>
            
          </Space>
        </Layout>
        <Layout style={{padding: 10}}>
        {

          (seasons &&  // Show skeleton until fields are loaded from api
            <List
              style={{textAlign: "left"}}
              itemLayout="vertical"
              responsive="true"
              size="small"
              pagination={{
                pageSize: 6,
                size: "small",
                position: "bottom",
                align: "center",
                color: "#282c34",          
              }}
              dataSource={filtered_data}
              renderItem={renderUserItem}
            />)

        }
        </Layout>


        {/** Create New Season Modal */}
        <Modal
          title={t('general.create_new_season')}
          centered
          open={modal_open}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          afterClose={afterModalClose}
          wrapClassName={"season-create-modal-wrapper"}
          footer={[
            <Button key="back" onClick={handleCancel}>
            {t('general.cancel')}
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
            {t('general.create')}
          </Button>
          ]}
        >
          <Space direction="vertical" style={{width: "100%"}}>
            <Input size="large" placeholder={t('components.season_name')} prefix={<UserOutlined />} onChange={onSeasonNameChange}/>
            <RangePicker 
              onChange={onDateRangeChange} 
              style={{width: "100%"}} 
              popupStyle={{zIndex: 2147483001}} 
              popupClassName="rangepicker-popup-zindex"/>
            <Cascader  
              defaultValue={[selected_crop]} 
              options={crop_classes} 
              loadData={loadData} 
              onChange={onCropChange} 
              changeOnSelect  
              style={{width: "100%"}} 
              popupClassName={"cascader-popup-zindex"}/>
            <ColorPicker 
              presets={presets} 
              defaultValue={season_color} 
              onChange={(value) => {setSeasonColor(value.toHexString())}} 
              rootClassName={"colorpicker-popup-zindex"}
              placement="right"
              
              
              />
            
          </Space>
        </Modal>  
      </>
  );
}

export default SeasonsList;
