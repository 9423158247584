import React, { Fragment, useContext, useState } from "react";
import {
  LogoutOutlined,
  LineChartOutlined,

  SettingOutlined,
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TeamOutlined,
  FormOutlined,
  BoxPlotOutlined,
  EnvironmentOutlined,
  SunOutlined,
  ContactsOutlined,
  CloudOutlined,
  AppstoreOutlined
} from '@ant-design/icons';
import { Layout, Menu,  Popconfirm, Button, message, Tag} from 'antd';
import { useNavigate, NavLink, useParams, useLocation } from 'react-router-dom';

// Components
import Logo from '../logo/Logo'
import { GlobalContext } from "../../App";
import AuthContext from "../../contexts/AuthContext";
import useWindowDimensions from "../../utilities/window_size";
import { useKeycloak } from "@react-keycloak/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup, faWheatAwn } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Stylesheet from "reactjs-stylesheet";

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const {Sider} = Layout;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LeftMenu() {
  const { t, i18n } = useTranslation("translation");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  //const {keycloak} = useContext(AuthContext);
  const {keycloak} = useKeycloak();

  const navigate = useNavigate(); // React-Router-Dom navigation tool
  let query = useQuery();
 // const [lat_lng, setLatLng] = useState({lat: query.get("lat"), lng: })

  // onClick listener for menu items, to navigate
  const menuOnClick = ({ item, key, keyPath, domEvent }) => {
    console.log("ITEM222: ", item, keyPath, domEvent);
    switch (key) {
      case 'Home':
        navigate("/home/?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'Weather':
        
        navigate("/weather/?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'NDVI':
        navigate("/ndvi?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'Fields':
          navigate("/fields", {replace: true})
          break;
      case 'CC': // Crop Classification
        navigate("/cc?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'yield-corn': // Yield Stats
        navigate("/cc/yield/corn?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'yield-silagecorn': // Yield Stats
        navigate("/cc/yield/silagecorn?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'yield-barley': // Yield Stats
        navigate("/cc/yield/barley?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'yield-sunflower': // Yield Stats
        navigate("/cc/yield/sunflower?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'yield-canola': // Yield Stats
        navigate("/cc/yield/canola?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'yield-oat': // Yield Stats
        navigate("/cc/yield/oat?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'yield-wheat': // Yield Stats
        navigate("/cc/yield/wheat?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'yield-peanut': // Yield Stats
        navigate("/cc/yield/peanut?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'yield-sugarbeet': // Yield Stats
        navigate("/cc/yield/peanut?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'yield-cotton': // Yield Stats
        navigate("/cc/yield/cotton?lat="+query.get("lat")+"&lng="+query.get("lng"), {replace: true})
        break;
      case 'settings':
        break;
      case 'profile':
        navigate("/profile", {replace: true})
        break;
      case 'Team':
        navigate("/team", {replace: true})
        break;
      case 'logout':
        // All the logout magic will happen in the popup menu on logout click, see below.
        break;
      default:
        //navigate("/home/fields")
        break;
    }
  }

  // Sidebars
  const [menu_collapsed, setMenuCollapsed] = useState(true);

  const { height, width } = useWindowDimensions();

  const toggleMenu = () => {
    setMenuCollapsed(!menu_collapsed);
  };
  // Sidebars End

  //Logout Menu Item
  const confirm = (e) => {
    message.success('Logout Confirmed...');
    localStorage.setItem('token', "");
    localStorage.setItem('user', "");
    localStorage.setItem("refreshed", "")
    keycloak.logout({redirectUri: window.location.origin + "/app/login"});
  };
  
  const cancel = (e) => {
    message.error('Cancelled Logout');
  };
  
  //Logout Menu Item End

  return (
    <Sider
    onMouseEnter={() => setMenuCollapsed(false)}
    onMouseLeave={() => setMenuCollapsed(true)}
      collapsible
      trigger={null}
      collapsed={menu_collapsed}
      onCollapse={() => setMenuCollapsed(!menu_collapsed)}
      collapsedWidth={width <= 1680 ? 50 : "2.5vw"} //FIXME - This should be regulated from css maybe?
      style={{
        overflow: "auto",
        height: "100vh",
        minWidth: width <= 1680 ? 50 : "2vw", //FIXME - This should be regulated from css maybe?
        width: width <= 1680 ? 50 : "2vw", //FIXME - This should be regulated from css maybe?
        position: "relative",
        top: 0,
        left: 0,
        backgroundColor: "#f2f2f2",
        //borderRadius: "8px",
        //boxShadow: "1px 0px 1px rgba(0,0,0,.4)",
        boxShadow: " 0px 0px 4px 1px rgba(0,0,0,.3)",
        zIndex: 1004,
        textAlign: 'left',
        color: '#fff',
      }}
    >
        <div className="logo" style={{ height: 32, margin: 10, textAlign: "center", verticalAlign: "middle"}}>
          <Logo type="dark" brandmark={menu_collapsed} />
        </div>
        <Layout style={{ backgroundColor: "transparent", textAlign: "left", zIndex: 1005, marginTop: 24}}>
          <Menu 
            theme="light" 
            mode="vertical" 
            style={{ backgroundColor: 'transparent'}}
            onClick={menuOnClick}
            
          >
            <MenuItem key="Home">
              <NavLink to="/home/">
                <EnvironmentOutlined style={styles.navIcon} color='#12D940'/>
                <span style={styles.navText}>{t('views.home')}</span>
              </NavLink>
            </MenuItem>
            <MenuItem key="Fields">
              <NavLink to="/fields/"> 
                <BoxPlotOutlined style={styles.navIcon} />
                <span style={styles.navText}>{t('general.fields')}</span>
              </NavLink>
            </MenuItem>
            <MenuItem key="Seasons">
              <NavLink to="/seasons/">
                <SunOutlined style={styles.navIcon} />
                <span style={styles.navText}>{t('general.seasons')}</span>
              </NavLink>
            </MenuItem>
            <MenuItem key="FieldControlProvisions">
              <NavLink to="/provisions/">
                <FormOutlined style={styles.navIcon} />
                <span style={styles.navText}>
                  {t('views.fieldcontrolprovisions')}
                </span>
              </NavLink>
            </MenuItem>
            <MenuItem key="Farmers">
              <NavLink to="/farmers/">
                <ContactsOutlined style={styles.navIcon} />
                <span style={styles.navText}>
                  {t('components.farmers')}
                </span>
              </NavLink>
            </MenuItem>
            <MenuItem key="Weather">
              <CloudOutlined style={styles.navIcon} />
              <span style={styles.navText}>
                {t('components.weather')}</span>
            </MenuItem>
            <MenuItem key="Team">
              <TeamOutlined style={styles.navIcon} />
              <span style={styles.navText}>{t('components.team')}</span>
            </MenuItem>
            <SubMenu  key="ccstats" title={t('components.cropstats')}  icon={ <AppstoreOutlined />} >
              <SubMenu  key="yield" title={"Yield"}  icon={ <FontAwesomeIcon icon={faWheatAwn} />} >
                <MenuItem key="yield-wheat">
                  <span style={styles.navText}>Barley</span>
                </MenuItem>
                <MenuItem key="yield-silagecorn">
                  <span style={styles.navText}>Silage Corn</span>
                </MenuItem>
                <MenuItem key="yield-corn">
                  <span style={styles.navText}>Corn</span>
                </MenuItem>
                <MenuItem key="yield-cotton">
                  <span style={styles.navText}>Cotton</span>
                </MenuItem>
                <MenuItem key="yield-oat">
                  <span style={styles.navText}>Oat</span>
                </MenuItem>
                <MenuItem key="yield-sunflower">
                  <span style={styles.navText}>Sunflower</span>
                </MenuItem>
                <MenuItem key="yield-canola">
                  <span style={styles.navText}>Canola</span>
                </MenuItem>
                <MenuItem key="yield-sugarbeet">
                  <span style={styles.navText}>Sugarbeet</span>
                </MenuItem>
                <MenuItem key="yield-peanut">
                  <span style={styles.navText}>Peanut</span>
                </MenuItem>
              </SubMenu>
              <MenuItem key="CC">
                <span style={styles.navText}>Crop Classification</span>
              </MenuItem>
            </SubMenu>
            {/* <MenuItem key="CC">
              <AppstoreOutlined />
              <span style={styles.navText}>
                {t('components.cropclassification')} <CrownOutlined style={{color: "orange"}}/>
              </span>
            </MenuItem> */}
          </Menu>
        </Layout>
        <Layout style={{backgroundColor: "transparent", bottom: 0, width: "100%", position: "absolute", textAlign: "left", alignItems: "left", zIndex: 1005}}>
          <Menu 
            theme="light" 
            mode="vertical" 
            style={{ backgroundColor: 'transparent'}}
            onClick={menuOnClick}
          >
            <MenuItem disabled key="profile"><UserOutlined /><span style={styles.navText}>Profile</span></MenuItem>
            <SubMenu  key="settings" title={t('general.settings')} icon={<SettingOutlined />} >
              <MenuItem key="en" onClick={() => changeLanguage("en")}>
                <span style={styles.navText}>🇬🇧&nbsp;English</span>
              </MenuItem>
              <MenuItem key="tr" onClick={() => changeLanguage("tr")}>
                <span style={styles.navText}>🇹🇷&nbsp;Türkçe</span>
              </MenuItem>
              <MenuItem key="nl" onClick={() => changeLanguage("nl")}> 
                <span style={styles.navText}>🇳🇱&nbsp;Nederlands</span>
              </MenuItem>
              <MenuItem key="sk" onClick={() => changeLanguage("sk")}> 
                <span style={styles.navText}>🇸🇰&nbsp;Slovensko</span>
              </MenuItem>
              <MenuItem key="si" onClick={() => changeLanguage("si")}> 
                <span style={styles.navText}>🇸🇮&nbsp;Slovenščina</span>
              </MenuItem>
              {/* <MenuItem key="az" onClick={() => changeLanguage("az")}> 
                <span style={styles.navText}>🇦🇿&nbsp;Azərbaycanca</span>
              </MenuItem> */}
            </SubMenu>
            <MenuItem key="logout">
              <Popconfirm
                title={t("auth.areyousure")}
                onConfirm={confirm}
                onCancel={cancel}
                okText={t("general.yes")}
                cancelText={t("general.no")}
              >
                <LogoutOutlined /><span style={styles.navText}>{t('auth.logout')}</span>
              </Popconfirm>
            </MenuItem>
          </Menu>
          <div style={{margin: "auto"}}>
          {
            React.createElement(
                menu_collapsed ? 
                MenuUnfoldOutlined  : MenuFoldOutlined,
                {
                  className: 'trigger',
                  onClick: toggleMenu,
                }
            )
          }
          </div>
        </Layout>    
    </Sider>
  );
}

const styles = Stylesheet.create({
  navText: {
    fontWeight: "bolder",
    color: "#18191B"
  },
  navIcon: {
    color: "#18191B",
    stroke: "5px #18191B" ,
  }
})

export default LeftMenu;