import React, { useContext, useEffect, useState } from "react";
import {
  SnippetsOutlined,
  QuestionCircleOutlined,
  PicCenterOutlined
} from '@ant-design/icons';
import { Layout, Button, Row, Space, Tooltip} from 'antd';
import Notes from '../../notes/Notes';
import GPT from '../../support/GPT';
import MapOverlays from '../../map-overlays/MapOverlays';
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../App";


const {Sider} = Layout;

const data = Array.from({ length: 23 }).map((_, i) => ({
  title: `ant design part ${i}`,
}));

function RightSidebar(props) {
  const { t, i18n } = useTranslation("translation");
  const [right_sidebar_collapsed, setRSCollapsed] = useState(true);
  const [notes_collapsed, setNotesCollapsed] = useState(true);
  const [gpt_collapsed, setGPTCollapsed] = useState(true);
  const [map_overlays_collapsed, setMapOverlaysCollapsed] = useState(true);

  const {
    selected_index,
    selected_index_overlay
  } = useContext(GlobalContext);

  useEffect(() => {
    if (selected_index ) {
      setNotesCollapsed(false);
      setRSCollapsed(false);
    } 

    if(!selected_index && !selected_index_overlay) {
      setNotesCollapsed(true);
      setRSCollapsed(true);
    }
  }, [selected_index])

    return (
        <Space style={{ width: 0}}>
          <Sider
            width={300}
            trigger={null}
            collapsedWidth={150}
            collapsed={true}
            collapsible
            style={{
              overflowY: "scroll",
              height: "10vh",
              position: "absolute",
              backgroundColor: "transparent",
              top: 0,
              right: "1vh",
              margin: "auto",
              display: "block",
              padding: 10,
              zIndex: 1008,
              textAlign: "left"
            }}
          >
              <Space
                  style={{ flexDirection: "row", flex: 1}}
                >
                <Tooltip title={t("general.notesannotations")}>
                  <Button
                    type={!notes_collapsed ? 'primary' : 'default' }
                    icon={<SnippetsOutlined />}
                    onClick={() => {
                      if(notes_collapsed) {
                        if(!right_sidebar_collapsed){
                          setNotesCollapsed(false)
                          setGPTCollapsed(true)
                          setMapOverlaysCollapsed(true)

                        } else {
                          setNotesCollapsed(false)
                          setRSCollapsed(false)
                        }
                      } else {
                        setRSCollapsed(true)
                        setNotesCollapsed(true)
                      }
                    }} 
                  />
                </Tooltip>
                <Tooltip title={t("general.chatsupport")}>
                  <Button disabled
                    type={!gpt_collapsed ? 'primary' : 'default' }
                    icon={<QuestionCircleOutlined />}
                    onClick={() => {
                      if(gpt_collapsed) {
                        if(!right_sidebar_collapsed){
                          setGPTCollapsed(false)
                          setNotesCollapsed(true)
                          setMapOverlaysCollapsed(true)
                        } else {
                          setGPTCollapsed(false)
                          setRSCollapsed(false)
                        }
                      } else {
                        setRSCollapsed(true)
                        setGPTCollapsed(true)
                      }
                    }} 
                  />
                </Tooltip>
                <Tooltip title={t("general.mapoverlays")}>
                  <Button
                    type={!map_overlays_collapsed ? 'primary' : 'default' }
                    icon={<PicCenterOutlined />}
                    onClick={() => {
                      if(map_overlays_collapsed) {
                        if(!right_sidebar_collapsed){
                          setMapOverlaysCollapsed(false)
                          setNotesCollapsed(true)
                          setGPTCollapsed(true)
                        } else {
                          setMapOverlaysCollapsed(false)
                          setRSCollapsed(false)
                        }
                      } else {
                        setRSCollapsed(true)
                        setMapOverlaysCollapsed(true)
                      }
                    }} 
                  />
                </Tooltip>
                
              </Space>
          </Sider>
          
          <Space>
            <Sider
              collapsible
              width={300}
              trigger={null}
              collapsed={right_sidebar_collapsed }
              collapsedWidth={20}
              style={{
                overflowY: "scroll",
                height: "80vh",
                position: "absolute",
                backgroundColor: right_sidebar_collapsed ? "transparent" : "#f7f7f8",
                bottom: "10vh",
                right: "1vh",
                margin: "auto",
                display: "block",
                zIndex: 1001,
                textAlign: "left",
                borderRadius: "8px",
                boxShadow: !right_sidebar_collapsed ? "0px 0px 2px 2px rgba(0,0,0,.2)": "none",
              }}
            >
             
              { (!notes_collapsed && !right_sidebar_collapsed) ? 
                    <Notes type="all" field_id={props.field_id} />
                  :
                    ( !gpt_collapsed && !right_sidebar_collapsed ?
                      <GPT />
                  :
                    ( !map_overlays_collapsed && !right_sidebar_collapsed ?
                      <MapOverlays />
                  :
                      ''
                    )
                    )
              }
            </Sider>
          </Space>
        </Space>
    );
  }

export default RightSidebar;

const siderStyle: React.CSSProperties = {
  textAlign: 'center',
  backgroundColor: "transparent",
  position: "absolute",
  fontSize: 30,
  top: 15,
  zIndex: 1200
};

const triggerStyle: React.CSSProperties = {
  textAlign: 'center',
  backgroundColor: "transparent"
};