import { Avatar, Card, Checkbox, Layout, Segmented, Space, Spin, Table, Tooltip, Typography } from "antd"
import { useContext, useEffect, useState,useRef } from "react"
import FieldsService from "../../services/Fields";
import { DownOutlined, EditOutlined, EllipsisOutlined, ExportOutlined, SettingOutlined } from "@ant-design/icons";
import { Path, StaticGoogleMap } from "react-static-google-map";
import polyline from 'google-polyline';
import { flattenPolygonCoordinates } from "../../utilities/polygon_util";
import { NavLink } from "react-router-dom";
import { GlobalContext } from "../../App";
import { BaseViewContext } from "../../views/Base";
import { useHorizontalScroll } from "../../utilities/useHorizontalScroll";
import { useTranslation } from "react-i18next";

const TeamTable = (props) => {
  const { t, i18n } = useTranslation("translation");
  const [data_layout, setDataLayout] =  useState("table");
  const [team_data, setTeamData] = useState([]);
  const [filtered_team_data, setFilteredTeamData] = useState([]);
  const [member_fields_data, setMemberFieldsData] = useState({});

  const {setViewLoading} = useContext(BaseViewContext);

  const {
    setSelectedUserAsset
  } = useContext(GlobalContext);

  useEffect(()=>{
    if(props.data) {
		  setTeamData(props.data);
    }
	}, [props.data])

  useEffect(()=>{
    if(props.filtered_data) {
		  setFilteredTeamData(props.filtered_data);
    }
	}, [props.filtered_data])

  useEffect(()=> {
  
  }, [member_fields_data])

  useEffect(()=> {
    const fetchFieldsByUser = async (user) => {
      setViewLoading(true)
      FieldsService.getAllFields({user: user}, _fields => {
      member_fields_data[user.id] = _fields;
      setMemberFieldsData({...member_fields_data})
      setViewLoading(false)
  
      });
      
    }

    if(team_data){
      for(var i = 0; i < team_data.length; i++){
        fetchFieldsByUser(team_data[i])
      }
    }
  }, [team_data])

  // Column definitions for FieldsComparisonTable, see below.
  const columns = [
    {
      title: t('general.name'),
      dataIndex: 'name',
      className: 'centered-cell text-left-cell',
      render: (name, member) => {
        let user_name_avatar = name?.split(" ").length > 1 ? (name?.split(" ")[0][0] + name?.split(" ")[1][0]) : name[0]
          return (  
            <NavLink to={`/team/member/${member.id}`}>
              <Space direction="horizontal" style={{textAlign: "left"}}  className="table-row-clickable-cell">
                <Avatar size="large" style={{ backgroundColor: '#f56a00' }}>{user_name_avatar}</Avatar>
                <Typography.Text className="table-row-clickable-cell-text" >
                  {name} 
                  <sup className="link-to-something"><ExportOutlined /></sup>
                </Typography.Text>
              </Space>
            </NavLink>
          )
      },
    },
    {
      title: t('general.email'),
      dataIndex: 'email',
      className: 'centered-cell',
      render: email => {
          return `${email|| "N/A"}`
      },
    }, {
      title: t('components.fields'),
      dataIndex: '',
      className: 'centered-cell',
      render: (row) => {
        const row_index = row.id;//team_data.indexOf(row)
        //const path = "enc:" + polyline.encode(flattenPolygonCoordinates(member_fields_data[row_index]?.field.geometry.coordinates));
        return (
          <>
            {
              !member_fields_data[row_index] &&
                <Spin />
            }
            <Tooltip title="">
              <Space direction='horizontal' style={{gap: "0 !important"}}>
              
              {
                member_fields_data && member_fields_data[row_index] &&
                  <Avatar.Group shape="square">
                    {
                      member_fields_data[row_index]?.data.slice(0,3).map((field) => (
                        <Avatar shape="square" style={{ backgroundColor: '#f56a00', fontSize: 11}}>
                            {`${field.name[0]}${field.name.split(" ")[1]}`}
                            {/* <StaticGoogleMap size="200x200" maptype="satellite" apiKey="AIzaSyDfbtDOCEr8I6CYzKeygJ7DIxoX-u_SwR8">
                              <Path
                                weight="1"
                                fillcolor='0xFFFF0033'
                                points={path}
                              />
                            </StaticGoogleMap> */}
                        </Avatar>
                      ))
                    }
                    {
                      (member_fields_data[row_index]?.count - 3 > 0) &&
                        <Avatar style={{ backgroundColor: '#f56a00', fontSize: 11}}>
                            +{member_fields_data[row_index].count - 3}
                        </Avatar>
                    }
                  </Avatar.Group>
                }

                
              </Space>
            </Tooltip>
          </>
        )
      },
    },
    {
      title: t('general.phone'),
      dataIndex: 'phone',
      className: 'centered-cell',
      render: phone => {
          return `${phone || "N/A"}`
      },
    },{
      title:  t('general.address'),
      children: [
        {
          title: t('general.street'),
          dataIndex: ['address', 'street'],
          className: 'centered-cell',
          render: street => {
            return `${street|| "N/A"}`
          },
        },{
          title: t('general.suite'),
          dataIndex: ['address', 'suite'],
          className: 'centered-cell',
          render: suite => {
              return `${suite|| "N/A"}`
          },
        },{
          title: t('general.city'),
          dataIndex: ['address', 'city'],
          className: 'centered-cell',
          render: city => {
            return `${city|| "N/A"}`
          },
        },{
          title:  t('general.zipcode'),
          dataIndex: ['address', 'zipcode'],
          className: 'centered-cell',
          render: zipcode => {
            return `${zipcode|| "N/A"}`
          },
        }
      ]
    }
  ];

  const horizontal_scroll_ref = useHorizontalScroll();  // Check expandedRowRender below, to be used to scroll fields in expanded table row horizontally

  return (

    <Table
      sticky
      tableLayout="auto"
      rowKey={(record) => record.id}
      style={{cursor: "pointer"}}
      onRow={(record, rowIndex) => {
        return {
            onClick: event => {
            //let basePath = current_view?.basePath ? current_view.basePath : "/" ;
            //navigate(`${basePath}/fields/${record.id}`);
            }, // click row
        };
      }}
      footer={()=> {
        return (
          <Space direction="horizontal"  style={{float: "left"}}>
            <Typography.Text>
              <b>{t('general.total')}:</b> {filtered_team_data?.length} {`${t('general.member')}${filtered_team_data?.length>1 && i18n.language !== "tr" ? "s" : ""}`}
            </Typography.Text>
          </Space>
        )
      }}
      columns={columns}
      expandable={{
        expandedRowRender: (record) => {
          const row_index = record.id;
          return (
            <>
              {
                !member_fields_data[row_index] &&
                  <Spin />
              }
              <Space direction="horizontal" ref={horizontal_scroll_ref} style={{overflowX: "scroll",  width: "65vw"}}> 
                {
                  member_fields_data[row_index]?.data.map((_rf, idx) => {
                    // encode the field coordinates for Google Static Map API
                    const path = "enc:" + encodeURIComponent(polyline.encode(flattenPolygonCoordinates(_rf.geometry.coordinates)));
                    return (
                      <NavLink to={`/ndvi/fields/${_rf.id}`}>
                        <Card key={idx}
                          className="data-avatars-card"
                          hoverable
                          style={{ width: 150 }}
                          //onClick={() => onCardClick(field)}
                          cover={
                            <>
                              <StaticGoogleMap size="200x200" maptype="satellite" apiKey="AIzaSyDfbtDOCEr8I6CYzKeygJ7DIxoX-u_SwR8">
                                <Path
                                  weight="1"
                                  fillcolor='0xFFFF0033'
                                  points={path}
                                />
                              </StaticGoogleMap>
                            </>
                          }
                        >
                          <Card.Meta title={_rf.name} />
                        </Card>
                      </NavLink>
                    )
                })
              }
            </Space>
          </> 
        )
        },
        rowExpandable: (record) => record.name !== 'Not Expandable',
        expandRowByClick: true
      }}
      dataSource={filtered_team_data}
      pagination={true}
      bordered
      rowClassName={() => 'table-row'}
        
    />

  )
}

export default TeamTable