import moment from 'moment';
import CountUp from 'react-countup'
import L from 'leaflet'

// Format text to have uppercased first letter
export const upperCaseFirstLetter = text =>
  text.charAt(0).toUpperCase() + text.toLowerCase().substring(1)


export const CommaFormatter = (value) => <CountUp end={value} separator="," />;

export const getNearestDate = (dates_array, date) => {
  let nearestDate;

  dates_array.forEach(dt => {
    let diff = moment(date).diff(moment(date), 'days');
    if (diff > 0) {
      if (nearestDate) {
        if (moment(date).diff(moment(nearestDate), 'days') < 0) {
          nearestDate = date;
        }
      } else {
        nearestDate = date;
      }
    }
  });

  return nearestDate;
}

export const convertType = function (value){
  var v = Number (value);
  return !isNaN(v) ? v : 
       value === "undefined" ? undefined
     : value === "null" ? null
     : value === "true" ? true
     : value === "false" ? false
     : value
}

export const getGeoJSONBounds = (geoJson) => {
  // Extract the coordinates from the GeoJSON
  console.log("GEOJSON GEOMETRY: ", geoJson);
  const coordinates = geoJson.geometry?.coordinates || geoJson.feature.geometry.coordinates; // TODO FIXME - Quick Fix

  let latLngs = [];

  // Flatten nested coordinates arrays if needed (handle different GeoJSON types)
  const extractLatLngs = (coords) => {
    if (typeof coords[0] === "number") {
      latLngs.push([coords[1], coords[0]]); // [lat, lng]
    } else {
      coords.forEach((coord) => extractLatLngs(coord));
    }
  };

  extractLatLngs(coordinates);

  // Get the bounds (southWest and northEast corners)
  const bounds = L.latLngBounds(latLngs);

  return bounds;
};


export function removeTurkishDiacritics(str) {
  // Mapping of Turkish characters to their ASCII equivalents
  const turkishMap = {
    'ğ': 'g', 'Ğ': 'G',
    'ı': 'i', 'I': 'I', // 'I' (capital i without the dot) can be a special case, choose what's best for your use
    'İ': 'I',
    'ö': 'o', 'Ö': 'O',
    'ü': 'u', 'Ü': 'U',
    'ş': 's', 'Ş': 'S',
    'ç': 'c', 'Ç': 'C',
  };

  return str
    .split('')
    .map(char => turkishMap[char] || char)
    .join('');
}