// Modules
import i18next from 'i18next'

// Utilities
import { shortenedNumber, listOfAvailableYears } from './math'

// Constants
import { DATA_START_YEAR } from '../constants/data'
import { RGB_PATTERN, XYZ_PATTERN, ZONE_PATTERN } from '../constants/patterns'
import { DEFAULT_LANGUAGE, DEFAULT_UNIT, DEFAULT_ZONE } from '../constants/settings'
import { MINIMUM_ZOOM, MAXIMUM_ZOOM, ZOOM_INCREMENT } from '../constants/map'
//FIXME - Analytics
import analytics from '../analytics/analytics'

// Data
const letters = {
  million: i18next.t('millionLetter'),
  thousand: i18next.t('thousandLetter')
}

// Helpers > Data
export const getAvailableYears = () => {
  return listOfAvailableYears(DATA_START_YEAR)
}
export const getColorWithAdjustedOpacity = (color, percentage) => {
  return RGB_PATTERN.test(color) ? color.replace('rgb', 'rgba').replace(')', `, ${percentage / 100})`) : color
}
export const getDisplayDataFromResponse = response => {
  const { name, parent, crops = [], identifiedCultivatedArea: cultivatedArea = 0, unit = DEFAULT_UNIT } = response || {}
  crops.forEach(crop => {
    crop.id = crop.name
    delete crop.name
  })
  return { name, parent, crops, cultivatedArea, unit }
}
export const getFocusFromQuery = (focus, defaultFocus) => {
  if (!XYZ_PATTERN.test(focus)) return defaultFocus
  const match = focus.match(XYZ_PATTERN)
  return {
    zoom: getNormalizedZoomValue(parseFloat(match[1])),
    lat: parseFloat(match[2]),
    lng: parseFloat(match[3])
  }
}
export const getNormalizedZoomValue = value => {
  if (value < MINIMUM_ZOOM) return MINIMUM_ZOOM
  else if (value > MAXIMUM_ZOOM) return MAXIMUM_ZOOM
  else return value - (value % ZOOM_INCREMENT)
}
export const getPathFromZoneId = zoneId => {
  let text = ''
  return zoneId.split('-').map((piece, index) => {
    text += (index === 0 ? '' : '-') + piece
    return text
  })
}
export const getSelectorItemsFromZoneResponse = (response, locale = DEFAULT_LANGUAGE) => {
  if (!response) return []
  const convert = item => ({
    value: item.zoneKey || '',
    label: i18next.t(item.zoneKey) !== item.zoneKey ? i18next.t(item.zoneKey) : item.name,
    focus: {
      ...item.center,
      zoom: item.defaultZoom
    },
    isLeaf: !item.hasSub
  })
  const prependList = []
  if (!response.isRoot) {
    prependList.push({
      ...convert({ ...response }),
      isLeaf: true,
      hasAll: true,
      label: i18next.t('allPlacesLabel') + (i18next.t(response.zoneKey) !== response.zoneKey ? i18next.t(response.zoneKey) : response.name),
      originalLabel: response.name
    })
  }
  return [
    ...prependList,
    ...response.sub
      .map(convert)
      .sort((x, y) => x.label.localeCompare(y.label, locale, { ignorePunctuation: true }))
  ]
}
export const getShortenedNumber = number => shortenedNumber(number, letters)
export const getUserConfigurationFromResponse = response => {
  if (!response?.user) return { isAuthenticated: false }
  response.user.startingZone.label = response.user.startingZone.name
  delete response.user.startingZone.name
  return { ...(response.user), isAuthenticated: true }
}
export const getZoneFromQuery = (zoneQuery = '', defaultZone) => {
  let zone = {}
  const pieces = zoneQuery.split('~')
  if (ZONE_PATTERN.test(pieces[0])) {
    zone.id = pieces[0]
    zone.label = pieces[1]
  } else { zone = defaultZone }
  return { ...zone, path: getPathFromZoneId(zone.id) }
}
export const getZoneFromResponse = response => {
  if (!response) {
    return {
      ...DEFAULT_ZONE,
      path: getPathFromZoneId(DEFAULT_ZONE.id)
    }
  }
  return {
    id: response.zoneKey,
    path: getPathFromZoneId(response.zoneKey),
    label: response.name
  }
}
export const sendMapObjectClickedAnalytics = (zoneKey, name) => {
  const args = zoneKey.split('-')
  switch (args.length) {
    case 1:
      analytics.trackMapCountryClicked(name)
      break
    case 2:
      analytics.trackMapProvinceClicked(name)
      break
    case 3:
      analytics.trackMapCountyClicked(name)
      break
  }
}
export const sendListObjectClickedAnalytics = (target) => {
  const zones = target.value.split('-')
  switch (zones.length) {
    case 1:
      analytics.trackCountrySelected(target.label)
      break
    case 2:
      analytics.trackProvinceSelected(target.label, target.value)
      break
    case 3:
      analytics.trackCountySelected(target.label, target.value)
      break
    default:
      break
  }
}
