import axios_instance from '../utilities/axios_instance';
import moment from 'moment';
import bbox from 'geojson-bbox';

const API_KEY = '94976952467899872b18ff33d2d1c941';
const API = process.env.REACT_APP_API_URL;


const UserService = {

  getMe: async (_clb) => {
    try {
      const URL = API + "me";
      const response = await axios_instance.get(`${URL}`, );
      
      _clb(response.data.data.user);
    } catch (error) {
      console.error(error);

      if(error.response.status === 400) {
        // Organization is not set for the user yet
        _clb({})
      }
    }
  },

  getTeamMember: async(member_id) => {
    try {
      const URL = `${API}user/${member_id}`;
      const response = await axios_instance.get(`${URL}`);
      console.log("RESP: ", response);
      return response.data.data.user;
    } catch (error) {
      console.error(error);
    }
  },

  getUsers: async (token) => {
    try {
      const URL = `${API}user`;
      //const URL = "https://jsonplaceholder.typicode.com/users"
      const response = await axios_instance.get(`${URL}`);
      return response.data.data.users.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    } catch (error) {
      console.error(error);
    }
  },

  //FIXME: Under development, is it necessary or should it come from backend
  getUsersWithAssignedFields: async () => {
    try {
      const users = await UserService.getUsers();

      //const URL = "https://jsonplaceholder.typicode.com/users"
      const response = await axios_instance.get(`${URL}`);
      return response.data.data.users;
    } catch (error) {
      console.error(error);
    }
  },

  assignFields: async (user_id, fields_arr) => {
    try {
      const URL = `${API}user/${user_id}/assignField`;
      const response = await axios_instance.post(`${URL}`, {assigned_fields: fields_arr});
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  inviteUser: async (info, _clb) => {
    try {
      const URL = `${API}user/invite`;
      const resp = await axios_instance.post(`${URL}`, info)
      _clb(resp.data.data, null);
    } catch (error) {
      console.error(error);
      _clb(null, error)
    }
  }

};

export default UserService;