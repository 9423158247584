import { useContext, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Divider, Layout, Row, Space, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UserService from "../services/User";
import { useNavigate } from "react-router";
import TeamData from "../components/team/TeamData";

import { Descriptions } from 'antd';
import { BaseViewContext } from "./Base";
import { useTranslation } from "react-i18next";
const items = [
  {
    key: '1',
    label: 'UserName',
    children: 'Zhou Maomao',
  },
  {
    key: '2',
    label: 'Telephone',
    children: '1810000000',
  },
  {
    key: '3',
    label: 'Live',
    children: 'Hangzhou, Zhejiang',
  },
  {
    key: '4',
    label: 'Remark',
    children: 'empty',
  },
  {
    key: '5',
    label: 'Address',
    children: 'No. 18, Wantang Road, Xihu District, Hangzhou, Zhejiang, China',
  },
];

const {Header} = Layout;
const {Title} = Typography;

const TeamView = () => {
  const { t, i18n } = useTranslation("translation");

  //States
  const [users, setUsers] = useState(null);
  const [info_panel_items, setInfoPanelItems] = useState("");

  const { 
    current_view,
    setCurrentView, 
  } = useContext(BaseViewContext);
    

  useEffect(()=>{

    // View Properties - same for all views
    const team_view_properties = {
      viewName: "Team",
      viewTitle: t("components.team"),
      viewTitleSecondary: "",
      basePath: "/team",
      breadcrumbs: [{name:  t("components.team"), url: "/team"}],
      goBack: {name:  t("views.home"), url: "/home"}
    }

    setCurrentView(team_view_properties);
  }, [setCurrentView]);

  useEffect(() => {
    const fetchUsers = async () => {
      const _users = await UserService.getUsers();
      setUsers(_users);
    }

    fetchUsers();
    setInfoPanelItems(items);
  }, []);

  const navigate = useNavigate();

  const handleGoBack = () => {
    // Handle back button click here
    navigate("/home");
  };

  return (
    <>
      {/* <Descriptions title="User Info" items={items} /> */}
      <Divider orientation="left" style={{color: "#282c34"}}>
        <Title level={5}>
        {t("components.team")}
        </Title>
      </Divider>
      <TeamData data={users}/>
    </>
  )
}


export default TeamView;