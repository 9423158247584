import axios from 'axios';
import axios_instance from '../utilities/axios_instance';

const API_KEY = '94976952467899872b18ff33d2d1c941';
//const API = "http://localhost:3001/";
//const API = "https://agrocasco-backend-okwhxq4xvq-ew.a.run.app/"
const API = process.env.REACT_APP_API_URL;


const SeasonService = {
  
  getSeasons: async () => { 
    try {
      const URL = API + "season";
      //const URL = "https://jsonplaceholder.typicode.com/users"
      const response = await axios_instance.get(`${URL}`, /*config*/);
      return response.data.data.seasons.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    } catch (error) {
      console.error(error);
    }
  },

  getSeasonById: async (season_id) => { 
    try {
      const URL = API + "season";
      const response = await axios_instance.get(`${URL}/${season_id}`, /*config*/);
      return response.data.data.season;
    } catch (error) {
      console.error(error);
    }
  },

  getSeasonsByFieldId: async (field_id) => { 
    try {
      const URL = API + "season";
      const response = await axios_instance.get(`${URL}/?fieldId=${field_id}`, /*config*/);
      console.log("RESP: ", response);
      return response.data.data.seasons;
    } catch (error) {
      console.error(error);
    }
  },

  createSeason: async (name, startDate, endDate, planted_crop, color) => {
    try {
      const URL = API + "season";
      const response = await axios_instance.post(`${URL}`, {name, startDate, endDate, planted_crop, color});
      console.log("SEASON CREATE RESP: ", response);
      return response.data.data.season;
    } catch (err) {
      console.error(err);
    }
  },

  assignFields: async (season_id, fields_arr) => {
    try {
      console.log("SEASONS ID : ", season_id, fields_arr)
      const URL = `${API}season/${season_id}/addFields`;
      const response = await axios_instance.post(`${URL}`, {added_fields: fields_arr});
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  getFarmer: async(farmer_id) => {
    try {
      const URL = `${API}farmer/${farmer_id}`;
      const response = await axios_instance.get(`${URL}`);
      return response.data.data.farmer;
    } catch (error) {
      console.error(error);
    }
  },

  getSeasonPerformancePerDate: async (season_id, date, _clb) => {
    try {
      const URL = `${API}season/${season_id}/performance?statDate=${date}`;
      const response = await axios_instance.get(`${URL}`);
      const performance = response.data.data.stats[0];
      _clb(performance); //FIXME: is this 0 necessary? should be fixed in the backend
    } catch (error) {
      console.error(error);
    }
  },

  getFieldControlStatsPerSeason: async (season_id, _clb) => {
    try {
      const URL = `${API}season/${season_id}/stats`;
      const response = await axios_instance.get(`${URL}`);
      const stats = response.data.data.stats[0];
      _clb(stats); //FIXME: is this 0 necessary? should be fixed in the backend
    } catch (error) {
      console.error(error);
    }
  },

  getSeasonReports: async (season_id, _clb) => {
    try {
      const URL = `${API}season/${season_id}/reports`;
      console.log("URL: ", URL);
      const response = await axios_instance.get(`${URL}`);
      const stats = response.data.data.stats[0].get_season_reports;
      console.log("REPORTS: ", stats)
      _clb(stats); //FIXME: is this 0 necessary? should be fixed in the backend
    } catch (err) {
      console.error(err)
    }
  }

};

export default SeasonService;