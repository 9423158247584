import React, {useState, useRef, useEffect, useCallback, useContext} from "react";
import { Layout, Menu, Button, Space, Dropdown, Carousel, Spin, Tooltip} from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Icon from "../icon/Icon";
import "./IndexTimeline.css"
import moment from "moment";

// Contexts
import { GlobalContext } from "../../App";

// Services
import SatelliteDataService from "../../services/SatelliteData";
import { useHorizontalScroll } from "../../utilities/useHorizontalScroll";
import { useTranslation } from "react-i18next";
import { BaseViewContext } from "../../views/Base";

//FIXME - Repetition; check LeftSidebar.js
const Loading = () => {
  return (
    <Layout className='view-loading-overlay fit-to-space rounded' style={{backgroundColor: "transparent"}}>
      <div className='view-loading-overlay-content'>
        <Spin />
      </div>
    </Layout>
  );
}

function IndexTimeline(props) {
  const { t, i18n } = useTranslation();

    // State
    const [selected_date, setSelectedDate] = useState(null);
    const [selected_index, setSelectedIndex] = useState(null);
    const [dates, setDates] = useState(null);

    //Refs
    const timeline_ref = useRef(null);
    const active_date_ref = useRef(null);

    // Context Variables
    const {
      selected_user_asset,
      setSelectedUserAsset,
      selected_index_overlay,
      setSelectedIndexOverlay
    } = useContext(GlobalContext);

    const {setViewLoading} = useContext(BaseViewContext);

    // Data
    //const color_palette = ["#CCFF00","#C3F702","#BBEE04","#B2E606","#AADE09","#A1D50B","#99CD0D","#90C50F","#87BC11","#7FB413","#76AC16","#6EA418","#659B1A","#5C931C","#548B1E","#4B8220","#437A23","#3A7225","#326927","#296129"]
    //const [scrollPosition, setScrollPosition] = useState(0);

    const fetchDates = (field_id, _clb) => {
      SatelliteDataService.getSentinelDates(field_id).then((dates) => {
        _clb(dates)
      });
    }

    useEffect(() => {
      setSelectedIndex(props.selected_index)
    }, [props.selected_index])

    useEffect(() => {
      if (selected_user_asset) {
        //setViewLoading(true); //TODO - Should we show a fullpage loading really?
        fetchDates(selected_user_asset.id, (_imagery_dates) => {
          const __imagery_dates = _imagery_dates.map(_dt => {
            return {label: moment(_dt).format('MMM D'), value: _dt}
          })

          //Set the dates
          setDates(__imagery_dates);

          if (!selected_date) {
            //Fetch the data for closes date to now by default
            const _nearest_date_to_now = __imagery_dates[__imagery_dates.length - 1];
            fetchData(selected_user_asset.id, _nearest_date_to_now.value);
          } else {
            fetchData(selected_user_asset.id, selected_date.value);
          }

          
          
        })
      }
    }, [selected_user_asset, selected_index, i18n.language])




    const fetchData = useCallback(async (user_asset_id, date)=> {
      
      //set index tile url 
      let index_tile_url = "";
      if (selected_index === "sentinel2") {
        index_tile_url = await SatelliteDataService.getSentinel2TileURL(user_asset_id, date);
      } else {
        index_tile_url = await SatelliteDataService.getIndexTileURL(user_asset_id, date, selected_index);
      }

      if(index_tile_url !== "") {

        setSelectedIndexOverlay({date: date, tile_url: index_tile_url.tileUrl});
        //setViewLoading(false); // TODO - Should we show a fullpage loading really?
      }

    }, [selected_index, setSelectedIndexOverlay])

    function dateOnClick(date){
      const field_id = selected_user_asset.id;
      setSelectedDate(date)
      fetchData(field_id, date.value);
    }


    const setScrollPosition = (element) => {
      const timeline_elem = document.getElementById("timeline")
      console.log(timeline_elem);
      // timeline_elem.scrollTo({
      //   top: element.current.offsetTop,
      //   behavior: "smooth"
      // });

      element.current.scrollIntoView();
    };

    useEffect(() => {
      if(active_date_ref.current) {
        setScrollPosition(active_date_ref);
      }
    }, [dates, selected_index_overlay])

    /** Decrements or increments scollLeft property to scroll left or right respectively */
    const handleTimelineArrows = (direction) => {
      if (direction === 'left') {
        return timeline_ref ? (timeline_ref.current.scrollLeft -= 200) : null;
      } else {
        return timeline_ref ? (timeline_ref.current.scrollLeft += 200) : null;
      }
    }

    return (
      <>
        <Layout className="map-timeline" 
          style={{
            
            paddingTop:2,
            paddingBottom:2,
            width: "80%",
            flexDirection: "row", 
            justifyContent:"center",
            textAlign: "center",
            margin: "auto",
            zIndex: 1008,
          }
        }>
          
            <div className="prev-button">
              <Button className="left-arrow" onClick={() => handleTimelineArrows("left")}>
                <svg className="chevron icon" viewBox="0 0 19 12"><path d="M0.726796015,4.24264069 C-0.242265338,3.27207794 -0.242265338,1.69848481 0.726796015,0.727922061 C1.69585737,-0.242640687 3.26701626,-0.242640687 4.23607761,0.727922061 L9.5,6 L14.7639224,0.727922061 C15.7329837,-0.242640687 17.3041426,-0.242640687 18.273204,0.727922061 C19.2422653,1.69848481 19.2422653,3.27207794 18.273204,4.24264069 L11.2546408,11.2720779 C10.2855794,12.2426407 8.71442056,12.2426407 7.7453592,11.2720779 L0.726796015,4.24264069 Z"></path></svg>
              </Button>
            </div>

            <div className="timeline" id="timeline" ref={timeline_ref} direction="horizontal" style={{flex: 1, width: "80%", flexDirection: "row", justifyContent: "space-between"}}>
              {
                (!dates || !selected_index_overlay) &&  <Spin style={{margin: "auto"}}/>
              }
              {
                selected_index_overlay && dates && dates?.map((date, index) => {
                  const active = moment(selected_index_overlay?.date).diff( moment(date.value)) === 0;
                  const itemProps = active ? { ref: active_date_ref } : {};
                  return (
                    <Tooltip placement="top" title={moment(date.value).format("MMM DD, YYYY")} arrow={false}>
                      <Button type="" style={{margin: "auto"}} className={`timeline-date ${active ? 'active': ""}` } 
                        key={index} 
                        onClick={() => dateOnClick(date)} 
                        {...itemProps}>
                        {date.label}
                      </Button>
                    </Tooltip>
                  )

                })
              }
            </div>

            <div className="next-button">
              <Button className="right-arrow" onClick={() => handleTimelineArrows("right")}>
              <svg className="chevron icon" viewBox="0 0 19 12"><path d="M0.726796015,4.24264069 C-0.242265338,3.27207794 -0.242265338,1.69848481 0.726796015,0.727922061 C1.69585737,-0.242640687 3.26701626,-0.242640687 4.23607761,0.727922061 L9.5,6 L14.7639224,0.727922061 C15.7329837,-0.242640687 17.3041426,-0.242640687 18.273204,0.727922061 C19.2422653,1.69848481 19.2422653,3.27207794 18.273204,4.24264069 L11.2546408,11.2720779 C10.2855794,12.2426407 8.71442056,12.2426407 7.7453592,11.2720779 L0.726796015,4.24264069 Z"></path></svg>
              </Button>
            </div>
        </Layout>
      </>
    );
  }

export default IndexTimeline;