import axios_instance from '../utilities/axios_instance';
import { upperCaseFirstLetter } from '../utilities/format';

const API = process.env.REACT_APP_API_URL;
const URL = "region";

const RegionsService = {
  
  getSubRegions: async (parent_id, subregions = true, geometry = false ) => {
    try {
      const response = await axios_instance.get(`${URL}/?key=${parent_id}&subregions=${subregions}&geometry=${geometry}`);
      // add this for cascaders, esp. /components/regions-cascader/RegionsCascader.js
      // this is to ensure each item will have its own children
      const region = response.data.data.region;
      const _subregions = region.subregions.map((region, idx)=>{
        const _reg = {};
        _reg.label = upperCaseFirstLetter(region.name);
       _reg.isLeaf = false;
        
        _reg.value = region.key; 
        _reg.key = idx; //making sure every item has a unique key, as ant-design cascader is pretty rough about it
        return _reg
      })

      _subregions.sort(function (a, b) {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
        //a.value.localeCompare(b.value)
      });

      return {subregions: _subregions, geometry: region.geometry, key: region.key, name: region.name};

    } catch (error) {
      console.error(error);
    }
  },

  getParentRegions: async () => {
    try {
      const response = await axios_instance.get(`${URL}`);
      // add this for cascaders, esp. /components/regions-cascader/RegionsCascader.js
      // this is to ensure each item will have its own children
      const _regions = response.data.data.regions.map((region, idx)=>{
        const _reg = {};
        _reg.label = region.name;
        _reg.isLeaf = false;
        _reg.value = region.key; 
        _reg.key = idx; //making sure every item has a unique key, as ant-design cascader is pretty rough about it
        return _reg
      }) 

      _regions.sort(function (a, b) {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
        //a.value.localeCompare(b.value)
      });
      return _regions
    } catch (error) {
      console.error(error);
    }
  }
};

export default RegionsService;