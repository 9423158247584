// Modules
import React from 'react'

// Component: Icon > Clouds (Shape)
const Clouds = props => (
  <svg className="clouds icon"
    viewBox="0 0 485 485">
    <path d="M485,214.04c0-39.199-28.706-71.819-66.201-77.947c-11.771-51.31-57.693-88.639-111.478-88.639
      c-48.103,0-90.977,30.346-107.368,74.864c-55.911,12.776-100.346,57.227-112.081,114.524C38.376,243.191,0,285.59,0,336.783
      c0,55.561,45.202,100.763,100.763,100.763h264.602c55.561,0,100.763-45.202,100.763-100.763c0-20.852-6.369-40.242-17.261-56.334
      C471.077,266.202,485,241.528,485,214.04z M307.321,77.454c42.765,0,78.79,31.97,83.798,74.365l1.564,13.24h13.332
      c27.01,0,48.984,21.973,48.984,48.98c0,18.897-10.598,35.657-27.051,43.832c-14.023-11.145-31.071-18.641-49.695-21.031
      c-13.857-67.654-73.302-117.397-143.4-118.233C249.819,93.616,277.171,77.454,307.321,77.454z M365.364,407.546H100.763
      C61.744,407.546,30,375.802,30,336.783s31.746-70.763,70.768-70.763H114.1l1.564-13.24
      c7.018-59.398,57.488-104.191,117.399-104.191s110.382,44.792,117.399,104.191l1.564,13.24h13.332
      c39.021,0,70.768,31.744,70.768,70.763S404.383,407.546,365.364,407.546z"/>
  </svg>
)

// Export
export default Clouds
