import { Space, Typography } from "antd";

const MapLegend = (props) => {
  const crop_style_map = {
    barley: {58: "#D6191C", 120: "#E13E2D", 180: "#FBA45D", 230: "#FEDE99", 260: "#FFFFBF", 300: "#CDEBAF", 340: "#A9DCA4", 381: "#75B7AD", 440: "#4C9AB4", 500: "#358AB8", 540: "#2D84B9"},
    sunflower: {87: "#D6191C", 120: "#DD3328", 160: "#ED7345", 200: "#FDB366", 241: "#FEE49F", 280: "#E0F2B5", 320: "#A5D8A4", 360: "#5EA7B1", 400: "#4595B6", 435: "#3086B9"},
    wheat: {40: "#D6191C", 100: "#DA2822", 140: "#E55036", 200: "#FAA45C", 240: "#FDD48C", 300: "#EAF7B8", 340: "#BEE5AA", 400: "#83C0AB", 440: "#5CA6B2", 500: "#378CB8", 550: "#2D84B9"},
    canola: {100: "#D6191C", 250: "#ED6D42", 300: "#FCAD61", 350: "#FFF2B1", 400: "#AADDA4", 450: "#62AAB1", 500: "#4F9DB3", 560: "#3D90B7"},
    corn: {51: "#D6191C", 150: "#E65839", 320: "#F1834D", 500: "#FDAC61", 650: "#FDCE86", 800: "#F1FABB", 950: "#B6E2A7", 1100: "#55A1B3", 1240: "#3187B9", 1307: "#2F85B8"},
    cotton: {},
    sugarbeet: {1800: "#D6191C", 4000: "#E65739", 4500: "#F1804C", 5500: "#FDBA6F", 6000: "#FFF1B0", 6500: "#C7E9AD", 7000: "#98CFA7", 7500: "#67AEB0", 8000: "#4E9CB4", 9000: "#2F85B9"},
    silagecorn: {1100: "#D6191C", 3000: "#DD3128", 4000: "#EE7546", 4500: "#FDBF73", 5000: "#FFF7B6", 5500: "#CDEBAF", 6000: "#80BEAB", 6500: "#519DB3", 7000: "#4898B5", 7500: "#3D90B7", 8000: "#3187B9", 8329: "#2D84B9"},
    peanut: {315: "#D6191C", 330: "#F0814C", 350: "#FDCE86", 400: "#E8F6B7", 430: "#9BD0A7", 450: "#58A2B2", 520: "#4193B6"},
    oat: {103: "#D6191C", 160: "#E2442F", 220: "#FCA95F", 250: "#FFECA9", 300: "#C0E6AB", 350: "#87C2A9", 400: "#61A9B0", 450: "#4696B5", 507: "#2F87B9"}
  }
    
  return (
    <>
      { 
        !props.crop &&
        <div style={{backgroundColor: "transparent", left: 0, bottom: 0, width: "10%", overflowY: "scroll"}}>
          <Space direction="vertical" size={"small"} styles={{item: {padding: 0, margin: 0, lineHeight: "1rem"}}} style={{rowGap: 0}}>
            <Space direction="horizontal" style={{columnGap: 0}} ><div className="color" style={{backgroundColor: "rgb(220, 206, 103)"}}></div><Typography.Text className="label">Sunflower</Typography.Text></Space>
            <Space direction="horizontal" style={{columnGap: 0}} ><div className="color" style={{backgroundColor: "rgb(255, 175, 116)"}}></div><Typography.Text className="label">Cotton (2)</Typography.Text></Space>
            <Space direction="horizontal" style={{columnGap: 0}}><div className="color" style={{backgroundColor: "rgb(241, 130, 245)"}}></div><div className="label">Cotton</div></Space>
            <Space direction="horizontal" style={{columnGap: 0}}><div className="color" style={{backgroundColor: "rgb(255, 165, 161)"}}></div><div className="label">Corn (2)</div></Space>
            <Space direction="horizontal" style={{columnGap: 0}}><div className="color" style={{backgroundColor: "rgb(123, 189, 255)"}}></div><div className="label">Corn</div></Space>
            <Space direction="horizontal" style={{columnGap: 0}}><div className="color" style={{backgroundColor: "rgb(255, 77, 127)"}}></div><div className="label">Canola</div></Space>
          </Space>
        </div>
      }

      {
        props.crop &&
        <div style={{backgroundColor: "transparent", left: 0, bottom: 0, width: "10%", overflowY: "scroll"}}>
          <Space direction="vertical" size={"small"} styles={{item: {padding: 0, margin: 0, lineHeight: "1rem"}}} style={{rowGap: 0}}>
            {
              Object.keys(crop_style_map[props.crop]).map((k) => (
                <Space direction="horizontal" style={{columnGap: 0}} ><div className="color" style={{backgroundColor: `${crop_style_map[props.crop][k]}`}}></div><Typography.Text className="label">{k} kg/da</Typography.Text></Space>
              ))
            }
          </Space>
        </div>
      }
    </>
  )
}

export default MapLegend;