/* WFS Component */

import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../App";
import { BaseViewContext } from "../../views/Base";
import { useLeafletContext } from "@react-leaflet/core";
import { useLocation, useParams } from "react-router";
import { useMapEvents } from "react-leaflet";
import GeoService from "../../services/GeoService";
import * as L from 'leaflet';
import { isJSON } from "../../utilities/check";
import * as turf from '@turf/turf'
import { useTranslation } from "react-i18next";

/* This will get GeoJSON data from GeoServer based on the extent the user is viewing currently */
function OGRWFS(props) {
    const { t, i18n } = useTranslation("translation");
    const {selected_user_asset, setSelectedUserAsset, setSelectedIndexOverlay, globalSelectedRegion, setGlobalSelectedRegion} = useContext(GlobalContext);
    const {setViewLoading} = useContext(BaseViewContext)
    const context = useLeafletContext()
    const wfs_ref = useRef()
    const [wfs_data, setWFSData] = useState(null);
    const [map_moved, setMapMoved] = useState(null);
  
    const location = useLocation();
    const {id} = useParams();

    const focusOnField = (field_id) => {
      wfs_ref.current.eachLayer(function (layer) {  
        if(layer.feature.properties.id === field_id) {    
          const bounds = layer.getBounds();
          props.map_ref.current.fitBounds(bounds);
          layer.setStyle({color: "red", fillColor: "gray"}) 
        }
      });
    }
  
    useMapEvents({
      dragend: (event) => {
        setMapMoved(event); // this is a trick to ping the useEffect below to work again, to avoid repetition of the code
      },
    })
  
    useEffect(() => {
      const fetchWFS = async (extent) => {
        setViewLoading(true);
        let data = "";

        if (props.cc && props.map_ref.current.getZoom() >= props.minZoom) {
          data = await GeoService.getCCWFS(extent) // if it's coming from Crop Classification
        } else if (props.layer_name === 'regions') {
          data = await GeoService.getRegions(extent, props.map_ref.current.getZoom()) // if it's coming from Regions
        }else {
          data = await GeoService.getWFS(extent, props.layer_name, props.org_id) //Anywhere else
        }
        
        setWFSData(data)
        setViewLoading(false);
      }
      
      if(props.map_ref) { 
        console.log("MAP IS READY TO PULL CC: ", props.map_ref)
        const bounds = props.map_ref?.current.getBounds();
        let ne = bounds.getNorthEast(); // Coords of the northeast corner
        let sw = bounds.getSouthWest(); // Coords of the southwest corner
        const extent = [sw.lng, sw.lat, ne.lng, ne.lat];
        fetchWFS(extent);
      }
      
    }, [props.map_ref, map_moved, props.layer_name, props.org_id, setViewLoading, props.cc])
  
  
    useEffect(() => {
      if(selected_user_asset && wfs_ref?.current) {
        focusOnField(selected_user_asset.id)
      }
    }, [selected_user_asset])
  
  
    const onFeatureClick = (e) => {
      const feature = e.target;
      if(props.layer_name !== 'regions') {
        if (selected_user_asset?.id === feature.feature.properties.id) { //if it is the second click on the selected user asset
          //feature.resetStyle();

          feature.setStyle({
            color: "blue",
            fillColor: "green"
          })
    
          props.map_ref.current.zoomOut(2);
          setSelectedUserAsset(null);
          //setSelectedIndexOverlay(null);
        } else {
          //feature.resetStyle();
          
          feature.setStyle({
            color: "red",
            fillColor: "blue",
            stroke: "purple"
          })
          
          const bounds = feature.getBounds();
          props.map_ref.current.fitBounds(bounds);
          setSelectedUserAsset({...feature.feature.properties, geometry: feature.feature.geometry});
        
        }
      } else if (props.layer_name === 'regions') {
        console.log("FEATURE: ", globalSelectedRegion?.properties?.key, feature.feature.properties.key)
        const bounds = feature.getBounds();
        props.map_ref.current.fitBounds(bounds);
        setGlobalSelectedRegion(feature.feature)
        //TODO - Needs a "reclick to deselect" feature
        // if(globalSelectedRegion?.properties?.key === feature.feature.properties.key) { // if this feature is already selected, deselect it.
        //   setGlobalSelectedRegion({})
        // } else {
        //   console.log("LDFKNDF", feature.feature.properties.key)
        //   setGlobalSelectedRegion(feature.feature)
        // }
      }
    }
  
    useEffect(() => {

      if(!isJSON(wfs_data)) { // if the wfs data is anything but JSON, then halt
        console.log("HALTED")
        return
      }

      /** TODO - REPEATING - check cc-stats.js */
      const CC_VALUES = ["wheat",
        "sunflower",
        "maize",
        // "othercrops",
        // "background1",
        // "background2",
        "secondmaize",
        "secondcotton",
        //"otherdoublecrops",
        "cotton",
        "clover"]

      wfs_ref.current = new L.geoJSON(wfs_data, {
        minZoom: props.minZoom,
        zIndex: 1400,
        onEachFeature: (feature, layer) => {
          layer.on({
            click: onFeatureClick,
            mouseover: (e) => {
              const layer = e.target;

              if(props.cc) {
                layer.setStyle({
                  fillOpacity: 1,
                })
              }

              if(props.layer_name !== 'regions') {
                return
              }
             
              layer.setStyle({
                color: "black",
                fillColor: "transparent",
                weight: 1.5,
                dashArray: '3 3',
                dashOffset: '0',
              })
            },
            mouseout: (e) => {
              const layer = e.target;

              if(props.cc) {
                layer.setStyle({
                  fillOpacity: 0.5,
                })
              }

              if(props.layer_name !== 'regions') {
                return
              }
              
              layer.setStyle({
                color: "gray",
                fillColor: "transparent",
                weight: 1,
                dashArray: '3 3',
                dashOffset: '0',
              })
            }
          });

          const feature_geom_area  = (turf.area(turf.multiPolygon(feature.geometry.coordinates)) * 0.0001).toPrecision(4)
          let layer_tooltip = "";
          if(props.layer_name !== 'regions') {
            layer_tooltip = t(`components.crops.${CC_VALUES[feature.properties.value]}`) + " " + feature_geom_area + " ha " + feature.properties.calc_area
          } else {
            layer_tooltip = feature.properties.name;
          }

          layer.bindTooltip( layer_tooltip, {
            permanent: false,
            direction: 'center', // Direction can be 'center', 'top', 'bottom', 'left', or 'right'
            offset: [0, 0],
            className: 'feature-label'
          });
        },
        style: (feature) => {

          let _style = {
            weight: 2,            // stroke width
            fillOpacity: 0.7      // fill opacity};
          } 

          if(props.layer_name === 'regions') { // if showing regions
            _style = {
              color: "gray",
              fillColor: "transparent",
              weight: 1,
              dashArray: '3 3',
              dashOffset: '0',
            }
          }

          if(props.cc) { // If shows crop classifications
            _style = {
              color: "purple",
              fillColor: "turquoise",
              weight: 2,            // stroke width
              fillOpacity: 0.7      // fill opacity
            }
            switch (feature.properties.value) {
              case 0: // Wheat (WHT)
                _style.color = '#FF9800';    // Vibrant Orange
                _style.fillColor = '#FFF3E0';// Light Orange Fill
                break;
              case 1: // Sunflower (SFL)
                _style.color = '#FFEB3B';    // Bright Yellow
                _style.fillColor = '#FFF9C4';// Light Yellow Fill
                break;
              case 2: // Maize (MAI)
                _style.color = '#4CAF50';    // Lush Green
                _style.fillColor = '#C8E6C9';// Light Green Fill
                break;
              case 3: // Other Crops (OTHR)
                _style.color = '#2196F3';    // Bold Blue
                _style.fillColor = '#BBDEFB';// Light Blue Fill
                break;
              case 4: // Background 1 (BACKG1)
                _style.color = '#9E9E9E';    // Neutral Gray
                _style.fillColor = '#E0E0E0';// Light Gray Fill
                break;
              case 5: // Background 2 (BACKG2)
                _style.color = '#607D8B';    // Blue Gray
                _style.fillColor = '#CFD8DC';// Light Blue Gray Fill
                break;
              case 6: // Second Maize (SMAI)
                _style.color = '#8BC34A';    // Light Green
                _style.fillColor = '#DCEDC8';// Pale Green Fill
                break;
              case 7: // Second Cotton (SCOT)
                _style.color = '#000000';    // Vivid Pink
                _style.fillColor = '#FFFFFF';// Light Pink Fill
                break;
              case 8: // Other Double Crops (DOTHR)
                _style.color = '#9C27B0';    // Bright Purple
                _style.fillColor = '#E1BEE7';// Light Purple Fill
                break;
              case 9: // Cotton (COT)
                _style.color = '#E91E63';    // Black Stroke for High Contrast
                _style.fillColor = '#F8BBD0';// Pure White Fill
                break;
              case 10: // Clover (CLVR)
                _style.color = '#00BCD4';    // Black Stroke for High Contrast
                _style.fillColor = '#B2EBF2';// Pure White Fill
                break;
              default: // None of the above
                _style.color = '#F44336';    // Intense Red Stroke
                _style.fillColor = '#FFCDD2';// Soft Pink Fill
                break;
            }
          }
          
         
          return _style;
        }
      })
  
      props.map_ref?.current.addLayer(wfs_ref.current)
  
      return () => {
        props.map_ref?.current?.removeLayer(wfs_ref.current)
      }
    }, [props.map_ref, selected_user_asset, setSelectedIndexOverlay, setSelectedUserAsset, wfs_data])

    return null
  }

  export default OGRWFS;