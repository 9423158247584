import React from "react";
import { Layout, List, Space, Divider} from 'antd';

const data = Array.from({ length: 23 }).map((_, i) => ({
  title: `GPT GPT GPT ${i}`,
}));

function GPT(props) {

    return (
      <Space direction="vertical" style={{width: "100%"}}>
        <Divider orientation="left" style={{color: "#282c34"}}>GPT</Divider>
        <Layout style={{backgroundColor: "#f7f7f8", color: "#282c34"}}>
          <List
          dataSource={data}
          bordered={false}
          size="small"
          itemLayout="horizontal"
          split
          pagination={{
              onChange: (page) => {
              console.log(page);
              },
              pageSize: 9,
              position: "bottom",
              align: "center",
              color: "#282c34"
          }}
          renderItem={(item, index) => (
              <List.Item>
              <List.Item.Meta
                  title={<a href="#">{item.title}</a>}
                  description="GPT Prompts"
              />
              </List.Item>
          )}
          />
        </Layout>
      </Space>
    );
  }

export default GPT;