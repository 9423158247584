import { ClearOutlined, DownOutlined, SearchOutlined, SunOutlined } from "@ant-design/icons";
import { Avatar, Cascader, Divider, Input, Space, Spin, Tag, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import FarmerService from "../../services/Farmer";
import UserService from "../../services/User";
import { useTranslation } from "react-i18next";
import SeasonService from "../../services/Season";

const textStyle = {
  fontWeight: "bolder",
}

const SearchToolbar = (props) => {
  const { t, i18n } = useTranslation("translation");
  const [farmers, setFarmers] = useState([]);
  const [users, setUsers] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [user_options, setUserOptions] = useState([]);
  const [farmer_options, setFarmerOptions] = useState([]);
  const [season_options, setSeasonOptions] = useState([]);
  const [search_criteria, setSearchCriteria] = useState({user: false, farmer: false, season: false, search_query: false});

  useEffect(() => {
    if(props.farmers){ // if farmers cascader is requested from the parent components
      const fetchFarmers = () => {
        FarmerService.getFarmers({season: null, page: "all"}, (_farmers) => {
          setFarmers(_farmers.data);
        });
        
      }
      fetchFarmers();
    }

    if(props.users){
      const fetchUsers = async () => {
        const _users = await UserService.getUsers();
        setUsers(_users);
      }
      fetchUsers();
    }

    if(props.seasons) {
      const fetchSeasons = async () => {
        const _seasons = await SeasonService.getSeasons();
        setSeasons(_seasons)
      }

      fetchSeasons();
    }
  }, []);

  useEffect(()=>{
    const _farmers_options = farmers?.map((farmer, idx) => {

      // if farmer name is only one word not two - stupid // FIXME
      let farmer_name_avatar = farmer.name?.split(" ").length > 1 ? (farmer.name?.split(" ")[0][0] + farmer.name?.split(" ")[1][0]) : farmer.name[0]

      return {
        key: idx,
        label: (
          <Space direction="horizontal" style={{textAlign: "left"}}>
            <Avatar size="small" style={{ backgroundColor: '#f56a00', fontSize: 11}}>{farmer_name_avatar}</Avatar>
            <Typography.Text>{farmer.name}</Typography.Text>
          </Space>
        ),
        value: {id: farmer.id, name: farmer.name}
      }
    })

    setFarmerOptions(_farmers_options);
  }, [farmers])

  useEffect(()=>{
    // if the user is invited, don't show them in the list
    const _user_options = users?.filter(u => !u.invited).map((user, idx) => {
      let user_name_avatar = user.name?.split(" ").length > 1 ? (user.name?.split(" ")[0][0] + user.name?.split(" ")[1][0]) : user.name[0]

      return {
        label: (
          <Space direction="horizontal" style={{textAlign: "left"}}>
            <Avatar size="small" style={{ backgroundColor: '#f56a00', fontSize: 11}}>{user_name_avatar}</Avatar>
            <Typography.Text>{user.name}</Typography.Text>
          </Space>
        ),
        value:  {id: user.id, name: user.name},
        key: idx,
      }
    })

    setUserOptions(_user_options);
  }, [users])

  useEffect(()=>{
    if(props.searchCriteria) {
      setSearchCriteria(props.searchCriteria)
    }
  }, [props.searchCriteria])

  useEffect(()=>{
    const _season_options = seasons?.map((season, idx) => {
      return {
        key: idx,
        label: (
          <Space direction="horizontal" style={{textAlign: "left"}}>
            {/* <Avatar shape="square" size="small" style={{ backgroundColor: '#f56a00', fontSize: 11}}>{season.name[0] + season.name[1]}</Avatar> */}
            <Avatar size={"small"} style={{ backgroundColor: season.color }} shape="square" icon={<SunOutlined />} />
            <Typography.Text>{season.name}</Typography.Text>
          </Space>
        ),
        value: {id: season.id, name: season.name}
      }
    })

    setSeasonOptions(_season_options);
  }, [seasons])

  const clearFarmersCascader = () => {
    let _search_criteria = {...search_criteria}
    _search_criteria.farmer = false
    props.onSearch(_search_criteria)
  }

  const clearUsersCascader = () => {
    let _search_criteria = {...search_criteria}
    _search_criteria.user = false
    props.onSearch(_search_criteria)
  }

  const clearSeasonsCascader = () => {
    let _search_criteria = {...search_criteria}
    _search_criteria.season = false
    props.onSearch(_search_criteria)
  }

  const onSearch = (e) => {
    let _search_criteria = {...search_criteria}
    _search_criteria.search_query = e.target.value.trim();
    setSearchCriteria(_search_criteria)
    props.onSearch(_search_criteria);
  }

  const onChangeCriteria = (criteria, value) => {
    let _search_criteria = {...search_criteria}
    _search_criteria[criteria] = value && value.length > 0 ? value[0]: false
    setSearchCriteria(_search_criteria)
    props.onSearch(_search_criteria)
  }


  return (
    <>
    
    <Space direction="vertical" style={{width: "100%"}}>
      <Space direction="horizontal" style={{width: "100%"}}>
        <div style={{width: "40vw"}}>
          {/**FIXME - Repetition, this should be an atomic component on its own */}
          <div className="search-bar">
            <div className='search-bar-content'>
              <Divider />
              <Input 
                style={{
                  fontSize: 16,
                }}
                onPressEnter={onSearch}
                onChange={props.onChange}
                placeholder={props.placeholder}
                bordered={false} 
                allowClear={true} 
                suffix={<SearchOutlined />} />
              <Divider />
            </div>
          </div>
        </div>
        <div >
          <Space direction="horizontal" className="search-toolbar-buttons" style={{width: "100%"}} split={<Divider orientation="vertical" />}>
            {
            props.users &&
              <div className="search-toolbar-assignees">
                <Tooltip placement="topLeft" title={t('components.assignees')}>
                <Space direction="horizontal">
                  {/* <Typography.Text style={textStyle}>{t('components.assignees')}: </Typography.Text> */}
                  <Cascader allowClear loading={!user_options?.length > 0} onChange={(value) => onChangeCriteria("user", value)} changeOnSelect  options={user_options}>
                    
                      <Space direction='horizontal' style={{gap: "0 !important"}}>
                        { !user_options ? 
                          <Spin />
                            :
                          <Avatar.Group shape="circle">
                            {
                              users?.filter(u => !u.invited).slice(0,3).map((user, idx) => {
                                let user_name_avatar = user.name?.split(" ").length > 1 ? (user.name?.split(" ")[0][0] + user.name?.split(" ")[1][0]) : user.name[0]
                                return (
                                  <Avatar key={idx} style={{ backgroundColor: '#f56a00', fontSize: 11}}>{user_name_avatar}</Avatar>
                                )
                              })
                            }
                          </Avatar.Group>
                        }

                        <Typography.Text onClick={clearUsersCascader} style={{padding: 10}}>
                          <ClearOutlined />
                        </Typography.Text>
                        <Typography.Text style={{ margin: "auto", fontSize: 10}}><DownOutlined /></Typography.Text>
                      </Space>
        
                  </Cascader>
                </Space>
                </Tooltip>
              </div>
            }
            {
            props.farmers &&
              <div className="search-toolbar-assignees">
                <Tooltip placement="topLeft" title={t('components.farmers')}>
                <Space direction="horizontal">
                  {/* <Typography.Text style={textStyle} >{t('components.farmers')}: </Typography.Text> */}
                  <Cascader allowClear={{clearIcon: <ClearOutlined />}} onChange={(value) => onChangeCriteria("farmer", value)}  options={farmer_options}>

                      <Space direction='horizontal'  style={{gap: "0 !important"}}>
                        { !farmer_options ? 
                          <Spin />
                          :
                          <Avatar.Group shape="circle">
                            {
                              farmers?.slice(0,3).map((_farmer, idx) => {
                                let farmer_name_avatar = _farmer.name?.split(" ").length > 1 ? (_farmer.name?.split(" ")[0][0] + _farmer.name?.split(" ")[1][0]) : _farmer.name[0]
                                return (
                                  <Avatar key={`${idx}`} style={{ backgroundColor: '#f56a00', fontSize: 11}}>{farmer_name_avatar}</Avatar>
                                )
                              })
                            }
                          </Avatar.Group>
                        }
                        <Typography.Text onClick={clearFarmersCascader}>
                          <ClearOutlined />
                        </Typography.Text>
                        <Typography.Text style={{ margin: "auto", fontSize: 10}}><DownOutlined /></Typography.Text>
                      </Space>

                  </Cascader>
                </Space>
                </Tooltip>
              </div>
            }

            {
            props.seasons &&
              <div className="search-toolbar-assignees">
                <Tooltip placement="topLeft" title={t('components.seasons')}>
                <Space direction="horizontal">
                  {/* <Typography.Text style={textStyle} >{t('components.farmers')}: </Typography.Text> */}
                  <Cascader allowClear={{clearIcon: <ClearOutlined />}} onChange={(value) => onChangeCriteria("season", value)}  options={season_options}>

                      <Space direction='horizontal'  style={{gap: "0 !important"}}>
                        { !seasons ? 
                          <Spin />
                          :
                          <Avatar.Group shape="circle">
                            {
                              seasons?.slice(0,3).map((season, idx) => (
                                <Avatar style={{ backgroundColor: season.color }} shape="square" icon={<SunOutlined />} />
                              ))
                            }
                          </Avatar.Group>
                        }
                        <Typography.Text onClick={clearSeasonsCascader}>
                          <ClearOutlined />
                        </Typography.Text>
                        <Typography.Text style={{ margin: "auto", fontSize: 10}}><DownOutlined /></Typography.Text>
                      </Space>

                  </Cascader>
                </Space>
                </Tooltip>
              </div>
            }

            <div className="search-toolbar-button">
            </div>
          </Space>
        </div>
      </Space>
      <Space direction="horizontal" style={{width: "100%"}}>
              {
                Object.keys(search_criteria).map(k => {
                  return (
                    search_criteria[k] && <Tag closable onClose={() => onChangeCriteria(k, false)} >{search_criteria[k].name || search_criteria[k]}</Tag>
                  )
                })
              }
      </Space>
      </Space>
    </>
  )
}

export default SearchToolbar;