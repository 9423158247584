import axios_instance from '../utilities/axios_instance';
import { removeTurkishDiacritics } from '../utilities/format';

const API_KEY = '94976952467899872b18ff33d2d1c941';

const API = process.env.REACT_APP_API_URL;

const CCStatsService = {
  
  getCCStatsForRegion: async (region) => {
    try {
      if(region.parent_region_key.indexOf("-") === -1) {
        region.parent_region_key = region.key;
        region.parent_region_name = removeTurkishDiacritics(region.name);
      }
      const URL = `${API}ccstats/?target_region_key=${region.key}&parent_region_name=${region.parent_region_name}&parent_region_key=${region.parent_region_key}&year=2024`;

  
      const response = await axios_instance.get(`${URL}`);
      console.log("Response Data; ", response.data.data.stats);
      
      return response.data.data.stats;
    } catch (error) {
      console.error(error);
    }
  },
};

export default CCStatsService;