import axios_instance from "../utilities/axios_instance";


const OrganizationsService = {
  createOrganization: async (name, description, _clb) => {
    try {
      const body = {name, description};
      const org = await axios_instance.post(`organization`, body);
      _clb(org.data.data.organization, null); // resp and error
    } catch (error) {
      _clb(null, error); // resp and error
    }
  }
};

export default OrganizationsService;